define("lbp-cust-web-application/templates/components/common/pagination/lbp-pagination-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BEMes3gx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"nav\"],[12,\"aria-label\",[28,[[27,\"t\",[\"common.pagination.label\"],null]]]],[12,\"class\",[28,[\"lbp-pagination-nav \",[27,\"if\",[[23,[\"setBottomDivider\"]],\"bottom-box-divider\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[27,\"not\",[[23,[\"_isLimitReached\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"role\",\"list\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"result-line-wrapper text-center\"],[11,\"role\",\"listitem\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"result-line-wrapper__block flex-grow\"],[9],[0,\"\\n          \"],[7,\"button\"],[11,\"id\",\"seeMore\"],[11,\"class\",\"lbp-pagination-nav__button\"],[12,\"aria-label\",[28,[[21,\"label\"]]]],[11,\"type\",\"button\"],[9],[0,\"\\n            \"],[1,[27,\"a11y/a11y-semantic-icon\",null,[[\"text\",\"iconClass\",\"srHidden\"],[[27,\"concat\",[[23,[\"title\"]],\" \",[27,\"if\",[[23,[\"displayNumberToLoad\"]],[23,[\"_numberToLoad\"]]],null]],null],\"icon-lbp-conversation-more label-component__icon-add\",true]]],false],[0,\"\\n          \"],[3,\"action\",[[22,0,[]],\"_onClick\"]],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"lbp-pagination-nav__limit\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"lbp-pagination-nav-limit__text\"],[9],[1,[27,\"t\",[\"common.pagination.limit\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/common/pagination/lbp-pagination-component.hbs"
    }
  });
  _exports.default = _default;
});