define("lbp-cust-web-application/components/ticust-web-module-authentication/auth-wrapper", ["exports", "ticust-web-module-authentication/components/ticust-web-module-authentication/auth-wrapper"], function (_exports, _authWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _authWrapper.default;
    }
  });
});