define("lbp-cust-web-application/adapters/conversation-message", ["exports", "lbp-cust-web-application/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    ajax: function ajax(url, type, options) {
      // Wait ES sync when draft is created
      if (type === 'DELETE' || type === 'POST' && options && options.data && options.data.status === 'DRAFT') {
        url += '?refresh=true';
      }
      return this._super(url, type, options);
    }
  });
  _exports.default = _default;
});