define("lbp-cust-web-application/models/project-part-document-file", ["exports", "ti-web-shared/models/project-part-document-file"], function (_exports, _projectPartDocumentFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _projectPartDocumentFile.default;
    }
  });
});