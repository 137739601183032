define("lbp-cust-web-application/components/ti-web-shared/projects/lbp-indicator-list", ["exports", "lbp-cust-web-application/mixins/project-indicator"], function (_exports, _projectIndicator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_projectIndicator.default, {
    classNames: ['project-group-bloc-detail']
  });
  _exports.default = _default;
});