define("lbp-cust-web-application/initializers/components/ti-web-shared/side-menu/side-menu-line", ["exports", "ti-web-shared/components/ti-web-shared/side-menu/side-menu-line", "ember-uuid", "lbp-cust-web-application/mixins/keydown"], function (_exports, _sideMenuLine, _emberUuid, _keydown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ti-web-shared/components/ti-web-shared/side-menu/side-menu-line',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _sideMenuLine.default.reopen(_keydown.default, {
    tagName: 'div',
    // Services
    topBar: Ember.inject.service('topbar-service'),
    buttonId: Ember.computed('line.action.buttonId', function () {
      var buttonId = this.get('line.action.buttonId');
      return buttonId ? buttonId : _emberUuid.default.v4();
    }),
    _closeMenu: function _closeMenu() {
      this.set('topBar.narrowScreenSidebar', false);
    },
    actions: {
      handleAction: function handleAction(actionName, actionParams) {
        this._super(actionName, actionParams);
        this._closeMenu();
      },
      resetData: function resetData() {
        this._super();
        this._closeMenu();
      },
      handleKeyDown: function handleKeyDown(event) {
        this.keyDown(event);
      }
    }
  });
});