define("lbp-cust-web-application/routes/app/ticust-web-module-interactions/interlocutors/interlocutor/conversations/inbenta", ["exports", "ti-web-shared/mixins/router-mixin"], function (_exports, _routerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_routerMixin.default, {
    templateName: 'app/ticust-web-module-interactions/interlocutors/interlocutor/conversations/inbenta',
    apiSettings: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        interlocutor: this.modelFor('app.ticust-web-module-interactions.interlocutors.interlocutor'),
        inbentaUrl: this.get('apiSettings').getSettingByKey('organization', null, 'cust.module.interactions.inbenta.url')
      });
    },
    afterModel: function afterModel(model) {
      if (model.inbentaUrl) {
        model.inbentaUrl = model.inbentaUrl.get('settingValue');
      }
    }
  });
  _exports.default = _default;
});