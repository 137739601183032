define("lbp-cust-web-application/initializers/components/ti-web-shared/projects/project-informations", ["exports", "ti-web-shared/components/ti-web-shared/projects/project-informations", "moment"], function (_exports, _projectInformations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ti-web-shared/components/ti-web-shared/projects/project-informations',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _projectInformations.default.reopen({
    showCreationDate: Ember.computed.equal('project.status', 'IN_PROGRESS'),
    showClosedDate: Ember.computed.equal('project.status', 'CLOSED'),
    showFinishDate: Ember.computed.equal('project.status', 'CLOSED'),
    creationDate: Ember.computed('project.creationDate', function () {
      return this.formatDate(this.get('project.creationDate'));
    }),
    closedDate: Ember.computed('project.closedDate', function () {
      return this.formatDate(this.get('project.closedDate'));
    }),
    finishDate: Ember.computed('project.finishDate', function () {
      return this.formatDate(this.get('project.finishDate'));
    }),
    formatDate: function formatDate(date) {
      return date ? (0, _moment.default)(date).format('DD MMMM YYYY') : '';
    }
  });
});