define("lbp-cust-web-application/components/ticust-web-module-interactions/conversation/simple-attachment-display", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-composer-attachment"], function (_exports, _conversationComposerAttachment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  var _default = _conversationComposerAttachment.default.extend({
    tagName: ''
  });
  _exports.default = _default;
});