define("lbp-cust-web-application/sanitizers/html-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // See: https://github.com/gbirke/Sanitize.js#configuration
  var _default = {
    elements: ['div', 'b', 'em', 'i', 'strong', 'u', 'p', 'br', 'a', 'span', 'img', 'h1', 'ul', 'li'],
    attributes: {
      a: ['href', 'title', 'target'],
      div: ['style'],
      h1: ['style'],
      span: ['style'],
      p: ['style'],
      img: ['src', 'cid', 'alt', 'title', 'tmpStyle', 'width', 'height', 'style']
    },
    protocols: {
      a: {
        href: ['http', 'https']
      },
      img: {
        src: ['http', 'https', 'data']
      }
    }
  };
  _exports.default = _default;
});