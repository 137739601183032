define("lbp-cust-web-application/sanitizers/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    allowedTags: ['div', 'p', 'br', 'a', 'span', 'img', 'ul', 'li', 'h1', 'h2', 'h3'],
    allowedAttributes: {
      a: ['href', 'title', 'target', 'style', 'tabindex'],
      span: ['style'],
      p: ['style', 'tabindex'],
      li: ['style'],
      div: ['style', 'class', 'tabindex'],
      ul: ['style'],
      h1: ['style'],
      img: ['src', 'cid', 'alt', 'title', 'tmpStyle', 'width', 'height', 'style']
    },
    allowedSchemes: ['http', 'https'],
    allowedSchemesByTag: {
      a: ['http', 'https'],
      img: ['http', 'https', 'data']
    }
  };
  _exports.default = _default;
});