define("lbp-cust-web-application/initializers/models/project-part-document", ["exports", "ember-data", "ti-web-shared/models/project-part-document", "ti-web-shared/models/enums/provider-type", "ti-web-shared/models/enums/document-type"], function (_exports, _emberData, _projectPartDocument, _providerType, _documentType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ti-web-shared/models/project-part-document',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  var Enums = {
    ProjectPartDocumentProviderTypes: _providerType.default.create(),
    ProjectPartDocumentType: _documentType.default.create()
  };
  _projectPartDocument.default.reopen(Enums, {
    creatorType: _emberData.default.attr('string'),
    creatorId: _emberData.default.attr('string'),
    clientReaders: Ember.computed('readers.[]', function () {
      var result = [];
      if (this.get('readers')) {
        this.get('readers').forEach(function (r) {
          if (r.type === 'CLIENT') {
            result.push(r.id);
          }
        });
      }
      return result;
    }),
    advisorReaders: Ember.computed('readers.[]', function () {
      var result = [];
      if (this.get('readers')) {
        this.get('readers').forEach(function (r) {
          if (r.type === 'ADVISER') {
            result.push(r.id);
          }
        });
      }
      return result;
    }),
    isActorProvider: Ember.computed('providerType', function () {
      return this.get('providerType') === this.get('ProjectPartDocumentProviderTypes.ACTOR');
    }),
    isInterlocutorProvider: Ember.computed('providerType', function () {
      return this.get('providerType') === this.get('ProjectPartDocumentProviderTypes.INTERLOCUTOR');
    }),
    hasStatusToAddFile: Ember.computed('status', function () {
      return this.get('status') === this.get('ProjectPartDocumentStatus.TO_UPLOAD') || this.get('status') === this.get('ProjectPartDocumentStatus.TO_ACKNOWLEDGE') || this.get('status') === this.get('ProjectPartDocumentStatus.KO');
    }),
    isFreeDocument: Ember.computed('type', function () {
      return this.get('type') === this.get('ProjectPartDocumentType.FREE');
    })
  });
});