define("lbp-cust-web-application/initializers/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  function initialize() {
    Ember.TextSupport.reopen({
      attributeBindings: ['aria-describedby']
    });
  }
});