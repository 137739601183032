define("lbp-cust-web-application/initializers/routes/ticust-web-module-projects/interlocutors/interlocutor/projects/index", ["exports", "ticust-web-module-projects/routes/interlocutors/interlocutor/projects/index", "tag-commander-cli/mixins/tagged-page"], function (_exports, _index, _taggedPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ticust-web-module-projects/routes/interlocutors/interlocutor/projects/index',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _index.default.reopen(_taggedPage.default, {
    queryParams: {
      status: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var status = params.status;
      var pageTagData = {};
      if (status === 'closed') {
        pageTagData.name = 'contacter::espace_echange_documents::projet_clos';
      } else if (status === 'open') {
        pageTagData.name = 'contacter::espace_echange_documents::accueil_projet';
      } else {
        // Should not be possible, but failsafe.
        pageTagData.name = 'contacter::espace_echange_documents::accueil_projet::autre';
      }
      this.set('pageTagData', pageTagData);
    }
  });
});