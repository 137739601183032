define("lbp-cust-web-application/templates/app/ticust-web-module-interactions/interlocutors/interlocutor/conversations/claim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QCODAV+F",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"inbenta\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[1,[27,\"t\",[\"interactions.conversations.claim.title\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"claim-form\",null,[[\"url\",\"onSendEmailEvent\",\"onReceivedMailEvent\",\"onRenewEesSession\"],[[23,[\"model\",\"claimUrl\"]],[27,\"action\",[[22,0,[]],\"newMessage\"],null],[27,\"action\",[[22,0,[]],\"backToList\"],null],[27,\"action\",[[22,0,[]],\"renewSession\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/app/ticust-web-module-interactions/interlocutors/interlocutor/conversations/claim.hbs"
    }
  });
  _exports.default = _default;
});