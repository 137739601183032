define("lbp-cust-web-application/templates/components/side-panel/side-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zHC5beEv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"header\"],[12,\"id\",[27,\"if\",[[23,[\"showSideMenu\"]],\"side-panel\",\"side-panel-burger\"],null]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"side-panel-footer\"],[9],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[28,[\"nav-control\\n      \",[27,\"if\",[[23,[\"showSideMenu\"]],\"close-icon-position menu-icon-close\",\"burger-icon-position menu-icon-panel-header-burger\"],null]]]],[12,\"aria-label\",[21,\"sideMenuState\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"toggleMenu\"],null]],[12,\"aria-expanded\",[27,\"if\",[[23,[\"showSideMenu\"]],\"true\",\"false\"],null]],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"controled \",[27,\"if\",[[23,[\"showSideMenu\"]],\"active\"],null]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"underlay\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"toggleMenu\"],null]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/side-panel/side-panel.hbs"
    }
  });
  _exports.default = _default;
});