define("lbp-cust-web-application/components/ti-web-shared/absence/absence-table", ["exports", "ti-web-shared/components/ti-web-shared/absence/absence-table"], function (_exports, _absenceTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _absenceTable.default;
    }
  });
});