define("lbp-cust-web-application/initializers/controllers/ticust-web-module-projects/interlocutors/interlocutor/projects/project/index", ["exports", "ticust-web-module-projects/controllers/interlocutors/interlocutor/projects/project/index", "ti-web-shared/mixins/toastMixin"], function (_exports, _index, _toastMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ticust-web-module-projects/controllers/interlocutors/interlocutor/projects/project/index',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _index.default.reopen(_toastMixin.default, {
    // Services
    tagService: Ember.inject.service('tags'),
    identity: Ember.inject.service('identity-service'),
    project: undefined,
    projectPart: undefined,
    // Customer reader is the logged user
    readerId: Ember.computed.alias('identity.userId'),
    reader: Ember.computed('readerId', function () {
      return {
        id: this.get('readerId'),
        type: 'CLIENT'
      };
    }),
    _notifyProjectUpdate: function _notifyProjectUpdate(notificationType) {
      var _this = this;
      return this._super(notificationType).then(function (result) {
        // Xiti Call
        _this.get('tagService').sendClickTag({
          name: 'contacter::espace_echange_documents::prevenir_mon_conseiller',
          type: 'navigation'
        });
        return result;
      });
    },
    canAddDocs: Ember.computed.alias('identity.hasRoleProjectsFreeDocumentCreate'),
    showAddDocument: Ember.computed('projectPart.isReadOnly', 'canAddDocs', 'project.isReadOnly', function () {
      return this.get('canAddDocs') && !this.get('projectPart.isReadOnly') && !this.get('project.isReadOnly');
    }),
    toArray: Ember.on('init', Ember.observer('projectPartDocuments.[]', function () {
      var _this2 = this;
      if (this.get('projectPartDocuments')) {
        Object.keys(this.get('projectPartDocuments')).forEach(function (key) {
          _this2.set("projectPartDocuments.".concat(key), _this2.get("projectPartDocuments.".concat(key)).toArray());
        });
      }
    })),
    actions: {
      deleteFile: function deleteFile(file, document, isLastFile) {
        var _this3 = this;
        if (isLastFile) {
          document.set('status', this.ProjectPartDocumentStatus.to_upload());
          document.save().then(function () {
            _this3._destroyFile(file);
          }).catch(function (error) {
            _this3.showErrorAndLog('common-projects.file.message-info.error.delete', 'An error occurred during delete of project file.', error);
          });
        } else {
          this._destroyFile(file);
        }
      },
      deleteDocument: function deleteDocument(document, files) {
        var _this4 = this;
        return document.save().then(function () {
          // Delete all files, one by one
          var deleteFilesPromises = [];
          files.forEach(function (file) {
            return deleteFilesPromises.push(file.destroyRecord());
          });
          return Ember.RSVP.allSettled(deleteFilesPromises).then(function (array) {
            var errors = array.filter(function (promise) {
              return promise.status === 'rejected';
            }).map(function (promise) {
              return promise.reason;
            });

            // Stop if errors deleting files, or continue with deleting document.
            if (errors.length > 0) {
              _this4.showErrorAndLog('projects.project.documents.message-info.error.delete', 'An error occurred while deleting files of the document.', errors);
            } else {
              return document.destroyRecord().then(function () {
                _this4.showSuccess('projects.project.documents.message-info.info.delete');
                _this4.send('refreshModel');
              }).catch(function (error) {
                _this4.showErrorAndLog('projects.project.documents.message-info.error.delete', 'An error occurred while deleting the document.', error);
                document.set('status', _this4.ProjectPartDocumentStatus.to_upload());
                document.save();
              });
            }
          });
        }).catch(function (error) {
          _this4.showErrorAndLog('projects.project.documents.message-info.error.delete', 'An error occurred while deleting the document.', error);
        });
      },
      addNewDoc: function addNewDoc(document) {
        if (document.projectPart && document.projectPart.get('id') && this.projectPartDocuments[document.projectPart.get('id')]) {
          this.projectPartDocuments[document.projectPart.get('id')].pushObject(document);
        }
      }
    }
  });
});