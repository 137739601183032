define("lbp-cust-web-application/mixins/self-service", ["exports", "lbp-cust-web-application/utils/part-utils"], function (_exports, _partUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    // Service injection
    aiSelfService: Ember.inject.service('ai-self-service'),
    store: Ember.inject.service(),
    tiLogger: Ember.inject.service('logger-service'),
    wrapSendMessageCallback: function wrapSendMessageCallback(sendMessageCallback, message) {
      var _this = this;
      return function () {
        var responseTextRejected = _this.get('_selfServiceFoundTxt');
        if (responseTextRejected) {
          _this.addResponseTextRejected(message, responseTextRejected);
        }
        sendMessageCallback(message);
      };
    },
    addResponseTextRejected: function addResponseTextRejected(message, responseTextRejected) {
      this.get('tiLogger').info('Add responseTextRejected as a part in message', responseTextRejected, message);
      var mainBody = message.get('bodies.firstObject');
      if (mainBody.get('contentType') != 'multipart/alternative') {
        this.get('tiLogger').error('Message is not multi part, cannot add OWI part');
        return;
      }
      var owiTxtPart = _partUtils.default.createTextPlainPart(this.get('store'), responseTextRejected);
      owiTxtPart.set('headers.X-TI-OWI-ANSWER', ['owi-answer']);
      mainBody.get('bodies').pushObject(owiTxtPart);
    },
    displayAiPopup: function displayAiPopup(text, closeActionCallback) {
      this.set('_selfServiceFoundTxt', text);
      this.set('selfServiceCloseAction', closeActionCallback);
      this.set('_showSelfServiceModal', true);
    },
    selfServiceMessageInterception: function selfServiceMessageInterception(message, sendMessageCallback) {
      var _this2 = this;
      var wrappedCallback = this.wrapSendMessageCallback(sendMessageCallback, message);

      // Call Self Service (OWI) here
      return this.get('aiSelfService').checkSelfService(message).then(function (response) {
        _this2.set('_selfServiceResponse', response);
        if (response.hasResponse) {
          // Display response popup
          var aiResponseText = response.text;
          _this2.get('tiLogger').info('AI found a self service response', response);
          _this2.displayAiPopup(aiResponseText, wrappedCallback, message);
        } else {
          // Do the normal sendMessageCall
          _this2.get('tiLogger').info('AI did not find self service. ref : ', response.reference);
          // TODO add headers to message and copy values such as response.reference
          wrappedCallback();
        }
      }, function (reason) {
        _this2.set('_selfServiceResponse', null);
        // reason.errors should have error details

        // Error with AI Self Service, continue sending message
        _this2.get('tiLogger').warn('Error with AI Self Service, continue sending message', reason.errors);
        sendMessageCallback(message);
      });
    },
    // Additional Self Service validate handler
    selfServiceValidateMisc: function selfServiceValidateMisc() {
      // Override where mixin used
    },
    // Additional Self Service close handler
    selfServiceCloseMisc: function selfServiceCloseMisc() {
      // Override where mixin used
    },
    actions: {
      // Common Self Service validate handler
      selfServiceValidate: function selfServiceValidate() {
        this.set('_showSelfServiceModal', false);
        this.get('aiSelfService').sendSatisfaction(this.get('_selfServiceResponse'), true);

        // The user is satisfied with the self service response
        this.get('selfServiceValidateAction')();
        this.selfServiceValidateMisc();
      },
      // Common Self Service close handler
      selfServiceClose: function selfServiceClose() {
        this.set('_showSelfServiceModal', false);
        this.get('aiSelfService').sendSatisfaction(this.get('_selfServiceResponse'), false);

        // The user does not want the self service response.
        // Send the message
        this.get('selfServiceCloseAction')();
        this.selfServiceCloseMisc();
      }
    }
  });
  _exports.default = _default;
});