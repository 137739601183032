define("lbp-cust-web-application/components/ti-web-shared/projects/lbp-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    action: undefined,
    // By default indicators are not hidden for aria
    indicatorsAriaHidden: false,
    hasAction: Ember.computed.notEmpty('action'),
    actions: {
      indicatorAction: function indicatorAction() {
        this.get('action')();
      }
    }
  });
  _exports.default = _default;
});