define("lbp-cust-web-application/routes/app/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    identity: Ember.inject.service('identity-service'),
    model: function model() {
      var _this = this;
      return this.get('identity').loadRights().finally(function () {
        if (_this.get('identity.hasRoleConversationModuleAccess')) {
          _this.transitionTo('app.ticust-web-module-interactions');
        } else if (_this.get('identity.hasRoleProjectModuleAccess')) {
          _this.transitionTo('app.ticust-web-module-projects');
        } else {
          _this.get('tiLogger').info('The user has no access to any module');
        }
      });
    }
  });
  _exports.default = _default;
});