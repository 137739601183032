define("lbp-cust-web-application/templates/components/ti-web-shared/projects/project-informations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1QP4CjtJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"showCreationDate\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"project-desc-date\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"projects.project.description.createdOn\"],null],false],[0,\" \"],[1,[21,\"creationDate\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"showClosedDate\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"project-desc-date\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"projects.project.description.closedOn\"],null],false],[0,\" \"],[1,[21,\"closedDate\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"showFinishDate\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"project-desc-date\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"projects.project.description.finishedOn\"],null],false],[0,\" \"],[1,[21,\"finishDate\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/projects/project-informations.hbs"
    }
  });
  _exports.default = _default;
});