define("lbp-cust-web-application/initializers/components/ticust-web-module-projects/side-menu/side-menu", ["exports", "ticust-web-module-projects/components/ticust-web-module-projects/side-menu/side-menu", "lbp-cust-web-application/templates/components/side-menu/side-menu", "lbp-cust-web-application/mixins/side-menu", "lodash", "ember-uuid"], function (_exports, _sideMenu, _sideMenu2, _sideMenu3, _lodash, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ticust-web-module-projects/components/ticust-web-module-projects/side-menu/side-menu',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _sideMenu.default.reopen(_sideMenu3.default, {
    // Layout
    layout: _sideMenu2.default,
    tagName: 'div',
    // Services
    tagService: Ember.inject.service('tags'),
    ajax: Ember.inject.service(),
    identity: Ember.inject.service('identity-service'),
    countRefresh: 30000,
    // Data 
    _switchPopup: false,
    _backText: 'projects.side-menu.module.label',
    _switchPopupTitle: 'projects.side-menu.switch-module.popup.label',
    _switchPopupText: 'projects.side-menu.switch-module.popup.message-info.info.confirm-route',
    ajaxNamespace: Ember.computed.alias('config.namespace'),
    configSections: Ember.computed.oneWay('config.ticustWebModuleProjects.listSections'),
    listSections: Ember.computed('configSections', function () {
      var sections = _lodash.default.clone(this.get('configSections'));
      sections.push({
        label: 'projects.side-menu.switch-module.title',
        customClasses: 'main-folder main-folder-plus ',
        lines: [{
          customClasses: 'menu-icon-project-inbox project-icon-text',
          label: 'projects.side-menu.switch-module.label',
          action: {
            name: 'switch',
            buttonId: this.get('switchPopupId')
          }
        }]
      });
      return sections;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalId', 'popup-to-project-' + _emberUuid.default.v4());
      this.set('modalTitleId', 'popup-to-project-title-' + _emberUuid.default.v4());
      this.set('switchPopupId', _emberUuid.default.v4());
    },
    handleAction: function handleAction(action) {
      if (action === 'switch') {
        this.set('_switchPopup', true);
      }
    },
    _getProjectRequestData: function _getProjectRequestData(source) {
      (true && !(source) && Ember.assert('Missing source', source));
      switch (source) {
        case 'open':
          {
            return {
              status: 'IN_PROGRESS'
            };
          }
        case 'closed':
          {
            return {
              status: 'CLOSED'
            };
          }
        default:
          throw new Error('queryParam.source define as ' + source + ' is not defined.');
      }
    },
    actions: {
      routeToSwitchModule: function routeToSwitchModule() {
        this.get('tagService').sendClickTag({
          name: 'contacter::espace_echange_documents::acces_messagerie',
          type: 'navigation'
        });
        this.get('transitionToExternalRoute')('ticust-web-module-interactions');
      },
      updateBadgeAction: function updateBadgeAction(line) {
        var _this = this;
        var requestData;
        if (line.badge.type === 'project') {
          requestData = this._getProjectRequestData(line.badge.name);
        } else {
          throw new Error('Unknown badge type:' + line.badge.type);
        }
        requestData['interlocutor'] = this.get('model.id');
        return this.get('ajax').raw(this.get('ajaxNamespace') + '/projects/count', {
          method: 'GET',
          headers: this.get('identity.ajaxHeaders'),
          data: requestData
        }).then(function (result) {
          var response = result.response;
          return response.total;
        }).catch(function (error) {
          _this.get('tiLogger').error('An error appear during count', error);
          return null;
        });
      }
    }
  });
});