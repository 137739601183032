define("lbp-cust-web-application/validators/label-name-unique", ["exports", "ticust-web-module-interactions/validators/label-name-unique"], function (_exports, _labelNameUnique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _labelNameUnique.default;
    }
  });
});