define("lbp-cust-web-application/components/ti-web-shared/modals/image-preview", ["exports", "ti-web-shared/components/ti-web-shared/modals/image-preview"], function (_exports, _imagePreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _imagePreview.default;
    }
  });
});