define("lbp-cust-web-application/services/ai-self-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    // Timeout in ms
    selfServiceTimeout: 5000,
    /**
     * @param {number} msWait
     * @param {string} error - error message
     * @return {Promise}
     */
    callTimeout: function callTimeout(msWait, error) {
      return new Promise(function (resolve, reject) {
        var errorPayload = {
          errors: []
        };
        errorPayload.errors.push(error);
        setTimeout(function () {
          return reject(errorPayload);
        }, msWait);
      });
    },
    callSelfService: function callSelfService(selfServiceRequest) {
      var request = selfServiceRequest;

      // let htmlBody = request.message.get('bodyTextHtmlParts.firstObject.body');
      var textBody = request.message.get('bodyTextPlainParts.firstObject.body');

      // TODO EWLTILBPEE-128 Create real call to OWI Self Service Endpoint

      // Mock self service response
      return new Promise(function (resolve) {
        var selfServiceResponse = {
          status: 'OK',
          hasResponse: false,
          reference: undefined
        };

        // Just to see quickly it's mocked
        selfServiceResponse.isMocked = true;

        // Random Id
        var owiMockedId = Math.random().toString(36).substring(2, 10);
        selfServiceResponse.reference = owiMockedId;

        // Response Scenarios of demo. Assume that OWI only returns plain text
        if (textBody.contains('demo')) {
          selfServiceResponse.hasResponse = true;
          /* eslint-disable max-len */
          selfServiceResponse.text = 'Dans sa démarche de "Banque et Citoyenne". La Banque Postale propose à l\'ensemble de ses clients le service de relevé de compte en ligne. \n' +
          // max-len
          'Le e-Relevé est le format électronique de votre relevé de compte papier.\n' + 'Ce service vous adresse vos relevés de compte CCP et épargne (1) au format électronique. Chaque mois, un email vous est envoyé sur votre messaagerie personnelle pour vous avertir de la mise à disposition de votre nouveau relevé de compte en ligne. Vous pouvez alors le consulter ou encore le télécharger si vous le souhaitez.\n' + 'Le e-Relevé répond aux mêmes contraintes légales que le relevé papier, il à le même format. Seule le mode de mise à disposition change. Il a exactement la même apparence que le format papier, et affiche les mêmes informations sans exception.\n' + '\n' + '(1) Excepté le Plan d\'Epargne Populaire';
          /* eslint-enable max-len */
        } else if (textBody.contains('test')) {
          selfServiceResponse.hasResponse = true;
          selfServiceResponse.text = 'Generic test response message from Mock.';
        } else if (textBody.contains('perdu ma carte')) {
          selfServiceResponse.hasResponse = true;
          selfServiceResponse.text = 'Si vous avez perdu votre carte bancaire, ' + 'appelez directement le 0800 123 123 pour faire opposition.';
        } else {
          selfServiceResponse.hasResponse = false;
        }

        // If text contains "timeout" it should behave like OWI was too long

        if (textBody.contains('timeout')) {
          // Repond after 15s to simulate long OWI delay.
          setTimeout(function () {
            return resolve(selfServiceResponse);
          }, 15000);
        } else {
          // Repond after 3s to simulate normal OWI delay.
          setTimeout(function () {
            return resolve(selfServiceResponse);
          }, 3000);
        }
      });
    },
    checkSelfService: function checkSelfService(msg) {
      var selfServiceRequest = {};
      selfServiceRequest.message = msg;
      var timeoutError = {
        status: '504',
        title: 'Self Service response too long'
      };

      // Run tasks with timeout error
      return Promise.race([this.get('callSelfService')(selfServiceRequest), this.get('callTimeout')(this.selfServiceTimeout, timeoutError)]).then(function (rawResponse) {
        // Handle Self Service raw repsonse
        // ...
        return rawResponse;
      });
    },
    /** Send back statistics to the AI for improvement
     * @param {object} response holds the selfServiceResponse with response.reference
     * @param {boolean} isOk true if the response was good, false otherwise
     */
    sendSatisfaction: function sendSatisfaction() {
      // Mock, do nothing
      // TODO OWI : send usage OK/KO.
    }
  });
  _exports.default = _default;
});