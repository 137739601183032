define("lbp-cust-web-application/initializers/routes/ticust-web-module-interactions/interlocutors/interlocutor/conversations/drafts/draft", ["exports", "ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/drafts/draft"], function (_exports, _draft) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/drafts/draft',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _draft.default.reopen({
    queue: Ember.inject.service(),
    model: function model(params) {
      var _this = this;
      return this.get('store').findRecord('conversation', params.conversation_id).then(function (conversation) {
        var promises = {
          messages: conversation.get('messages'),
          settings: _this.getSettingsPromise(),
          // from abstract route
          interlocutorSelectedInHeaderBar: _this.modelFor('interlocutors.interlocutor')
        };
        return Ember.RSVP.hash(promises).then(function (model) {
          model.conversation = conversation;
          return model;
        });
      }).then(function (model) {
        // Manage themes
        model.themes = _this._getThemesPromise();
        model.pageSource = params.pageSource;
        return Ember.RSVP.hash(model);
      });
    },
    actions: {
      cancelDraftInRoute: function cancelDraftInRoute(transition) {
        if (transition) {
          transition.retry();
        } else {
          this._super();
        }
      },
      updateConversationInRoute: function updateConversationInRoute(messageContent, status) {
        var _this2 = this;
        var transition = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var autoSaving = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
        var displaySubjectError = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
        this.set('controller.isSaving', true);
        this.get('queue').push(function () {
          var conversation = _this2.get('controller.conversation');
          var message = conversation.get('messages.lastObject');

          // Update conversation
          if (status === 'SENT') {
            conversation.set('status', 'IN_PROGRESS');
          }

          // Update conversation to add theme information
          var caracteristics = _this2._addThemeInputInCaracteristics(conversation.get('caracteristics'));
          conversation.set('caracteristics', caracteristics);

          // Update message
          messageContent = _this2._addLbpHeaders(messageContent);
          message.set('status', status);
          message.set('messageContent', messageContent);
          message.set('receiveDate', new Date());
          var promise = undefined;
          if (displaySubjectError) {
            promise = new Promise(function (resolve) {
              resolve(conversation);
            });
          } else {
            promise = conversation.save();
          }
          return promise.then(function (conversation) {
            return message.save().then(function () {
              if (!autoSaving) {
                if (status === 'SENT') {
                  _this2.showSuccess('interactions.conversation.success.send');
                  _this2.transitionTo('interlocutors.interlocutor.conversations.conversation', _this2.modelFor('interlocutors.interlocutor'), conversation.id, {
                    queryParams: {
                      pageSource: 'conversationSent'
                    }
                  });
                } else if (transition) {
                  transition.retry();
                } else {
                  _this2.showSuccess('interactions.conversation.draft.message-info.info.save');
                  _this2.transitionTo('interlocutors.interlocutor.conversations.sources.source', _this2.modelFor('interlocutors.interlocutor'), 'conversationMine');
                }
              }
            }).catch(function (error) {
              _this2.showErrorAndLog('interactions.conversation.draft.message-info.error.save.conversation-message', 'An error occurred while saving conversation-message in conversation / draft.', error);
              message.rollbackAttributes();
            });
          }).catch(function (error) {
            _this2.showErrorAndLog('interactions.conversation.draft.message-info.error.save.conversation', 'An error occurred while saving conversation in conversation / draft.', error);
            conversation.rollbackAttributes();
            message.rollbackAttributes();
          }).finally(function () {
            _this2.set('controller.isSaving', false);
          });
        }, autoSaving ? 'continue' : 'stop', 'message');
      }
    }
  });
});