define("lbp-cust-web-application/components/ticust-web-module-interactions/editor/message-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: 'message-editor',
    intl: Ember.inject.service(),
    text: '',
    idTextarea: undefined,
    showHistoryBis: undefined,
    placeholderType: "response",
    ariaDescribedBy: undefined,
    plainText: Ember.computed('text', function computePlainText() {
      var plainText = this.get('text').replace(/(<p>|<\/p>)/g, '').replace(/&nbsp;/g, ' ');
      /* Simply letting Ember updating the textarea with data binding crashes the app in IE11
         due to a known bug. Here, the workaround is to update the textarea value directly.
         However, the element might not be created in the DOM on the first run
         so we rerun the function until it is.
         See https://github.com/glimmerjs/glimmer-vm/issues/345 */
      var textareaElement = this.get('element').getElementsByTagName('textarea')[0];
      if (textareaElement) {
        textareaElement.value = plainText;
      } else {
        Ember.run.later(computePlainText.bind(this), 100);
      }
      return plainText;
    }),
    placeholder: Ember.computed('intl', function () {
      return this.get('intl').t('interactions.conversation.composer.editor.placeholder.' + this.get('placeholderType'));
    }),
    ariaLabel: Ember.computed('intl', function () {
      return this.get('intl').t('interactions.conversation.composer.editor.aria-label.' + this.get('placeholderType'));
    }),
    // IE displays the placeholder even if there is content on first render
    displayPlaceholder: Ember.computed('plainText', function () {
      return this.get('plainText').length === 0;
    }),
    isReadOnly: false,
    disabled: Ember.computed('isReadOnly', function () {
      if (this.get('isReadOnly') === true) {
        return 'true';
      } else {
        return null;
      }
    }),
    init: function init() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      this._super(args);
    },
    actions: {
      onTextChanged: function onTextChanged(event) {
        var newText = '<p>' + event.target.value.replace(/\n/g, '</p>\n<p>').replace(/<p>\s*<\/p>/g, '<p>&nbsp;</p>') + '</p>';
        // eslint-disable-next-line ember/closure-actions
        this.sendAction('onTextChanged', newText);
      },
      displayHistory: function displayHistory() {
        if (this.get('showHistoryBis')) {
          this.get('showHistoryBis')(false);
        }
      }
    }
  });
  _exports.default = _default;
});