define("lbp-cust-web-application/initializers/components/ticust-web-module-interactions/label-line-component", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/label-line-component", "ember-uuid"], function (_exports, _labelLineComponent, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-next-line max-len

  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/components/ticust-web-module-interactions/label-line-component',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _labelLineComponent.default.reopen({
    showModalDeletion: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalDeletionId', 'label-modal-' + _emberUuid.default.v4());
      this.set('modalDeletionTitleId', 'label-modal-title-' + _emberUuid.default.v4());
      this.set('triggerDeleteButtonId', 'trigger-modal-delete-' + _emberUuid.default.v4());
    },
    actions: {
      toggleModalDeletion: function toggleModalDeletion() {
        this.toggleProperty('showModalDeletion');
      }
    }
  });
});