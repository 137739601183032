define("lbp-cust-web-application/utils/identity-utils", ["exports", "ti-web-shared/utils/identity-utils"], function (_exports, _identityUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _identityUtils.default;
    }
  });
});