define("lbp-cust-web-application/components/common/dropdown/dropdown-component", ["exports", "ember-uuid", "ember-keyboard"], function (_exports, _emberUuid, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    // Layout
    tagName: '',
    actionDivId: undefined,
    init: function init() {
      this._super();
      if (this.get('actionDivId') === undefined) {
        this.set('actionDivId', 'action-div-id-' + _emberUuid.default.v4());
      }
    },
    // Parameters
    iconClass: undefined,
    label: '',
    // Data
    _panelDropDownOpened: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.handleKeyDownListener = this._closeMenuIfNotFocus.bind(this);
      document.addEventListener("keydown", this.handleKeyDownListener);
    },
    _closeMenuIfNotFocus: function _closeMenuIfNotFocus() {
      var _this = this;
      Ember.run.later(function () {
        var element = document.getElementById('panel-drop-down-region');
        if (element) {
          if (!element.contains(document.querySelector(':focus'))) {
            _this._closeMenu();
          }
        }
      }, 10);
    },
    // eslint-disable-next-line ember/no-on-calls-in-components
    activateKeyboard: Ember.on('init', function () {
      this.set('keyboardActivated', true);
    }),
    closeOnEscapeKey: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function () {
      if (this.get('_panelDropDownOpened') === true) {
        document.getElementById(this.get('actionDivId')).focus();
      }
      this._closeMenu();
    }),
    ariaExpanded: Ember.computed('_panelDropDownOpened', function () {
      return this.get('_panelDropDownOpened');
    }),
    _panelDropDownStyle: Ember.computed('_panelDropDownOpened', function () {
      return this.get('_panelDropDownOpened') ? 'opened' : 'closed';
    }),
    _actionDivStyle: Ember.computed('_panelDropDownOpened', function () {
      return 'lbp-dropdown-component_action-div lbp-panel-header-prev-action' + (this.get('_panelDropDownOpened') ? ' focus' : '');
    }),
    _closeMenu: function _closeMenu() {
      if (!this.get('isDestroyed')) {
        this.set('_panelDropDownOpened', false);
      }
      this._removeEvent();
    },
    _removeEvent: function _removeEvent() {
      if (this.get('_clickListener')) {
        document.removeEventListener('click', this.get('_clickListener'), true);
        this.set('_clickListener', null);
      }
    },
    _openMenu: function _openMenu() {
      var _this2 = this;
      this.set('_panelDropDownOpened', true);
      // Cheat to call the event after the action
      this.set('_clickListener', function () {
        return Ember.run.later(_this2, _this2._closeMenu);
      });
      document.addEventListener('click', this.get('_clickListener'), true);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._removeEvent();
      document.removeEventListener('keydown', this.handleKeyDownListener);
    },
    // Actions
    actions: {
      toggleDropDown: function toggleDropDown() {
        if (!this.get('_panelDropDownOpened')) {
          this._openMenu();
        } else {
          this._closeMenu();
        }
      }
    }
  });
  _exports.default = _default;
});