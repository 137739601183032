define("lbp-cust-web-application/initializers/services/ti-web-shared/user-idle", ["exports", "ember-user-activity/services/user-idle"], function (_exports, _userIdle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ember-user-activity/services/user-idle',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _userIdle.default.reopen({
    config: Ember.inject.service(),
    init: function init() {
      // 10 minutes
      this.set('IDLE_TIMEOUT', this.get('config.userIdleInSeconds') * 1000 || 600000);
      this._super.apply(this, arguments);
    }
  });
});