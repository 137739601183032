define("lbp-cust-web-application/initializers/services/ti-web-shared/topbar-service", ["exports", "ti-web-shared/services/topbar-service"], function (_exports, _topbarService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ti-web-shared/services/topbar-service',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _topbarService.default.reopen({
    config: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      // Watch the window width
      function handleResize() {
        this.set('_windowWidth', Ember.$(window).width());
      }

      // Run on start, to init state
      Ember.run.next(this, handleResize);
      // Update the state at each resize
      Ember.$(window).on('resize', Ember.run.bind(this, handleResize));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      Ember.$(window).off('resize');
      this._removeEvent();
    },
    isDisplayInModal: Ember.computed('_windowWidth', function () {
      return this.get('_windowWidth') < this.get('config.screenMd');
    }),
    // side-menu
    isDisplayInModalObserver: Ember.on('init', Ember.observer('isDisplayInModal', 'narrowScreenSidebar', function () {
      if (this.get('isDisplayInModal') && this.get('narrowScreenSidebar')) {
        this._registerEvent();
      } else {
        this._removeEvent();
      }
    })),
    keyDownAction: function keyDownAction(event) {
      if (event.keyCode === 27 && this.get('narrowScreenSidebar')) {
        this.set('narrowScreenSidebar', false);
        event.preventDefault();
        return false;
      }
      return true;
    },
    _registerEvent: function _registerEvent() {
      var _this = this;
      if (!this.get('_clickListener')) {
        this.set('_clickListener', function () {
          return _this.set('narrowScreenSidebar', false);
        });
        Ember.$('#wrapper').get(0).addEventListener('click', this.get('_clickListener'), true);
      }
      if (!this.get('_keyListener')) {
        this.set('_keyListener', function (event) {
          return _this.keyDownAction(event);
        });
        Ember.$('body').get(0).addEventListener('keydown', this.get('_keyListener'), true);
      }
    },
    _removeEvent: function _removeEvent() {
      if (this.get('_clickListener')) {
        Ember.$('#wrapper').get(0).removeEventListener('click', this.get('_clickListener'), true);
        this.set('_clickListener', null);
      }
      if (this.get('_keyListener')) {
        Ember.$('body').get(0).removeEventListener('keydown', this.get('_keyListener'), true);
        this.set('_keyListener', null);
      }
    }
  });
});