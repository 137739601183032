define("lbp-cust-web-application/initializers/components/ticust-web-module-interactions/conversation/conversation-message-header", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message-header", "moment", "ember-uuid"], function (_exports, _conversationMessageHeader, _moment, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message-header',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _conversationMessageHeader.default.reopen({
    tiLogger: Ember.inject.service('logger-service'),
    intl: Ember.inject.service(),
    accessibleDateService: Ember.inject.service('accessible-date'),
    headerElement: undefined,
    headerId: undefined,
    // Data
    isRead: Ember.computed('message.readingDate', function () {
      return !!this.get('message.readingDate');
    }),
    nbAttachments: Ember.computed.alias('message.messageContent.attachmentParts.length'),
    expirationDate: Ember.computed.alias('conversation.expiringDate'),
    displayExpirationDate: Ember.computed.notEmpty('expirationDate'),
    init: function init() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      this._super(args);
      this.set('headerId', _emberUuid.default.v4());
    },
    accessibleExpirationDate: Ember.computed('expirationDate', function () {
      if (!this.get('expirationDate')) {
        return undefined;
      }
      var date = (0, _moment.default)(this.get('expirationDate')).subtract(this.get('expirationDate').getTimezoneOffset(), 'm');
      return this.get('accessibleDateService').format(date, {
        format: 'D M Y',
        language: 'fr',
        military: true
      });
    }),
    date: Ember.computed('displayedDate', function () {
      var date = (0, _moment.default)(this.get('displayedDate')); //.tz(moment.tz.guess());
      if (date && (0, _moment.default)().isSame(date, 'day')) {
        return (0, _moment.default)(date).format('HH[h]mm');
      }
      var format = 'ddd DD/MM HH[h]mm';
      if (!(0, _moment.default)().isSame(date, 'year')) {
        format = 'ddd DD/MM/YY HH[h]mm';
      }
      var formattedDate = (0, _moment.default)(date).format(format);
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    }),
    accessibleDate: Ember.computed('displayedDate', function () {
      var date = (0, _moment.default)(this.get('displayedDate')).subtract(this.get('displayedDate').getTimezoneOffset(), 'm');
      var yearFormat = this.get('displayedDate').getFullYear() !== new Date().getFullYear() ? ' Y' : '';
      return this.get('accessibleDateService').format(date, {
        format: "DD D M".concat(yearFormat, " \xE0 H heures MM"),
        language: 'fr',
        military: true
      });
    }),
    truncatedText: Ember.computed('message.messageContent.bodyTextPlainParts.firstObject.body', 'message.messageContent.body', function () {
      var plainMessageContent = this.get('message.messageContent.bodyTextPlainParts.firstObject.body');
      if (!plainMessageContent) {
        // If the message text content is stored in an unexpected manner in the multipart object,
        // the aria-label will show it as empty. See EWLTILBPEE-1417.
        return '';
      }
      return plainMessageContent.slice(0, 60);
    }),
    hasEmptyBody: Ember.computed('truncatedText', function () {
      return this.get('truncatedText').length === 0;
    }),
    sender: Ember.computed('fromMe', function () {
      if (this.get('fromMe').length > 0) {
        return this.get('intl').t('interactions.conversation-message-header.from.me');
      } else {
        return this.get('intl').t('interactions.conversation-message-header.from.adviser');
      }
    }),
    expandButtonLabel: Ember.computed('isExpanded', function () {
      var isReadIntlKey = '';
      if (!this.get('isExpanded')) {
        isReadIntlKey = this.get('isRead') ? '.isRead' : '.isNotRead';
      }
      var isExpandedIntlKey = this.get('isExpanded') ? '.shrink' : '.expand';
      var messageTypeIntlKey = this.get('displayExpirationDate') ? '.campaign' : '.regular';
      return "interactions.conversation-message.header.expand-button".concat(isExpandedIntlKey) + ".aria-label".concat(messageTypeIntlKey).concat(isReadIntlKey);
    }),
    expandButtonTitle: Ember.computed('isExpanded', function () {
      var isExpandedIntlKey = this.get('isExpanded') ? '.shrink' : '.expand';
      return "interactions.conversation-message.header.expand-button".concat(isExpandedIntlKey, ".title");
    }),
    _markAsRead: function _markAsRead() {
      var _this = this;
      if (this.get('isExpanded') && !this.get('message.readingDate')) {
        this.set('message.readingDate', new Date());
        this.get('message').save().catch(function (error) {
          _this.get('message').rollbackAttributes();
          _this.get('tiLogger').error('Cannot mark as read the message', error);
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('headerElement', document.getElementById('message-header-' + this.get('headerId')));
      if (this.get('headerElement')) {
        this.handleKeyDownListener = this._handleKeyDownAction.bind(this);
        this.get('headerElement').addEventListener('keydown', this.handleKeyDownListener);
      }
    },
    _handleKeyDownAction: function _handleKeyDownAction(event) {
      if (event.key === 'Enter' || event.key === ' ') {
        this.toggleProperty('isExpanded');
        this._markAsRead();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('headerElement')) {
        this.get('headerElement').removeEventListener('keydown', this.handleKeyDownListener);
      }
    },
    actions: {
      markAsRead: function markAsRead() {
        // If header is clicked, toggle the property isExpanded and mark the message as read if needed
        this.toggleProperty('isExpanded');
        this._markAsRead();
      }
    }
  });
});