define("lbp-cust-web-application/polyfills/html-polyfill", [], function () {
  "use strict";

  if (typeof HTMLCollection !== 'undefined' && HTMLCollection.prototype && !HTMLCollection.prototype.forEach) {
    Object.defineProperty(HTMLCollection.prototype, 'forEach', {
      value: Array.prototype.forEach,
      configurable: true,
      writable: true
    });
  }
  if (typeof FileList !== 'undefined' && FileList.prototype && !FileList.prototype.forEach) {
    Object.defineProperty(FileList.prototype, 'forEach', {
      value: Array.prototype.forEach,
      configurable: true,
      writable: true
    });
  }
});