define("lbp-cust-web-application/templates/components/ti-web-shared/projects/lbp-accordion-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GbeApj+4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"a11y/a11y-action-div\",null,[[\"id\",\"class\",\"action\",\"aria-expanded\",\"aria-controls\",\"label\"],[[23,[\"idElement\"]],\"project-accordion-trigger\",[27,\"action\",[[22,0,[]],\"toggle\"],null],[23,[\"ariaExpanded\"]],[23,[\"bodyElementId\"]],[23,[\"ariaLabel\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"project-accordion-trigger-body ellipsis no-space\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"icon\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"a11y/a11y-semantic-icon\",null,[[\"textClass\",\"fixClass\",\"iconClass\",\"srHidden\"],[\"\",\"fixSafari\",[27,\"concat\",[\"go-right project-icon \",[23,[\"icon\"]]],null],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"hasIndex\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"project-accordion-index\"],[9],[1,[21,\"index\"],false],[0,\".\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"span\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"project-accordion-trigger-icon center-trigger-icon\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"hasIndicator\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"project-accordion-indicator\"],[9],[1,[21,\"indicator\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[27,\"a11y/a11y-semantic-icon\",null,[[\"textClass\",\"fixClass\",\"iconClass\",\"srHidden\"],[\"\",\"fixSafari\",\"go-right project-icon accordion-trigger-icon noMargin center-trigger-icon\",true]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[12,\"id\",[28,[[21,\"bodyElementId\"]]]],[11,\"class\",\"project-accordion-detail\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/projects/lbp-accordion-element.hbs"
    }
  });
  _exports.default = _default;
});