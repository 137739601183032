define("lbp-cust-web-application/initializers/models/theme", ["exports", "ember-data", "ti-web-shared/models/theme"], function (_exports, _emberData, _theme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ti-web-shared/models/theme',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _theme.default.reopen({
    // Fields required
    additionalFields: _emberData.default.attr(),
    recipientEmail: _emberData.default.attr('string'),
    claimCode: _emberData.default.attr('string'),
    // Ref to the theme
    key: _emberData.default.attr(),
    forcedHeaders: _emberData.default.attr('string')
  });
});