define("lbp-cust-web-application/mixins/infinite-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    /**
     * Create infinite scroll based on id array.
     * @param {array} ids list of ids
     * @param {number} size size for each page
     * @param {function} getData function to get data based on id (ex: this.get('store').find('', id))
     * @param {boolean} unit <true> if the request must be done one by one
     * @param {pagesToGet} pagesToGet number of pages opened by user clicking on "See more" button
     * @return {*} list of data
     */
    infiniteScrollIds: function infiniteScrollIds(ids, size, getData) {
      var unit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      var pagesToGet = arguments.length > 4 ? arguments[4] : undefined;
      // Create response
      var response = Ember.ArrayProxy.create({
        content: Ember.A(),
        // eslint-disable-line
        meta: {
          first: true,
          hasNext: true,
          hasPrevious: false,
          number: 0,
          displayedItems: 0,
          size: size,
          totalItems: ids ? ids.length : 0,
          totalPages: 0
        }
      });

      // Check if there is something to process
      if (!ids || ids.length === 0) {
        return new Promise(function (resolve) {
          return resolve(response);
        });
      }

      // Process ids
      var splittedIds = this._splitData(ids, size);
      response.set('meta.totalPages', splittedIds.length);
      response.set('meta.hasNext', splittedIds.length > 1);

      // Get Data
      return this._seeMoreIds(response, splittedIds, getData, 0, unit, pagesToGet)();
    },
    _seeMorePreviousIds: function _seeMorePreviousIds(data, splittedIds, getData) {
      var _this = this;
      var previousIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
      return function () {
        var ids = splittedIds[previousIndex];
        var promise = getData(ids);
        return promise.then(function (hash) {
          var dataToAdd = Ember.A(); // eslint-disable-line
          hash.forEach(function (obj) {
            return dataToAdd.push(obj);
          });
          data.set('content', dataToAdd.concat(data.get('content')));

          // Get ready to get the previous data if it is not the first page
          if (previousIndex - 1 >= 0) {
            data.set('meta.hasPrevious', true);
            data.previous = _this._seeMorePreviousIds(data, splittedIds, getData, previousIndex - 1);
          } else {
            data.set('meta.hasPrevious', false);
            data.previous = undefined;
          }
          return data;
        });
      };
    },
    _seeMoreIds: function _seeMoreIds(data, splittedIds, getData) {
      var _this2 = this;
      var index = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
      var unit = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
      var pagesToGet = arguments.length > 5 ? arguments[5] : undefined;
      // Execute promise
      return function () {
        if (_this2.get('paginationHelper').isIndexSetUp()) {
          index = _this2.get('paginationHelper').getIndexEndResetIt();
        }
        var ids = splittedIds[index];

        // Create promise
        var promise = undefined;
        if (unit) {
          var promises = {};
          ids.forEach(function (id) {
            return promises[id] = getData(id);
          });
          promise = Ember.RSVP.hash(promises);
        } else {
          if (pagesToGet > 1) {
            // need to clear the array before
            ids = [];
            ids = _this2.get('paginationHelper').getIdsOfOpenedPages(splittedIds);
            if (!_this2.get('paginationHelper').isFirstPageDisplayed()) {
              data.set('meta.hasPrevious', true);
              var previousIndex = _this2.get('paginationHelper').getPreviousIndex();
              data.previous = _this2._seeMorePreviousIds(data, splittedIds, getData, previousIndex);
              // Update index position. Index show or hide the button: "see more"
              index = previousIndex + 2;
            }
          }
          promise = getData(ids);
        }
        return promise.then(function (hash) {
          // Transform response
          if (unit) {
            var values = Object.values(hash);
            data.set('content', data.get('content').concat(values));
          } else {
            var dataToAdd = Ember.A(); // eslint-disable-line
            hash.forEach(function (obj) {
              return dataToAdd.push(obj);
            });
            data.set('content', data.get('content').concat(dataToAdd));
          }

          // Update meta
          _this2._updateMeta(data, index, splittedIds.length);

          // Try to get the next data if it is not the last page
          if (index + 1 < splittedIds.length) {
            data.more = _this2._seeMoreIds(data, splittedIds, getData, index + 1, unit);
          } else {
            data.more = undefined;
          }
          return data;
        });
      };
    },
    /**
     * Create infinite scroll based on preload data.
     * @param {array} data list of data already load
     * @param {number} size size for each page
     * @return {*} list of data
     */
    infiniteScrollPreload: function infiniteScrollPreload(data, size) {
      // Check if there is something to process
      if (data.get('meta.totalItems') === 0) {
        return data;
      }

      // Process data
      var splittedData = this._splitData(data.get('content'), size);
      data.get('content').clear();
      data.set('meta.totalPages', splittedData.length);
      this._seeMorePreload(data, splittedData)();
      return data;
    },
    _seeMorePreload: function _seeMorePreload(data, splittedData) {
      var _this3 = this;
      var index = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      return function () {
        var dataToLoad = splittedData[index];

        // Set content
        data.set('content', data.get('content').concat(dataToLoad));

        // Set meta
        _this3._updateMeta(data, index, splittedData.length);

        // Try to get the next data if it is not the last page
        if (index + 1 < splittedData.length) {
          data.more = _this3._seeMorePreload(data, splittedData, index + 1);
        } else {
          data.more = undefined;
        }
        return data;
      };
    },
    /**
     * Create infinite scroll.
     * @param {array} data list of data currently load
     * @param {function} getData function to load data (ex: this.get('store').query())
     * @return {*} list of data
     */
    infiniteScroll: function infiniteScroll(data, getData) {
      // Try to get the next data if it is not the last page
      if (data.meta.hasNext) {
        // Create promise to get the next data
        data.more = this._seeMore(data, getData);
      }
      return data;
    },
    _seeMore: function _seeMore(data, getData) {
      var _this4 = this;
      var nextPage = data.meta.number + 2;
      return function () {
        return getData(nextPage).then(function (hash) {
          // Add the new conversation to the first one
          data.set('content', data.content.concat(hash.content));

          // Set meta
          data.set('meta', hash.meta);
          data.set('meta.displayedItems', data.get('content').length);

          // Try to get the next data if it is not the last page
          if (hash.meta.hasNext) {
            // Create promise to get the next data
            data.more = _this4._seeMore(data, getData);
          } else {
            data.more = undefined;
          }
          return hash;
        });
      };
    },
    _splitData: function _splitData(data, size) {
      var splittedData = [];
      for (var i = 0; i < data.length; i += size) {
        splittedData.push(data.slice(i, i + size));
      }
      return splittedData;
    },
    _updateMeta: function _updateMeta(data, index, nbPages) {
      data.set('meta.number', index);
      data.set('meta.displayedItems', data.get('content').length);
      data.set('meta.first', index === 0);
      data.set('meta.hasNext', index + 1 < nbPages);
    }
  });
  _exports.default = _default;
});