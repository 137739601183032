define("lbp-cust-web-application/serializers/setting", ["exports", "ticust-web-common/serializers/setting"], function (_exports, _setting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _setting.default;
    }
  });
});