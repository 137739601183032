define("lbp-cust-web-application/components/ticust-web-module-interactions/inbenta/inbenta-iframe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['inbenta-body'],
    identity: Ember.inject.service('identity-service'),
    tagService: Ember.inject.service('tags'),
    inbentaUrl: Ember.computed('url', 'identity.userCallingCode', function () {
      var callingCode = this.get('identity.userCallingCode');
      switch (callingCode) {
        // mobile + tablette
        case '9241':
        case '9240':
        case '9228':
        case '9251':
        case '9250':
          return this.get('url') + '?type=application';
      }
      return this.get('url');
    }),
    _listener: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$('#inbentaIframe').iFrameResize({
        log: false,
        // Enable console logging
        inPageLinks: true,
        heightCalculationMethod: 'lowestElement',
        checkOrigin: false,
        minHeight: 550
      });
      var _this = this;
      function receive(event) {
        if (event.data === 'inbentaContactSendMailEvent') {
          _this.get('tagService').sendClickTag({
            name: 'contacter::messagerie::reception_bouton_ecrire_a_lbp',
            type: 'navigation'
          });
          _this.get('onSendEmailEvent')();
        } else if (event.data === 'ClaimDepositEvent') {
          _this.get('onClaimDepositEvent')();
        }
      }
      this.set('_listener', receive);
      window.addEventListener('message', receive, false);
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('message', this.get('_listener'), false);
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});