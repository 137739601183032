define("lbp-cust-web-application/initializers/controllers/ticust-web-module-authentication/login", ["exports", "ticust-web-module-authentication/controllers/login"], function (_exports, _login) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ticust-web-module-authentication/controllers/login',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _login.default.reopen({
    _isTypoInvalid: Ember.computed('_showErrorTypo', 'model.validations.attrs.typo.isInvalid', function () {
      return this.get('_showErrorTypo') && this.get('model.validations.attrs.typo.isInvalid');
    }),
    _isFinancialCenterInvalid: Ember.computed('_showErrorFinancialCenter', 'model.validations.attrs.financialCenter.isInvalid', function () {
      return this.get('_showErrorFinancialCenter') && this.get('model.validations.attrs.financialCenter.isInvalid');
    }),
    _isAccountsInvalid: Ember.computed('_showErrorAccounts', 'model.validations.attrs.accounts.isInvalid', function () {
      return this.get('_showErrorAccounts') && this.get('model.validations.attrs.accounts.isInvalid');
    }),
    _isCreditAccountsInvalid: Ember.computed('_showErrorCreditAccounts', 'model.validations.attrs.creditAccounts.isInvalid', function () {
      return this.get('_showErrorCreditAccounts') && this.get('model.validations.attrs.creditAccounts.isInvalid');
    }),
    _isPhonesInvalid: Ember.computed('_showErrorPhones', 'model.validations.attrs.phones.isInvalid', function () {
      return this.get('_showErrorPhones') && this.get('model.validations.attrs.phones.isInvalid');
    }),
    _isReturnUrlInvalid: Ember.computed('_showErrorReturnUrl', 'model.validations.attrs.returnUrl.isInvalid', function () {
      return this.get('_showErrorReturnUrl') && this.get('model.validations.attrs.returnUrl.isInvalid');
    }),
    _isCallingCodeInvalid: Ember.computed('_showErrorCallingCode', 'model.validations.attrs.callingCode.isInvalid', function () {
      return this.get('_showErrorCallingCode') && this.get('model.validations.attrs.callingCode.isInvalid');
    })
  });
});