define("lbp-cust-web-application/templates/components/ti-web-shared/modals/blacklist-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XsFYQXGs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ti-web-shared/modals/modal-component\",null,[[\"isOpen\",\"modalTitleId\",\"modalTitle\",\"modalId\",\"focusBackId\",\"showConfirmButton\",\"showCancelButton\",\"showTriggerButton\",\"cancelButtonIntl\",\"cancel\"],[true,[23,[\"modalTitleId\"]],[27,\"t\",[\"common.blacklisted-modal.title\"],null],[23,[\"modalId\"]],\"side-menu\",false,true,false,\"common.blacklisted-modal.buttons.close\",[27,\"action\",[[22,0,[]],\"cancel\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"common.blacklisted-modal.main-text\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"common.blacklisted-modal.footer\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/modals/blacklist-modal.hbs"
    }
  });
  _exports.default = _default;
});