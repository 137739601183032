define("lbp-cust-web-application/components/ti-web-shared/projects/lbp-add-document", ["exports", "ember-uuid", "ti-web-shared/mixins/toastMixin"], function (_exports, _emberUuid, _toastMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO EWLTILBPEE-225 add all implementations and back calls
  var _default = Ember.Component.extend(_toastMixin.default, {
    tagName: '',
    // Services
    store: Ember.inject.service(),
    projectReaderService: Ember.inject.service(),
    identityService: Ember.inject.service(),
    tiLogger: Ember.inject.service('logger-service'),
    // Attributes
    projectPart: undefined,
    projectPartDocuments: undefined,
    eventHandler: {},
    reader: {
      id: undefined,
      type: undefined
    },
    // Internal Data
    _newDocType: undefined,
    // Last doc created
    _newDoc: undefined,
    _newDocErrorModalOpened: false,
    _newDocErrorModalKey: 'projects.project.documents.uploadOther.errorModal.text',
    _fileUploadResult: undefined,
    // Keep all models. To filter override this function
    _filteredDocumentModels: Ember.computed('documentModels.[]', function () {
      return this.get('documentModels').filter(function (m) {
        return m.get('providerType') === 'INTERLOCUTOR';
      });
    }),
    _defaultProviderForNewDocs: 'INTERLOCUTOR',
    _defaultCreatorForNewDocs: 'INTERLOCUTOR',
    showBlacklistedModal: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalErrorId', 'popup-add-document-' + _emberUuid.default.v4());
      this.set('modalErrorTitleId', 'popup-add-document-' + _emberUuid.default.v4());
    },
    // Wrap model and nature in a single object
    newDocTypesDisplay: Ember.computed('newDocTypes.[]', 'newDocTypes.@each.label', function () {
      var list = this.get('newDocTypes');
      var sortFct = function sortFct(a, b) {
        return a.get('label').localeCompare(b.get('label'));
      };
      return list.filter(function (nature) {
        return nature.get('type') === 'nature';
      }).map(function (nature) {
        var models = list.filter(function (model) {
          return model.get('type') === 'model' && model.get('data.naturebelongsTo') === nature.get('data.id');
        });
        nature.set('models', models.sort(sortFct));
        return nature;
      }).sort(sortFct);
    }),
    newDocTypes: Ember.computed('_filteredDocumentModels.[]', 'documentNatures.[]', function () {
      var options = [];
      if (this.get('_filteredDocumentModels')) {
        this.get('_filteredDocumentModels').forEach(function (docModel) {
          options.push(new Ember.Object({
            'id': _emberUuid.default.v4(),
            'type': 'model',
            'label': docModel.get('title'),
            'data': docModel
          }));
        });
      }
      if (this.get('documentNatures')) {
        this.get('documentNatures').forEach(function (docNature) {
          options.push(new Ember.Object({
            'id': _emberUuid.default.v4(),
            'type': 'nature',
            'label': docNature.get('label'),
            'data': docNature
          }));
        });
      }
      return options;
    }),
    isNewDocTypeSelected: Ember.computed.notEmpty('_newDocType'),
    selectAddNewDocumentPartId: Ember.computed('projectPart.id', function () {
      return 'select-add-new-document-part-' + this.get('projectPart.id');
    }),
    actions: {
      onBlacklistedModalClose: function onBlacklistedModalClose() {
        this.set('showBlacklistedModal', false);
      },
      addNewDoc: function addNewDoc() {
        var _this = this;
        if (this.get('identityService.isBlacklisted')) {
          this.set('showBlacklistedModal', true);
          return;
        }
        // Create new element
        var newDocType = this.get('_newDocType');
        var prop = {
          status: 'TO_UPLOAD',
          projectPart: this.get('projectPart'),
          type: 'FREE'
        };
        if (newDocType.get('type') === 'model') {
          prop.title = newDocType.get('data.title');
          prop.description = newDocType.get('data.description');
          prop.nature = newDocType.get('data.description');
          prop.providerType = newDocType.get('data.providerType');
          prop.creatorType = newDocType.get('data.creatorType');
          prop.creatorId = newDocType.get('data.creatorId');
        } else {
          prop.title = newDocType.get('data.label');
          prop.nature = newDocType.get('data');
          prop.providerType = this.get('_defaultProviderForNewDocs');
          prop.creatorType = newDocType.get('_defaultCreatorForNewDocs');
        }

        // Control verification
        if (this.get('projectPartDocuments').find(function (d) {
          return d.get('title') === prop.title;
        })) {
          // Cannot add doc with same name.
          this.set('_newDocErrorModalKey', 'projects.project.documents.uploadOther.errorModal.textDuplicate');
          this.set('_newDocErrorModalOpened', true);
          // Reset the component
          this.set('_newDocType', undefined);
          document.getElementById(this.get('selectAddNewDocumentPartId')).selectedIndex = 0;
          return;
        }

        // Create New Document
        var newDoc = this.get('store').createRecord('project-part-document', prop);
        var reader = this.get('reader');

        // Save it
        newDoc.save().then(function (saved) {
          // Callback to add it to the list.
          _this.get('addNewDoc')(saved);

          // Save new doc for the add file popup
          _this.set('_newDoc', saved);

          // sucess popup/toast
          _this.showSuccess('projects.project.documents.uploadOther.confirmModal.text', {
            type: saved.get('title')
          });

          // Save reader
          saved.set('readers', [reader]);
          return saved;
        }).then(function (saved) {
          return _this.get('projectReaderService').markDocumentAsRead(saved.get('id'), reader.id);
        }).catch(function (error) {
          _this.get('tiLogger').error('ERROR', error);
          _this.set('_newDocErrorModalKey', 'projects.project.documents.uploadOther.errorModal.text');
          _this.set('_newDocErrorModalOpened', true);
        });

        // Reset the component
        this.set('_newDocType', undefined);
        document.getElementById(this.get('selectAddNewDocumentPartId')).selectedIndex = 0;
      },
      openNewDocFileModal: function openNewDocFileModal() {
        this.get('openAddFileDialogAction')(this.get('projectPart'), this.get('_newDoc'));
      },
      onNewDocTypeChanged: function onNewDocTypeChanged(value) {
        var docType = this.get('newDocTypes').find(function (docType) {
          return docType.get('id') === value;
        });
        this.set('_newDocType', docType);

        // iOS a11y fix
        document.getElementById(this.get('selectAddNewDocumentPartId')).focus();
      }
    }
  });
  _exports.default = _default;
});