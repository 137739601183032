define("lbp-cust-web-application/initializers/components/ti-web-shared/documents/document-file-uploader", ["exports", "ti-web-shared/components/ti-web-shared/documents/document-file-uploader"], function (_exports, _documentFileUploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ti-web-shared/components/ti-web-shared/documents/document-file-uploader',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _documentFileUploader.default.reopen({
    actions: {
      uploadAttachmentOnStartUpload: function uploadAttachmentOnStartUpload(file) {
        var fileObject = Ember.Object.create({
          contentRef: null,
          isUploaded: false,
          label: file.name.split('.').slice(0, -1).join('.') + '.pdf',
          contentType: 'application/pdf',
          size: file.size,
          file: file
        });
        this.get('isUploading')(fileObject);
        this.get('_attachments').addObject(fileObject);
      },
      click: function click() {
        Ember.$('#' + this.get('uploaderId')).trigger('click');
      }
    }
  });
});