define("lbp-cust-web-application/models/enums/conversation-status", ["exports", "ti-web-shared/models/enums/conversation-status"], function (_exports, _conversationStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _conversationStatus.default;
    }
  });
});