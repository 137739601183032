define("lbp-cust-web-application/templates/components/ti-web-shared/projects/project-element-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AehVOhVC",
    "block": "{\"symbols\":[\"line\",\"&default\"],\"statements\":[[7,\"h3\"],[11,\"aria-level\",\"3\"],[9],[1,[27,\"t\",[\"projects.project-group-title.projects\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"projects-results\"],[9],[0,\"\\n\"],[4,\"ti-web-shared/panels/results-list\",null,[[\"elements\",\"isLoading\",\"hasError\"],[[23,[\"elements\"]],[23,[\"_isLoading\"]],[23,[\"_hasError\"]]]],{\"statements\":[[0,\"    \"],[14,2,[[22,1,[]],[23,[\"style\"]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"_maxPage\"]],1],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ti-web-shared/pagination-component\",null,[[\"currentPage\",\"lastPage\"],[[23,[\"_page\"]],[23,[\"_maxPage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/projects/project-element-list.hbs"
    }
  });
  _exports.default = _default;
});