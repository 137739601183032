define("lbp-cust-web-application/components/ticust-web-module-interactions/label-line-component", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/label-line-component"], function (_exports, _labelLineComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _labelLineComponent.default;
    }
  });
});