define("lbp-cust-web-application/services/recorder-service", ["exports", "ti-web-shared/services/recorder-service"], function (_exports, _recorderService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _recorderService.default;
    }
  });
});