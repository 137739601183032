define("lbp-cust-web-application/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this.transitionTo('ticust-web-module-authentication.sso-error');
    }
  });
  _exports.default = _default;
});