define("lbp-cust-web-application/helpers/ellipsis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.ellipsis = ellipsis;
  function ellipsis(param) {
    return param.reduce(function (text, size) {
      var tmp = document.createElement('div');
      tmp.innerHTML = text;
      var tmpText = tmp.textContent || tmp.innerText || '';
      return tmpText.substring(0, size) + '...';
    });
  }
  var _default = Ember.Helper.helper(ellipsis);
  _exports.default = _default;
});