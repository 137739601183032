define("lbp-cust-web-application/components/claim-form", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    lbpservice: Ember.inject.service('lbp-service'),
    userIdle: Ember.inject.service(),
    tiLogger: Ember.inject.service('logger-service'),
    identity: Ember.inject.service('identity-service'),
    theme: undefined,
    claimFormUrl: Ember.computed('url', 'identity.userCallingCode', 'identity.userDefaultAccount.nature', 'identity.userDefaultAccount.number', 'identity.userFinancialCenter', 'theme.id', 'theme.key', 'theme.label', function () {
      var callingCode = this.get('identity.userCallingCode');
      var claimFormUrl = new URL(this.get('url'));
      var themeKey = this.get('theme.key');
      var themeLabel = this.get('theme.label');
      if (themeKey !== undefined && themeKey !== null) {
        claimFormUrl.searchParams.set('X-BPCDSTH', themeKey);
        if (themeLabel !== undefined && themeLabel !== null) {
          claimFormUrl.searchParams.set('X-BPLBSTH', themeLabel);
        }
      }
      if (this.get('theme.id')) {
        claimFormUrl.searchParams.set('X-BPAETHEMEUUID', this.get('theme.id'));
      }
      claimFormUrl.searchParams.set('X-BPCODECF', this.get('identity.userFinancialCenter'));
      claimFormUrl.searchParams.set('X-BPTYPCPT', this.get('identity.userDefaultAccount.nature'));
      var now = (0, _moment.default)();
      var idMsg = 'MS' + now.format('YYYYMMDDHHmmss') + '_' + this.get('identity.userDefaultAccount.number');
      claimFormUrl.searchParams.set('X-BPIDMSG', idMsg);
      claimFormUrl.searchParams.set('DISFE_CCX_Code_Appelant', callingCode);
      return claimFormUrl.toString();
    }),
    _listener: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$('#claimIframe').iFrameResize({
        log: false,
        // Enable console logging
        inPageLinks: true,
        heightCalculationMethod: 'lowestElement',
        checkOrigin: false,
        minHeight: 550
      });
      var _this = this;
      function receive(event) {
        if (event.data === 'goToReceivedMailEvent') {
          _this.get('onReceivedMailEvent')();
        } else if (event.data === 'goToSendMailEvent') {
          _this.get('onSendEmailEvent')();
        } else if (event.data === 'RenewEesSession') {
          _this.get('onRenewEesSession')(_this, _this.userIdle, _this.tiLogger, _this.lbpservice);
        }
      }
      this.set('_listener', receive);
      window.addEventListener('message', receive, false);
      var element = document.querySelector('.panel-title.create-title span');
      if (element) {
        element.setAttribute('tabindex', '-1');
        element.focus();
      }
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('message', this.get('_listener'), false);
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});