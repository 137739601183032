define("lbp-cust-web-application/components/ti-web-shared/projects/lbp-action-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    // Attributes
    title: undefined,
    ariaLabel: undefined,
    icon: undefined,
    project: undefined,
    action: function action() {},
    showModal: function showModal() {},
    actions: {
      triggerAction: function triggerAction() {
        var callback = this.get('showModal');
        try {
          this.get('action')(this.get('project')).then(function (result) {
            if (result) {
              if (result !== undefined && result.status === 'success') {
                var successModal = result.successModal;
                callback(successModal);
              }
            }
          });
        } catch (error) {
          //just to catch the error if download action
        }
      }
    }
  });
  _exports.default = _default;
});