define("lbp-cust-web-application/components/ti-web-shared/modals/blacklist-modal", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalId', 'popup-blacklist-' + _emberUuid.default.v4());
      this.set('modalTitleId', 'popup-blacklist-' + _emberUuid.default.v4());
    },
    actions: {
      cancel: function cancel() {
        if (this.get('cancel')) {
          this.get('cancel')();
        }
      }
    }
  });
  _exports.default = _default;
});