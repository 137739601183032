define("lbp-cust-web-application/app", ["exports", "lbp-cust-web-application/resolver", "ember-load-initializers", "lbp-cust-web-application/config/environment", "lbp-cust-web-application/polyfills/html-polyfill"], function (_exports, _resolver, _emberLoadInitializers, _environment, _htmlPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    engines: {
      ticustWebModuleAuthentication: {
        dependencies: {
          services: ['hostApplication', 'store', 'intl', 'identity-service', 'session', 'cookies', 'ajax', 'toast', 'config', 'api-settings', 'lbp-service'],
          externalRoutes: {
            'host-base-route': 'index'
          }
        }
      },
      ticustWebModuleTopbar: {
        dependencies: {
          services: ['hostApplication', 'session', 'identity-service', 'intl', 'topbar-service', 'modules-manager', 'config', 'toast', 'api-settings'],
          externalRoutes: {
            'ember-auth-login': 'ticust-web-module-authentication.login'
          }
        }
      },
      ticustWebModuleInteractions: {
        dependencies: {
          services: ['hostApplication', 'store', 'intl', 'identity-service', 'spellchecker', 'session', 'cookies', 'ajax', 'ajax-binary', 'topbar-service', 'modules-manager', 'toast', 'config', 'api-settings', 'logger-service', 'tags', 'ai-self-service', 'lbp-message', 'lbp-service', 'pagination-helper'],
          externalRoutes: {
            'ember-auth-login': 'ticust-web-module-authentication.login',
            'ember-projects-details': 'app.ticust-web-module-projects.interlocutors.interlocutor.projects.project',
            'ember-settings': 'app.ticust-web-module-profile',
            'ticust-web-module-projects': 'app.ticust-web-module-projects'
          }
        }
      },
      ticustWebModuleProfile: {
        dependencies: {
          services: ['hostApplication', 'store', 'intl', 'identity-service', 'session', 'cookies', 'ajax', 'topbar-service', 'modules-manager', 'toast', 'config', 'api-settings'],
          externalRoutes: {
            'ember-auth-login': 'unused',
            'ember-settings': 'app.ticust-web-module-profile'
          }
        }
      },
      ticustWebModuleProjects: {
        dependencies: {
          services: ['hostApplication', 'store', 'intl', 'identity-service', 'session', 'cookies', 'ajax', 'topbar-service', 'modules-manager', 'toast', 'config', 'api-settings', 'logger-service', 'router', 'tags', 'lbp-service', 'project-reader-service'],
          externalRoutes: {
            'ember-auth-login': 'unused',
            'ember-settings': 'app.ticust-web-module-profile',
            'ticust-web-module-interactions': 'app.ticust-web-module-interactions',
            'ember-interactions': 'app.ticust-web-module-interactions.' + 'interlocutors.interlocutor.conversations.conversation'
          }
        }
      }
    }
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  window.onload = function () {
    var loading = document.getElementById('loading-text');
    if (loading) {
      loading.hidden = true;
    }
  };
  var _default = App;
  _exports.default = _default;
});