define("lbp-cust-web-application/initializers/controllers/ticust-web-module-projects/interlocutors/interlocutor/projects/index", ["exports", "ticust-web-module-projects/controllers/interlocutors/interlocutor/projects/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ticust-web-module-projects/controllers/interlocutors/interlocutor/projects/index',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _index.default.reopen({
    tagService: Ember.inject.service('tags'),
    queryParams: ['status'],
    status: 'open',
    statsStatuses: Ember.computed(function () {
      return [this.get('ProjectPartDocumentStatus.UPLOADED'), this.get('ProjectPartDocumentStatus.TO_ACKNOWLEDGE'), this.get('ProjectPartDocumentStatus.OK'), this.get('ProjectPartDocumentStatus.KO')];
    }),
    pageTitle: Ember.on('init', Ember.observer('status', function () {
      if (this.get('status') && this.get('status') !== 'open') {
        document.getElementById("dynamicTitle").innerText = this.get('intl').t('page.name.project', {
          pageName: this.get('intl').t('common-projects.side-menu.closed-projects')
        });
      } else {
        document.getElementById("dynamicTitle").innerText = this.get('intl').t('page.name.project', {
          pageName: this.get('intl').t('interlocutors.interlocutor.projects.search.open')
        });
      }
    })),
    actions: {
      openConversation: function openConversation(interlocutor, conversation) {
        this.send('transitionToExternal', 'ember-interactions', interlocutor.get('id'), conversation.get('id'), {
          queryParams: {
            pageSource: 'project'
          }
        });
      },
      openProject: function openProject(interlocutor, project) {
        this.transitionToRoute('interlocutors.interlocutor.projects.project', interlocutor.get('id'), project.get('id'));
      }
    }
  });
});