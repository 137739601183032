define("lbp-cust-web-application/services/logger-service", ["exports", "ti-web-shared/services/logger-service"], function (_exports, _loggerService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loggerService.default;
    }
  });
});