define("lbp-cust-web-application/initializers/component-lookup-override", ["exports"], function (_exports) {
  'use strict';

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'component-lookup-override',
    initialize: initialize
  }; //
  // IMPORTANT:
  // The definition below redefines some Ember components.
  // Update this file after an update is mandatory
  // see:
  // https://github.com/emberjs/ember.js/blob/v2.12.2/packages/ember-views/lib/component_lookup.js
  //
  _exports.default = _default;
  Ember.ComponentLookup.reopen({
    layoutFor: function layoutFor(name, owner, options) {
      (true && !(~name.indexOf('-')) && Ember.assert("You cannot use '".concat(name, "' as a component name. Component names must contain a hyphen."), ~name.indexOf('-')));
      var templateFullName = "template:components/".concat(name);
      // BEGIN CODE CHANGE
      // Try to load a component template override
      var defaultTemplate = this._super(name, owner, options);
      var overrideTemplate = null;
      var hostOwner = this.getHostOwner(owner);
      if (owner.mountPoint && hostOwner) {
        overrideTemplate = this._super(name, hostOwner, options);
      }
      return overrideTemplate || defaultTemplate;
      // END CODE CHANGE
    },
    getHostOwner: function getHostOwner(owner) {
      if (owner.get('engineParentContainer')) {
        return owner.get('engineParentContainer');
      }
      var engineKey = Object.keys(owner).filter(function (key) {
        return key.includes('__ENGINE_PARENT__');
      })[0];
      return owner[engineKey];
    }
  });
});