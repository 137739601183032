define("lbp-cust-web-application/components/file-upload", ["exports", "ticust-web-common/components/file-upload"], function (_exports, _fileUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fileUpload.default;
    }
  });
});