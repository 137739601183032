define("lbp-cust-web-application/templates/app/ticust-web-module-interactions/interlocutors/interlocutor/conversations/inbenta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fj01XZdQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"inbenta\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"inbenta-title-backButton\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"aria-level\",\"2\"],[9],[0,\"\\n      \"],[7,\"span\"],[9],[1,[27,\"t\",[\"interactions.conversations.inbenta.title\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"aria-hidden\",\"true\"],[11,\"class\",\"go-back\"],[9],[0,\"\\n\"],[4,\"a11y/a11y-action-div\",null,[[\"action\",\"class\"],[[27,\"action\",[[22,0,[]],\"backToList\"],null],\"\"]],{\"statements\":[[0,\"        \"],[1,[27,\"a11y/a11y-semantic-icon\",null,[[\"label\",\"iconClass\",\"srHidden\"],[[27,\"t\",[\"interactions.conversations.inbenta.return-to-list\"],null],\"icon-lbp-panel-modal-close\",true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"ticust-web-module-interactions/inbenta/inbenta-iframe\",null,[[\"url\",\"onSendEmailEvent\",\"onClaimDepositEvent\"],[[23,[\"model\",\"inbentaUrl\"]],[27,\"action\",[[22,0,[]],\"newMessage\"],null],[27,\"action\",[[22,0,[]],\"claim\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/app/ticust-web-module-interactions/interlocutors/interlocutor/conversations/inbenta.hbs"
    }
  });
  _exports.default = _default;
});