define("lbp-cust-web-application/controllers/interlocutors/interlocutor/conversations/claim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    isCurrentlyLoading: undefined,
    actions: {
      backToList: function backToList() {
        this.transitionToRoute('app.ticust-web-module-interactions.interlocutors' + '.interlocutor.conversations.sources.source', this.get('model.interlocutor'), 'conversationMine');
      },
      newMessage: function newMessage() {
        this.transitionToRoute('app.ticust-web-module-interactions.interlocutors.' + 'interlocutor.conversations.create', this.get('model.interlocutor'));
      },
      renewSession: function renewSession(_this, userIdle, tiLogger, lbpservice) {
        userIdle.resetTimeout();
        tiLogger.info('EES Session updated');
        lbpservice.keepAlive();
      }
    }
  });
  _exports.default = _default;
});