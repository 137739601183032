define("lbp-cust-web-application/initializers/controllers/ticust-web-module-interactions/interlocutors/interlocutor/labels/settings", ["exports", "ticust-web-module-interactions/controllers/interlocutors/interlocutor/labels/settings"], function (_exports, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/controllers/interlocutors/interlocutor/labels/settings',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _settings.default.reopen({
    tagService: Ember.inject.service('tags'),
    intl: Ember.inject.service(),
    originalFocusId: undefined,
    _confirmDelete: function _confirmDelete() {
      return true;
    },
    _limitReached: Ember.computed('labels.meta.totalElements', 'maxLabelSizeSettings', function () {
      return this.get('labels.meta.totalElements') >= this.get('maxLabelSizeSettings');
    }),
    _totalElements: Ember.computed('labels.meta.totalElements', function () {
      if (!this.get('labels')) {
        return 0;
      }
      return this.get('labels.meta.totalElements');
    }),
    _limitNotReached: Ember.computed.not('_limitReached'),
    _displayCreatePopup: Ember.computed.and('popupIsVisible', '_limitNotReached'),
    _displayCannotCreatePopup: Ember.computed('popupIsVisible', '_limitReached', {
      get: function get() {
        return this.get('popupIsVisible') && this.get('_limitReached');
      },
      set: function set(key, value) {
        this.set('popupIsVisible', value);
        return value;
      }
    }),
    // Override to always display the create button
    hasFooter: Ember.computed.alias('identity.hasRoleLabelModify'),
    pageTitle: Ember.on('init', Ember.observer('model.name', function () {
      if (this.get('model')) {
        document.getElementById("dynamicTitle").innerText = this.get('intl').t('page.name', {
          pageName: this.get('intl').t("interactions.label.settings.search.results.title-empty")
        });
      }
    })),
    actions: {
      createAction: function createAction(label) {
        var _this = this;
        label.set('owner', this.get('interlocutorCurrent'));
        label.save().then(function () {
          window.toastr.options.onHidden = function () {
            var newCreatedFolder = document.getElementById("folderName-" + label.name);
            if (newCreatedFolder) {
              newCreatedFolder.setAttribute('tabindex', '-1');
              newCreatedFolder.focus();
            }
          };
          _this.showSuccess('interactions.label.settings.message-info.info.create');
          _this.toggleProperty('popupIsVisible');
          _this.set('newLabel', null);
          _this.send('refreshAction');
        }).catch(function (error) {
          _this.showErrorAndLog('interactions.label.settings.message-info.error.create', 'An error appear during creation of the label.', error);
        });
      },
      addLabel: function addLabel() {
        // Create new data
        this.set('newLabel', this.get('store').createRecord('label'));

        // Send Tag
        this.get('tagService').sendPageTag({
          name: 'contacter::messagerie::popin_creation_dossier_personnel'
        });

        // Display popup to create a label
        this.set('popupIsVisible', true);
      }
    }
  });
});