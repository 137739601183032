define("lbp-cust-web-application/templates/components/common/modal/self-service-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4KsgOoRi",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ti-web-shared/modals/modal-component\",null,[[\"modalId\",\"modalTitle\",\"modalTitleId\",\"isOpen\",\"confirm\",\"cancel\",\"showConfirmButton\",\"showCancelButton\",\"showTriggerButton\",\"confirmButtonIntl\",\"cancelButtonIntl\",\"modalClass\",\"focusSelector\",\"ariaLabelledBy\"],[[23,[\"modalId\"]],[27,\"t\",[\"interactions.conversations.search.message-info.info.pop-up-removeFromTrashtitle\"],null],[23,[\"modalTitleId\"]],[23,[\"isOpen\"]],[23,[\"validate\"]],[23,[\"close\"]],true,true,false,[23,[\"validateButtonText\"]],[23,[\"closeButtonText\"]],\"self-service-modal\",[27,\"concat\",[\"#title-\",[23,[\"elementId\"]]],null],\"selfServiceLabel\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"panel-title-body panel-body-modal-title no-line-height\"],[9],[0,\"\\n    \"],[1,[21,\"text\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"panel-title-body panel-body-modal-title no-line-height\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[21,\"prompt\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/common/modal/self-service-modal.hbs"
    }
  });
  _exports.default = _default;
});