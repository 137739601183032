define("lbp-cust-web-application/components/ti-web-shared/projects/lbp-unread-documents-indicators", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    stats: undefined,
    action: undefined,
    _getUnreadDocumentsWithStatus: function _getUnreadDocumentsWithStatus(statuses) {
      var stats = this.get('stats');
      return _lodash.default.sumBy(_lodash.default.filter(stats, function (_ref) {
        var status = _ref.status;
        return _lodash.default.includes(statuses, status);
      }), 'nbDocumentsNotRead');
    },
    unreadNewDocuments: Ember.computed('stats.@each.{status,nbDocumentsNotRead}', function () {
      return this._getUnreadDocumentsWithStatus(['TO_ACKNOWLEDGE', 'UPLOADED']);
    }),
    hasUnreadNewDocuments: Ember.computed.gt('unreadNewDocuments', 0),
    unreadValidatedDocuments: Ember.computed('stats.@each.{status,nbDocumentsNotRead}', function () {
      return this._getUnreadDocumentsWithStatus(['OK']);
    }),
    hasUnreadValidatedDocuments: Ember.computed.gt('unreadValidatedDocuments', 0),
    unreadRejectedDocuments: Ember.computed('stats.@each.{status,nbDocumentsNotRead}', function () {
      return this._getUnreadDocumentsWithStatus(['KO']);
    }),
    hasUnreadRejectedDocuments: Ember.computed.gt('unreadRejectedDocuments', 0)
  });
  _exports.default = _default;
});