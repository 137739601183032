define("lbp-cust-web-application/initializers/routes/ticust-web-module-interactions/interlocutors/interlocutor/conversations/abstractSearchConversation", ["exports", "ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/abstractSearchConversation", "lbp-cust-web-application/mixins/infinite-scroll"], function (_exports, _abstractSearchConversation, _infiniteScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/abstractSearchConversation',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _abstractSearchConversation.default.reopen(_infiniteScroll.default, {
    ajax: Ember.inject.service(),
    ajaxNamespace: Ember.computed.alias('config.namespaceCustomer'),
    store: Ember.inject.service(),
    identity: Ember.inject.service('identity-service'),
    config: Ember.inject.service(),
    paginationHelper: Ember.inject.service('pagination-helper'),
    sizeOfSearch: 10,
    queryParams: {
      currentPage: {
        refreshModel: true
      },
      selectedSort: {
        refreshModel: true
      },
      searchText: {},
      searchPerimeter: {},
      // Query param to refresh current page from side menu
      search: {
        refreshModel: true
      }
    },
    showIsReadInConversations: function showIsReadInConversations() {
      return true;
    },
    resetController: function resetController(controller, isExiting, transition) {
      this._super(controller, isExiting, transition);
      if (isExiting) {
        controller.set('searchPerimeter', 'all');
      }
    },
    _getLabels: function _getLabels() {
      var payload = {
        owner: this.modelFor('interlocutors.interlocutor').get('id')
      };
      return this.get('store').query('label', payload);
    },
    _getConversations: function _getConversations(params) {
      // LBP - Manage search page : Avoid doing search if no text has been provided
      if (params.source === 'search' && !params.searchText) {
        return;
      }
      // Get request data
      var requestData = this._getRequestData(params);
      // Request to get conversation IDs

      return this.get('ajax').raw(this.get('ajaxNamespace') + '/conversations/ids', {
        method: 'GET',
        headers: this.get('identity.ajaxHeaders'),
        data: requestData
      });
    },
    _getMore: function _getMore(params, ids) {
      var requestData = this._getRequestData(params);
      requestData['ids'] = ids;
      return this.get('store').query('conversation', requestData);
    },
    _getRequestData: function _getRequestData(params) {
      var currentPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var requestData = this.requestData(params);
      requestData['owner'] = this.modelFor('interlocutors.interlocutor').get('id');
      // requestData['size'] = this.get('sizeOfSearch');
      if (!params.currentPage) {
        params.currentPage = currentPage;
      }
      requestData['page'] = params.currentPage - 1;
      if (!params.selectedSort) {
        params.selectedSort = 'lastMessageReceivedDate,desc';
      }
      requestData['sort'] = params.selectedSort;
      if (!params.searchText) {
        params.searchText = '';
      }
      requestData['text'] = params.searchText;
      return requestData;
    },
    model: function model(params) {
      var _this = this;
      var requestData = this.requestData(params);
      requestData['owner'] = this.modelFor('interlocutors.interlocutor').get('id');
      requestData['size'] = this.get('sizeOfSearch');
      if (!params.currentPage) {
        params.currentPage = 1;
      }
      requestData['page'] = params.currentPage - 1;
      if (!params.selectedSort) {
        params.selectedSort = 'lastMessageReceivedDate,desc';
      }
      requestData['sort'] = params.selectedSort;
      if (!params.searchText) {
        params.searchText = '';
      }
      requestData['text'] = params.searchText;
      return Ember.RSVP.hash({
        conversations: this._getConversations(params),
        buttonsConfig: this.displayButtons(params),
        showIsReadInConversations: this.showIsReadInConversations(params),
        hasRoleConversationModify: this.get('identity.hasRoleConversationModify'),
        hasRoleConversationDelete: this.get('identity.hasRoleConversationDelete'),
        labels: this._getLabels()
      }).then(function (hash) {
        // Manage infinite scroll
        hash.conversations = _this.infiniteScrollIds(hash.conversations ? hash.conversations.response.content : undefined, _this.get('sizeOfSearch'), function (ids) {
          return _this._getMore(params, ids);
        }, false, params.pagesToGet ? params.pagesToGet : 1);
        return Ember.RSVP.hash(hash);
      });
    }
  });
});