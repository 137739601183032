define("lbp-cust-web-application/components/ticust-web-module-interactions/conversation/conversation-action-bar", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-action-bar"], function (_exports, _conversationActionBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  var _default = _conversationActionBar.default;
  _exports.default = _default;
});