define("lbp-cust-web-application/initializers/components/ti-web-shared/projects/project-details-component", ["exports", "ti-web-shared/components/ti-web-shared/projects/project-details-component", "ti-web-shared/mixins/toastMixin"], function (_exports, _projectDetailsComponent, _toastMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ti-web-shared/components/ti-web-shared/projects/project-details-component',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _projectDetailsComponent.default.reopen(_toastMixin.default, {
    // Services
    intl: Ember.inject.service(),
    // Trigger to show the confirmation modal
    showNotifySuccess: false,
    isInProgress: Ember.computed.equal('project.status', 'IN_PROGRESS'),
    projectActions: Ember.computed('isInProgress', 'intl.locale', function () {
      var actions = [];

      // only if in progress
      if (this.get('isInProgress')) {
        actions.push({
          'title': this.get('intl').t('projects.project.actions.action-notif.title'),
          'ariaLabel': this.get('intl').t('projects.project.actions.action-notif.aria-label'),
          'icon': 'project-action-notif',
          'action': this.get('actions.notifyProjectUpdate').bind(this)
        });
      }

      // Always
      actions.push({
        'title': this.get('intl').t('projects.project.actions.action-download.title'),
        'ariaLabel': this.get('intl').t('projects.project.actions.action-download.aria-label'),
        'icon': 'project-action-download',
        'action': this.get('actions.download').bind(this)
      });
      return actions;
    }),
    pageTitle: Ember.on('init', Ember.observer('project.title', function () {
      if (this.get('project.title')) {
        document.getElementById("dynamicTitle").innerText = this.get('intl').t('page.name.project', {
          pageName: this.get('intl').t('page.name.project-info') + this.get('project.title')
        });
      }
    })),
    actions: {
      // Open requested modal
      showModal: function showModal(modalTrigger) {
        this.set(modalTrigger, true);
      },
      notifyProjectUpdate: function notifyProjectUpdate() {
        return this.notifyProjectUpdate();
      }
    }
  });
});