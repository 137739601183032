define("lbp-cust-web-application/helpers/numeric-unit-view", ["exports", "ti-web-shared/mixins/utils/numericUnit"], function (_exports, _numericUnit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend(_numericUnit.default, {
    tagName: '',
    compute: function compute(params) {
      return this.getUnit(params[0]);
    }
  });
  _exports.default = _default;
});