define("lbp-cust-web-application/components/panel-collapse", ["exports", "ticust-web-common/components/panel-collapse"], function (_exports, _panelCollapse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _panelCollapse.default;
    }
  });
});