define("lbp-cust-web-application/initializers/polyfill/object-polyfill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /* eslint-disable */

  function initialize() {
    Object.values = Object.values || function (object) {
      return Object.keys(object).map(function (key) {
        return object[key];
      });
    };
  }
  var _default = {
    name: 'lbp-cust-web-application/object-polyfill',
    initialize: initialize
  };
  _exports.default = _default;
});