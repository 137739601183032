define("lbp-cust-web-application/initializers/models/project", ["exports", "ti-web-shared/models/project"], function (_exports, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ti-web-shared/models/project',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _project.default.reopen({
    clientReaders: Ember.computed('readers.[]', function () {
      var result = [];
      if (this.get('readers')) {
        this.get('readers').forEach(function (r) {
          if (r.type === 'CLIENT') {
            result.push(r.id);
          }
        });
      }
      return result;
    }),
    advisorReaders: Ember.computed('readers.[]', function () {
      var result = [];
      if (this.get('readers')) {
        this.get('readers').forEach(function (r) {
          if (r.type === 'ADVISER') {
            result.push(r.id);
          }
        });
      }
      return result;
    })
  });
});