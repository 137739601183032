define("lbp-cust-web-application/initializers/controllers/ti-web-shared/interlocutors/interlocutor/projects/project", ["exports", "ti-web-shared/controllers/interlocutors/interlocutor/projects/project"], function (_exports, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ti-web-shared/controllers/interlocutors/interlocutor/projects/project',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _project.default.reopen({
    // LBP Override to replace popup
    _notifyProjectUpdate: function _notifyProjectUpdate() {
      var _this = this;
      // Determine type
      var notificationType = this.get('identity').isActor() ? 'SEND_UPDATE_TO_CUSTOMER' : 'SEND_UPDATE_TO_ADVISER';

      // Xiti Call
      if (notificationType === 'SEND_UPDATE_TO_ADVISER') {
        this.get('tagService').sendClickTag({
          name: 'contacter::espace_echange_documents::prevenir_mon_conseiller',
          type: 'navigation'
        });
      }

      // Call request
      var url = this.get('ajaxNamespace') + '/projects/notifyUpdate/' + this.get('project.id') + '/' + notificationType;
      return this.get('ajax').raw(url, {
        method: 'GET',
        dataType: 'text',
        headers: this.get('identity.ajaxHeaders')
      }).then(function () {
        // LBP: ShowSuccess replaced by popup.
        // Returns an object that will be handled by project-action.
        return {
          status: 'success',
          successModal: 'showNotifySuccess'
        };
      }).catch(function (error) {
        if (error.payload.code === 'LIMITED_CLICK_FOR_ADVISER' || error.payload.code === 'LIMITED_CLICK_FOR_CUSTOMER') {
          _this.showErrorAndLog('common-projects.project.message-info.error.' + error.payload.code, 'An error appear during sending a project update notification.', error, {
            limit: _this.get(error.payload.code)
          });
        } else {
          _this.showErrorAndLog('common-projects.project.message-info.error.unknown', 'An error appear during sending a project update notification.', error);
        }
      }).then(function (result) {
        // Add today date in project to refresh only the current indicator
        _this.set('project.lastNotifiedDate', new Date());
        return result;
      });
    },
    actions: {
      notifyProjectUpdate: function notifyProjectUpdate() {
        return this._notifyProjectUpdate();
      }
    }
  });
});