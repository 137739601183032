define("lbp-cust-web-application/initializers/components/ti-web-shared/side-panel/side-panel", ["exports", "ti-web-shared/components/ti-web-shared/side-panel/side-panel"], function (_exports, _sidePanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ti-web-shared/components/ti-web-shared/side-panel/side-panel',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _sidePanel.default.reopen({
    // Services
    topBarService: Ember.inject.service('topbar-service'),
    // Data
    isOpen: Ember.computed.alias('topBarService.narrowScreenSidebar'),
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      document.addEventListener("keydown", function () {
        Ember.run.later(function () {
          if (_this.get('isOpen')) {
            var element = document.getElementById('side-panel-container');
            if (!element.contains(document.querySelector(':focus'))) {
              _this.set('isOpen', false);
            }
          }
        }, 150);
      });
    }
  });
});