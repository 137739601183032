define("lbp-cust-web-application/components/ti-web-shared/generic-multi-selector", ["exports", "ti-web-shared/components/ti-web-shared/generic-multi-selector"], function (_exports, _genericMultiSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _genericMultiSelector.default;
    }
  });
});