define("lbp-cust-web-application/services/lbp-message", ["exports", "libmime", "moment"], function (_exports, _libmime, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    // Services
    identity: Ember.inject.service('identity-service'),
    addMessageHeaders: function addMessageHeaders(messageContent, interlocutor, nbAttachments) {
      // BGP customer
      var walletCustomer = interlocutor.get('wallet').split('_')[0];
      messageContent.set('headers.X-BPLBTHM', [_libmime.default.encodeWord('Messagerie Sécurisée')]);
      messageContent.set('headers.X-BPNBRPJ', [nbAttachments]);
      messageContent.set('headers.X-BPMSGFROM', [interlocutor.get('internalEmailAddress')]);
      messageContent.set('headers.X-LCBGP', [walletCustomer]);
      messageContent.set('headers.X-BPIDPART', [interlocutor.get('id')]);
      // X-LCCLIENT header deprecated but used by MS
      messageContent.set('headers.X-LCCLIENT', [interlocutor.get('displayAll')]);
      messageContent.set('headers.X-TYPO', [this.get('identity.userTypo')]);
      messageContent.set('headers.X-BPCDTYP', [this.get('identity.userTypo')]);
      messageContent.set('headers.X-BPNUMCPT', [this.get('identity.userDefaultAccount.number')]);
      messageContent.set('headers.X-BPTYPCPT', [this.get('identity.userDefaultAccount.nature')]);
      if (this.get('identity.userDefaultCreditAccount')) {
        messageContent.set('headers.X-BPNUMCRE', [this.get('identity.userDefaultCreditAccount.number')]);
      }
      messageContent.set('headers.X-BPCODECF', [this.get('identity.userFinancialCenter')]);
      // S for secured authentication level
      messageContent.set('headers.X-BPDGAUTH', ['S']);
      if (typeof this.get('identity.userPhones') !== 'undefined' && this.get('identity.userPhones').length > 0) {
        messageContent.set('headers.X-BPNUMTEL', [this.get('identity.userPhones')[0]]);
      }
      if (typeof messageContent.get('headers.X-LCCLIENT') == 'undefined') {
        messageContent.set('headers.X-LCCLIENT', ['(courriel inconnu)']);
      }
      if (typeof messageContent.get('headers.X-LCDPIDM') == 'undefined') {
        messageContent.set('headers.X-LCDPIDM', ['-']);
      }
      messageContent.set('headers.X-LCDPDT', ['']);
      if (typeof messageContent.get('headers.X-LCDPCONS') == 'undefined') {
        messageContent.set('headers.X-LCDPCONS', ['-']);
      }
      // If a legal guardian is logged in, set a header for it.
      if (this.get('identity.legalGuardianIdPph')) {
        messageContent.set('headers.X-TI-IDPPH-RL', [this.get('identity.legalGuardianIdPph')]);
      }
      return messageContent;
    },
    addHeadersForFields: function addHeadersForFields(messageContent, theme, fields) {
      var _this = this;
      // Add fields into headers
      if (fields) {
        fields.forEach(function (field) {
          messageContent = _this._setHeaderForField(messageContent, field.name, field.selectedOption ? field.selectedOption.value : null);
        });
      }
      return this._addHeaderForTheme(messageContent, theme);
    },
    addHeadersForFieldsReply: function addHeadersForFieldsReply(messageContent, conversation, theme, receivedMessage) {
      var _this2 = this;
      // Add fields into headers
      var caracteristics = conversation.get('data.caracteristics') || {};
      Object.keys(caracteristics).forEach(function (key) {
        if (key.startsWith('theme-')) {
          var name = key.substring('theme-'.length);
          var value = conversation.get('data.caracteristics.' + key);
          messageContent = _this2._setHeaderForField(messageContent, name, value);
        }
      });

      // Add fields into header from previous message in case of migration
      if (!caracteristics['theme-ACCOUNT_NUMBER'] && conversation.get('messages')) {
        var sentMessage = conversation.get('messages').find(function (m) {
          return m.get('status') == 'SENT';
        });
        if (sentMessage) {
          var sentMessageHeaders = sentMessage.get('messageContent.headers');
          // Manage account number
          var numAccHeader = sentMessageHeaders['X-BPNUMCPT'];
          if (numAccHeader) {
            var numAcc = numAccHeader[0];
            if (this.get('identity.userAccounts').any(function (a) {
              return a.number === numAcc;
            })) {
              messageContent.set('headers.X-BPNUMCPT', [numAcc]);
              messageContent.set('headers.X-BPTYPCPT', sentMessageHeaders['X-BPTYPCPT']);
            } else if (this.get('identity.userCreditAccounts').any(function (a) {
              return a.number === numAcc;
            })) {
              messageContent.set('headers.X-BPNUMCRE', [numAcc]);
            }
          }
        }
      }
      this._addHeaderFromReceivedMessage(messageContent, receivedMessage);
      return this._addHeaderForTheme(messageContent, theme);
    },
    _addHeaderFromReceivedMessage: function _addHeaderFromReceivedMessage(messageContent, receivedMessage) {
      if (messageContent && receivedMessage) {
        var bpfDemandeHeader = receivedMessage.get('messageContent.headers.X-BPFDEMANDE');
        if (bpfDemandeHeader) {
          messageContent.set('headers.X-BPFDEMANDE', bpfDemandeHeader);
        }
        messageContent.set('headers.WLRAISEDSKILL', receivedMessage.get('messageContent.headers.WLRAISEDSKILL'));
      }
    },
    _setHeaderForField: function _setHeaderForField(messageContent, name, value) {
      if (value) {
        switch (name) {
          case 'ACCOUNT_NUMBER':
            {
              var data = value.split('|');
              messageContent.set('headers.X-BPTYPCPT', [data[0]]);
              messageContent.set('headers.X-BPNUMCPT', [data[1]]);
              break;
            }
          case 'CREDIT_NUMBER':
            messageContent.set('headers.X-BPNUMCRE', [value]);
            break;
          case 'PHONE_NUMBER':
            messageContent.set('headers.X-BPNUMTEL', [value]);
            break;
        }
      }
      return messageContent;
    },
    _addHeaderForTheme: function _addHeaderForTheme(messageContent, theme) {
      // Get now date
      var now = (0, _moment.default)();

      // Create unique ID
      var account = messageContent.get('headers.X-BPNUMCPT.firstObject');
      var idMsg = 'MS' + now.format('YYYYMMDDHHmmss') + '_' + account;

      // Add headers
      messageContent.set('headers.X-BPIDMSG', [idMsg]);
      messageContent.set('headers.X-BPDTEMIS', [now.format('DDMMYYYYHHmmss')]);

      // Add header theme
      messageContent.set('headers.X-BPCDTHM', ['300000']);
      if (theme) {
        messageContent.set('headers.X-BPAETHEMEUUID', [theme.get('id')]);
        messageContent.set('headers.X-BPCDSTH', [theme.get('key')]);
        messageContent.set('headers.X-BPLBSTH', [_libmime.default.encodeWord(theme.get('label'))]);
        var forcedHeaders = theme.get('forcedHeaders');
        if (forcedHeaders) {
          messageContent.set('headers', this._applyForcedHeadersFromTheme(messageContent.get('headers'), JSON.parse(forcedHeaders)));
        }
      }
      return messageContent;
    },
    _applyForcedHeadersFromTheme: function _applyForcedHeadersFromTheme(headers, forcedHeaders) {
      var _this3 = this;
      forcedHeaders.forEach(function (header) {
        var headerValues = Object.values(header);
        var updatedHeader = {};
        updatedHeader[headerValues[0]] = [_this3._mimeEncodeIfString(headerValues[1])];
        if (updatedHeader) {
          Object.assign(headers, updatedHeader);
        }
      });
      return headers;
    },
    _mimeEncodeIfString: function _mimeEncodeIfString(header) {
      if (typeof header === 'string' || header instanceof String) {
        return _libmime.default.encodeWord(header);
      }
      return header;
    }
  });
  _exports.default = _default;
});