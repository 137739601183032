define("lbp-cust-web-application/initializers/ticust-web-common/string-polyfill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /* eslint-disable */

  function initialize() {
    String.prototype.contains = String.prototype.contains || function (str) {
      return this.indexOf(str) >= 0;
    };
    String.prototype.startsWith = String.prototype.startsWith || function (prefix) {
      return this.indexOf(prefix) === 0;
    };
    String.prototype.endsWith = String.prototype.endsWith || function (suffix) {
      return this.indexOf(suffix, this.length - suffix.length) >= 0;
    };
  }
  var _default = {
    name: 'ticust-web-common/string-polyfill',
    initialize: initialize
  };
  _exports.default = _default;
});