define("lbp-cust-web-application/routes/ticust-web-module-authentication/otu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    lbpService: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      var lbpService = this.get('lbpService');

      // transition to requested page.
      lbpService.get('contextPromise').then(function () {
        lbpService.dispatch(_this);
      }).catch(function () {
        lbpService.dispatch(_this);
      });
    }
  });
  _exports.default = _default;
});