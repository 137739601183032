define("lbp-cust-web-application/initializers/components/ti-web-shared/file-uploader", ["exports", "ti-web-shared/components/ti-web-shared/file-uploader"], function (_exports, _fileUploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ti-web-shared/components/ti-web-shared/file-uploader',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _fileUploader.default.reopen({
    isAllowedType: function isAllowedType(contentType, name) {
      if (!this.get('allowedTypes')) {
        // Is no defiend allowed type: all types are allowed
        return true;
      }

      // Handle CSV problem (ish... not a perfect fix, but it doesn't exist anyway)
      // If name end in .csv, change mime type to text/csv (RFC 4180 standard)
      if (name.split('.').pop() === 'csv') {
        contentType = 'text/csv';
      }
      return !!this.get('allowedTypes').find(function (type) {
        return contentType === type;
      });
    },
    isFileAllowed: function isFileAllowed(file) {
      if (!this.isAllowedType(file.type, file.name)) {
        this.showError('common.file-upload.error.contentType');
        return false;
      }
      if (!this.isAllowedSize(file.size)) {
        this.showError('common.file-upload.error.length');
        return false;
      }
      return true;
    }
  });
});