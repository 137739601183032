define("lbp-cust-web-application/initializers/components/ti-web-shared/side-menu/side-menu-section", ["exports", "ti-web-shared/components/ti-web-shared/side-menu/side-menu-section"], function (_exports, _sideMenuSection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ti-web-shared/components/ti-web-shared/side-menu/side-menu-section',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _sideMenuSection.default.reopen({
    hasRigthToSwitchModule: ''
  });
});