define("lbp-cust-web-application/templates/app/ticust-web-module-projects/interlocutors/interlocutor/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5Fq+zDxI",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"help-page\",[[23,[\"text\"]],\"projects\"],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/app/ticust-web-module-projects/interlocutors/interlocutor/help.hbs"
    }
  });
  _exports.default = _default;
});