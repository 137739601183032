define("lbp-cust-web-application/components/ticust-web-module-interactions/label/popup-component", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = Ember.Component.extend({
    isVisible: false,
    labelsAvailable: undefined,
    labelsUsed: undefined,
    originalFocusId: undefined,
    IsNotFocusableElement: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalId', 'popup-component-categorize-' + _emberUuid.default.v4());
      this.set('modalTitleId', 'popup-component-categorize-' + _emberUuid.default.v4());
    },
    data: Ember.computed('isOpen', 'labelsAvailable.[]', 'labelsUsed.[]', function () {
      var _this = this;
      // set focus on selected folder
      if (this.get('labelsAvailable') && this.get('isOpen')) {
        return this.get('labelsAvailable').map(function (label) {
          return Ember.Object.create({
            label: label,
            checked: _this._checkLabelUsage(label)
          });
        });
      }
      return [];
    }),
    _checkLabelUsage: function _checkLabelUsage(label) {
      if (this.get('labelsUsed')) {
        return this.get('labelsUsed').any(function (labelUsed) {
          return labelUsed.get('id') === label.get('id');
        });
      }
      return false;
    },
    onSave: function onSave() {
      throw new Error('The onSave callback is not defined');
    },
    onManage: function onManage() {
      throw new Error('The onManage callback is not defined');
    },
    actions: {
      saveAction: function saveAction() {
        // Get checked folder
        var selectedLabels = this.get('data').filter(function (label) {
          return label.get('checked');
        }).map(function (label) {
          return label.get('label');
        });

        // Call parent save action
        this.get('onSave')(selectedLabels);
      },
      // Manage action button
      manageAction: function manageAction() {
        this.get('onManage')();
      },
      // Close popup
      closeAction: function closeAction() {
        // call parent close action
        if (this.get('onClosePopup')) {
          this.get('onClosePopup')();
        }
      },
      // Change selected label
      labelChangeAction: function labelChangeAction(selectedLabel, uncheck) {
        this.get('data').forEach(function (label) {
          if (label.get('checked')) {
            label.set('checked', false);
          }
          if (label.get('label') === selectedLabel && !uncheck) {
            label.set('checked', true);
          }
        });
      }
    }
  });
  _exports.default = _default;
});