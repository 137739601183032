define("lbp-cust-web-application/services/browser-utils", ["exports", "ti-web-shared/services/browser-utils"], function (_exports, _browserUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _browserUtils.default;
    }
  });
});