define("lbp-cust-web-application/templates/components/a11y/a11y-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hY7L8QH/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"loading-spinner \",[27,\"if\",[[23,[\"icon\"]],\"icon-spinner\"],null]]]],[9],[0,\"\\n  \"],[1,[27,\"spin-spinner\",null,[[\"lines\",\"length\",\"width\",\"radius\",\"scale\",\"left\",\"top\"],[[23,[\"lines\"]],[23,[\"length\"]],[23,[\"width\"]],[23,[\"radius\"]],[23,[\"scale\"]],[23,[\"left\"]],[23,[\"top\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"labelKey\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"spacer-left sr-only\"],[9],[1,[27,\"t\",[[23,[\"labelKey\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/a11y/a11y-spinner.hbs"
    }
  });
  _exports.default = _default;
});