define("lbp-cust-web-application/components/ticust-web-module-interactions/conversation/lbp-conversation-header-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Layout
    tagName: '',
    //services
    intl: Ember.inject.service(),
    limitedLabels: Ember.computed.filter('conversation.labels', function (label, index) {
      return index < 1;
    }),
    init: function init() {
      this._super();
      if (this.get('conversation.subject')) {
        document.getElementById("dynamicTitle").innerText = this.get('intl').t('page.name', {
          pageName: this.get('intl').t('interactions.conversation.create.subject-label.info') + this.get('conversation.subject')
        });
      }
    }
  });
  _exports.default = _default;
});