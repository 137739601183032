define("lbp-cust-web-application/templates/components/ticust-web-module-interactions/conversation/lbp-conversation-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "C3eo6u9+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"enableBack\"]]],null,{\"statements\":[[4,\"a11y/a11y-action-div\",null,[[\"class\",\"label\",\"action\"],[\"lbp-panel-header-prev lbp-panel-header-prev-icon\",[27,\"t\",[\"interactions.conversation-header.buttons.back.aria-label\"],[[\"pageSource\"],[[23,[\"pageSource\"]]]]],[27,\"action\",[[22,0,[]],\"back\"],null]]],{\"statements\":[[0,\"    \"],[1,[27,\"a11y/a11y-semantic-icon\",null,[[\"label\",\"iconClass\"],[[27,\"t\",[\"interactions.conversation-header.buttons.back.label\"],null],\"icon-lbp-panel-header-prev\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[7,\"div\"],[11,\"class\",\"panel-title center flex-grow-header flex-center\"],[9],[0,\"\\n\\n  \"],[7,\"span\"],[11,\"role\",\"heading\"],[11,\"aria-level\",\"2\"],[11,\"tabindex\",\"0\"],[9],[1,[27,\"t\",[\"interactions.conversation-header.title\"],[[\"pageSource\"],[[23,[\"pageSource\"]]]]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"enableAnswer\"]]],null,{\"statements\":[[4,\"a11y/a11y-action-div\",null,[[\"class\",\"label\",\"action\"],[\"hidden-xs lbp-conversation-answer lbp-conversation-answer-block\",[27,\"t\",[\"interactions.conversation-header.buttons.answer.label\"],null],[27,\"action\",[[22,0,[]],\"answer\"],null]]],{\"statements\":[[0,\"    \"],[1,[27,\"a11y/a11y-semantic-icon\",null,[[\"label\",\"iconClass\"],[[27,\"t\",[\"interactions.conversation-header.buttons.answer.label\"],null],\"lbp-panel-header-prev-header-icon icon-lbp-conversation-answer\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ticust-web-module-interactions/conversation/lbp-conversation-header.hbs"
    }
  });
  _exports.default = _default;
});