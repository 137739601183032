define("lbp-cust-web-application/initializers/components/ticust-web-module-interactions/conversation-line-component", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation-line-component", "moment"], function (_exports, _conversationLineComponent, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-next-line max-len

  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/components/ticust-web-module-interactions/conversation-line-component',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _conversationLineComponent.default.reopen({
    //services
    intl: Ember.inject.service('intl'),
    accessibleDate: Ember.inject.service('accessible-date'),
    indexFirstNewElement: undefined,
    indexElement: undefined,
    didInsertElement: function didInsertElement() {
      if (this.indexElement && this.indexFirstNewElement && this.indexFirstNewElement === this.indexElement) {
        // delete the attribute tabindex from the other card
        var oldCard = document.querySelector('.card[tabindex="-1"]');
        if (oldCard) {
          oldCard.removeAttribute('tabindex');
        }

        //add tabindex to the new first card after seeMore action
        var cards = document.querySelectorAll('.card');
        var card = cards[this.get('indexFirstNewElement')];
        card.setAttribute('tabindex', '-1');
        card.focus();
      }
    },
    lastMessageReceivedDate: Ember.computed.alias('model.lastMessageReceivedDate'),
    lastMessageReceivedFormattedDate: Ember.computed('lastMessageReceivedDate', function () {
      var date = this.get('lastMessageReceivedDate');
      if (date === null) {
        throw new Error('Date unavailable'); // Should be impossible to reach...
      }

      if (date && (0, _moment.default)().isSame(date, 'day')) {
        return (0, _moment.default)(date).format('HH[h]mm');
      }
      var format = 'ddd DD/MM HH[h]mm';
      if (!(0, _moment.default)().isSame(date, 'year')) {
        format = 'ddd DD/MM/YY HH[h]mm';
      }
      var formattedDate = (0, _moment.default)(date).format(format);
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    }),
    expirationAccessibleDate: Ember.computed('expirationDate', function () {
      var date = (0, _moment.default)(this.get('expirationDate'));
      return this.get('accessibleDate').format(date, {
        format: 'D M Y',
        language: 'fr',
        military: true
      });
    }),
    isDraft: Ember.computed('model.messageStatuses', function () {
      return this.get('model.messageStatuses.DRAFT') > 0;
    }),
    expirationDate: Ember.computed.alias('model.expiringDate'),
    displayExpirationDate: Ember.computed.notEmpty('expirationDate'),
    //added part form the version C
    classNameBindings: ['isRead:conversation-line-component--read', 'isChecked:converastion-line-component--checked'],
    attributeBindings: ['role'],
    role: 'listitem',
    day: Ember.computed('model.lastMessageStatus', function () {
      if (this.get('model.lastMessageStatus') === 'DRAFT') {
        return this.get('model.lastUpdateDate') ? this.get('model.lastUpdateDate') : this.get('model.lastMessageReceivedDate');
      }
      return this.get('model.lastMessageReceivedDate');
    }),
    titleCheckBox: Ember.computed('intl.locale', 'model.nbMessages', 'isDraft', 'model.subject', 'lastMessageReceivedAccessibleDate', 'isChecked', 'displayExpirationDate', 'expirationAccessibleDate', 'limitedLabels', 'hasLabels', function () {
      var i18nCheck = 'interactions.conversation-line-component.buttons';
      var isRead = this.get('isRead') ? 'isRead' : 'isNotRead';
      var intl = this.get('intl');
      var label = this.get('limitedLabels')[0] ? this.get('limitedLabels.firstObject.title') : null;
      var labelPath = this.get('hasLabels') ? 'label' : 'no-label';
      var intlParameters = {
        subject: this.get('model.subject'),
        nbrMessages: this.get('model.nbMessages'),
        day: this.get('lastMessageReceivedAccessibleDate'),
        expiringDate: this.get('expirationAccessibleDate'),
        attachment: this.get('model.hasAttachments'),
        truncatedMessage: this.get('truncatedMessage'),
        label: label
      };
      if (this.get('displayExpirationDate')) {
        return intl.t("".concat(i18nCheck, ".").concat(labelPath, ".campaign.").concat(isRead), intlParameters);
      } else if (!this.get('isDraft')) {
        return intl.t("".concat(i18nCheck, ".").concat(labelPath, ".no-draft.").concat(isRead), intlParameters);
      } else if (this.get('model.nbMessages') === 1) {
        return intl.t("".concat(i18nCheck, ".").concat(labelPath, ".only-draft.").concat(isRead), intlParameters);
      } else {
        return intl.t("".concat(i18nCheck, ".").concat(labelPath, ".draft.").concat(isRead), intlParameters);
      }
    }),
    titleConversation: Ember.computed('intl.locale', 'model.nbMessages', 'isDraft', 'model.subject', 'lastMessageReceivedAccessibleDate', 'isChecked', 'displayExpirationDate', 'expirationAccessibleDate', 'limitedLabels', 'hasLabels', function () {
      var i18nCheck = 'interactions.conversation-line-component.title';
      var isRead = this.get('isRead') ? 'isRead' : 'isNotRead';
      var intl = this.get('intl');
      var label = this.get('limitedLabels')[0] ? this.get('limitedLabels.firstObject.title') : null;
      var labelPath = this.get('hasLabels') ? 'label' : 'no-label';
      var intlParameters = {
        subject: this.get('model.subject'),
        nbrMessages: this.get('model.nbMessages'),
        day: this.get('lastMessageReceivedAccessibleDate'),
        expiringDate: this.get('expirationAccessibleDate'),
        attachment: this.get('model.hasAttachments'),
        truncatedMessage: this.get('truncatedMessage'),
        label: label
      };
      if (this.get('displayExpirationDate')) {
        return intl.t("".concat(i18nCheck, ".").concat(labelPath, ".campaign.").concat(isRead), intlParameters);
      } else if (!this.get('isDraft')) {
        return intl.t("".concat(i18nCheck, ".").concat(labelPath, ".no-draft.").concat(isRead), intlParameters);
      } else if (this.get('model.nbMessages') === 1) {
        return intl.t("".concat(i18nCheck, ".").concat(labelPath, ".only-draft.").concat(isRead), intlParameters);
      } else {
        return intl.t("".concat(i18nCheck, ".").concat(labelPath, ".draft.").concat(isRead), intlParameters);
      }
    }),
    truncatedMessage: Ember.computed('model.lastMessageReceivedcontent', function () {
      // the value 60 has been seen with LBP
      return this.get('model.lastMessageReceivedcontent') ? this.get('model.lastMessageReceivedcontent').slice(0, 60) : '';
    }),
    lastMessageReceivedAccessibleDate: Ember.computed('day', function () {
      var date = (0, _moment.default)(this.get('day')).subtract(this.get('day').getTimezoneOffset(), 'm');
      var yearFormat = this.get('day').getFullYear() !== new Date().getFullYear() ? ' Y' : '';
      return this.get('accessibleDate').format(date, {
        format: "DD D M".concat(yearFormat, " \xE0 H heures MM"),
        language: 'fr',
        military: true
      });
    }),
    limitedLabels: Ember.computed.filter('model.labels', function (label, index) {
      return index < 1;
    })
  });
});