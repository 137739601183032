define("lbp-cust-web-application/controllers/app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    userIdle: Ember.inject.service(),
    tiLogger: Ember.inject.service('logger-service'),
    // Data
    isIdle: Ember.computed.readOnly('userIdle.isIdle'),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.get('userIdle').on('idleChanged', function (isIdle) {
        var session = _this.get('session');
        if (isIdle && session.get('isAuthenticated')) {
          _this.get('tiLogger').info('Inactivity logout');
          session.invalidate();
        }
      });
    }
  });
  _exports.default = _default;
});