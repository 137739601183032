define("lbp-cust-web-application/mixins/tabbable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TABBABLE_ELEMENTS = 'a[href], area[href], input:not([disabled]), select:not([disabled]), ' + 'textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], ' + '*[contenteditable]';
  var _default = Ember.Mixin.create({
    enableTabbable: true,
    keyDown: function keyDown(event) {
      if (this.get('enableTabbable') && event.keyCode === 9) {
        var component = Ember.$('#' + this.element.id);
        var current = component.find(':focus');
        var data = component.find(TABBABLE_ELEMENTS).filter(':visible');
        var nextIndex = event.shiftKey ? data.length - 1 : 0;
        if (current.length === 1) {
          var currentIndex = data.index(current);
          if (event.shiftKey) {
            if (currentIndex > 0) {
              nextIndex = currentIndex - 1;
            }
          } else {
            if (currentIndex + 1 < data.length) {
              nextIndex = currentIndex + 1;
            }
          }
        }
        var nextComponent = data.eq(nextIndex);
        nextComponent.focus();
        event.preventDefault();
        return false;
      }
      this._super(event);
    }
  });
  _exports.default = _default;
});