define("lbp-cust-web-application/templates/components/ti-web-shared/projects/project-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KEXyKpzA",
    "block": "{\"symbols\":[\"project\"],\"statements\":[[4,\"ti-web-shared/projects/project-element-list\",null,[[\"getElements\",\"onChangeStatus\",\"titleText\",\"titleIconClass\",\"pageSize\",\"showSort\",\"currentInterlocutor\",\"sharedWithMe\",\"selectedStatusFilter\",\"selectedTab\",\"isReloadProjects\",\"onDidReload\"],[[27,\"action\",[[22,0,[]],\"getProjects\"],null],[27,\"action\",[[22,0,[]],\"onChangeStatus\"],null],[23,[\"title\"]],\"icon-projects-list\",10,[23,[\"showSort\"]],[23,[\"currentInterlocutor\"]],[23,[\"sharedWithMe\"]],[23,[\"selectedStatusFilter\"]],[23,[\"selectedTab\"]],[23,[\"isReloadProjects\"]],[27,\"action\",[[22,0,[]],\"onDidReload\"],null]]],{\"statements\":[[4,\"ti-web-shared/projects/project-card-component\",null,[[\"model\",\"currentInterlocutor\",\"route\",\"openConversation\",\"openProject\"],[[22,1,[]],[23,[\"currentInterlocutor\"]],[23,[\"route\"]],[23,[\"openConversation\"]],[23,[\"openProject\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"project-information\"],[9],[0,\"\\n      \"],[1,[27,\"ti-web-shared/projects/project-informations\",null,[[\"model\",\"displayDetails\"],[[22,1,[]],false]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/projects/project-group.hbs"
    }
  });
  _exports.default = _default;
});