define("lbp-cust-web-application/components/ticust-web-module-interactions/conversation/conversation-search-form", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Input validation
  var Validations = (0, _emberCpValidations.buildValidations)({
    _searchText: (0, _emberCpValidations.validator)('presence', true)
  });
  var _default = Ember.Component.extend(Validations, {
    classNames: ['conversation-search'],
    tagService: Ember.inject.service('tags'),
    paginationHelper: Ember.inject.service('pagination-helper'),
    // Perimeters
    perimeters: ['all', 'conversationMine', 'conversationSent', 'conversationDraft', 'conversationTrashed'],
    // Services
    intl: Ember.inject.service('intl'),
    perimeterLabel: Ember.computed('intl.locale', '_searchPerimeter', function () {
      return this.get('intl').t('interactions.conversation-search-form.fields.perimeter.dropdown-label', {
        perimeter: this.get('intl').t('interactions.conversation-search-form.perimeters.'.concat(this.get('_searchPerimeter')))
      });
    }),
    // Data
    _searchText: Ember.computed.oneWay('searchText'),
    _searchPerimeter: undefined,
    searchTextFieldId: 'conversation-search-text',
    // eslint-disable-next-line
    _searchPerimeterObserver: Ember.on('init', Ember.observer('searchPerimeter', function () {
      if (this.get('searchPerimeter') && this.get('perimeters').includes(this.get('searchPerimeter'))) {
        this.set('_searchPerimeter', this.get('searchPerimeter'));
      } else {
        this.set('_searchPerimeter', 'all');
      }
    })),
    hasText: Ember.computed('_searchText', function () {
      return this.get('_searchText');
    }),
    showError: false,
    canSearch: Ember.computed('validations.isValid', 'showError', function () {
      return !this.get('showError') || this.get('validations.isValid');
    }),
    // Actions
    search: function search() {
      throw new Error("Missing 'search' handler");
    },
    actions: {
      onPerimeterChanged: function onPerimeterChanged(perimeter) {
        this.set('_searchPerimeter', perimeter);

        // iOS a11y fix
        document.getElementById('conversation-search-form_select-perimeter').focus();
      },
      clearText: function clearText() {
        this.set('_searchText', '');
        if (this.get('onClearText')) {
          this.get('onClearText')();
        }
      },
      search: function search() {
        this.get('tagService').sendClickTag({
          name: 'contacter::messagerie::recherche_bouton_rechercher',
          type: 'navigation'
        });
        if (!this.get('validations.isValid')) {
          this.set('showError', true);
          this.$("#".concat(this.get('searchTextFieldId'))).focus();
          return;
        }
        this.get('paginationHelper').setSearchValues(1, this.get('_searchPerimeter'), this.get('_searchText'));
        this.get('search')(this.get('_searchPerimeter'), this.get('_searchText'));
      }
    }
  });
  _exports.default = _default;
});