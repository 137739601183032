define("lbp-cust-web-application/services/lbp-service", ["exports", "lbp-cust-web-application/config/environment", "ember-uuid", "moment"], function (_exports, _environment, _emberUuid, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FLAG_INIT = 0;
  var FLAG_RUNNING = 1;
  var FLAG_STOPPED = -1;
  var STATUS_INIT = 'init';
  var STATUS_LOADED = 'loaded';
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    identity: Ember.inject.service('identity-service'),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    tiLogger: Ember.inject.service('logger-service'),
    // Service status : init, loaded
    status: STATUS_INIT,
    openConversationClick: false,
    //if back button to source page clicked
    returnToSourcePageClick: false,
    //When cancel button clicked from setting page
    settingCancelButtonClicked: false,
    //When cancel button clicked from inbenta page
    inbentaCancelButtonClicked: false,
    // User token from LBP
    lbptoken: undefined,
    // LBP context
    contextPromise: undefined,
    context: undefined,
    // LBP URI to call for session keepalive
    uriLbp: Ember.computed.alias('context.uriLbp'),
    // Base URL grabbed from parent frame referrerOrigin
    referrerOrigin: Ember.computed('context.referer', 'tmpReferrerOrigin', function () {
      if (this.get('context.referer')) {
        return this.get('context.referer');
      }
      return this.get('tmpReferrerOrigin');
    }),
    tmpReferrerOrigin: undefined,
    // Flag for keepalive. 0 = no started, 1 = running, -1 = stopped.
    keepAliveFlag: FLAG_INIT,
    // Handler for keepAlive Run.Later
    _keepaliveLater: undefined,
    // How often should keepalive be called ?
    _keepAliveTiming: _environment.default.keepAlive.timing || 0,
    keepAliveUrl: Ember.computed('uriLbp', 'referrerOrigin', function () {
      var url = this.get('referrerOrigin');
      if (!url.endsWith('/') && !this.get('uriLbp').startsWith('/')) {
        url += '/';
      } else if (url.endsWith('/') && this.get('uriLbp').startsWith('/')) {
        url = url.slice(0, -1);
      }
      url += this.get('uriLbp');
      return url;
    }),
    load: function load() {
      var _this = this;
      this.set('status', STATUS_LOADED);
      var otu = undefined;
      if (document.cookie.match(/OTU\=([^;]*)/) &&
      // eslint-disable-line
      document.cookie.match(/OTU\=([^;]*)/).length > 1) {
        // eslint-disable-line
        otu = document.cookie.match(/OTU\=([^;]*)/)[1]; // eslint-disable-line
      }

      this.get('tiLogger').info('otu = ' + otu);
      if (!this.get('tmpReferrerOrigin')) {
        // handle referrer
        var fullReferrer = document.referrer;
        var tempAnchor = document.createElement('a');
        tempAnchor.href = fullReferrer;
        var referrerOrigin = tempAnchor.origin;
        this.set('tmpReferrerOrigin', referrerOrigin);
      }
      if (otu) {
        // New OTU given, call WS for Context
        this.get('tiLogger').debug('Get LBP Context from OTU token');
        this.set('contextPromise', this.getLbpContext(otu));
      } else if (this.get('context') === undefined) {
        // Otherwise load previous context from local storage
        this.get('tiLogger').debug('Get LBP Context from local storage / cookie');
        this.set('contextPromise', new Promise(function (resolve, reject) {
          var savedContext = _this.get('session.data.context');
          if (savedContext) {
            resolve(savedContext);
          } else {
            reject();
          }
        }));
      }
      if (this.get('contextPromise')) {
        return this.get('contextPromise').then(function (context) {
          _this.set('context', context);
          if (_this.get('keepAliveFlag') === FLAG_INIT) {
            _this.startKeepalive();
          }
          return context;
        }).catch(function () {
          _this.get('tiLogger').warn('Failed getting context,' + ' keepalive will not be called');
        });
      }
    },
    getLbpContext: function getLbpContext() {
      var _this2 = this;
      // Call Context API to retrieve context data

      // Set session ID
      var sessionId = _emberUuid.default.v4();
      var time = (0, _moment.default)(new Date().getTime()).format('HHmmss');
      this.get('session').set('data.sessionId', sessionId + '-' + time);
      return this.get('ajax').raw('/api/customer/authentication/context', {
        method: 'GET',
        headers: this.get('identity.ajaxHeaders')
      }).then(function (response) {
        var context = response.response;
        // save JWT token and LBP context into local storage
        _this2.get('session').set('data.context', context);
        return _this2.get('session').authenticate('authenticator:lbp-token-auth', context.jetonLbp).then(function () {
          return context;
        }).catch(function (_ref) {
          var response = _ref.response,
            jqXHR = _ref.jqXHR,
            payload = _ref.payload;
          _this2.get('tiLogger').error('Failed LBP token Auth : HTTP ' + jqXHR.status + ' ' + response.message);
          // Chain catch
          var error = {
            response: response,
            jqXHR: jqXHR,
            payload: payload
          };
          throw error;
        });
      }).catch(function (_ref2) {
        var response = _ref2.response,
          jqXHR = _ref2.jqXHR,
          payload = _ref2.payload;
        _this2.get('tiLogger').error('Failed retrieving LBP Context : HTTP ' + jqXHR.status + ' ' + response.message);
        // Chain catch
        var error = {
          response: response,
          jqXHR: jqXHR,
          payload: payload
        };
        throw error;
      });
    },
    dispatch: function dispatch(route) {
      // depending on context, go to different parts of the app.
      var context = this.get('context');
      if (!context) {
        this.get('tiLogger').error('unknow context, go to root');
        route.transitionTo('app');
        return;
      }
      if (context.cible === 'WEBMAIL') {
        route.transitionTo('app.ticust-web-module-interactions');
      } else if (context.cible === 'PROJECTS') {
        route.transitionTo('app.ticust-web-module-projects');
      } else if (context.cible === 'NEW_CONVERSATION') {
        this.getInterlocutor().then(function (interlocutor) {
          if (context.themeCode) {
            route.transitionTo('app.ticust-web-module-interactions.interlocutors.interlocutor.conversations.create',
            // eslint-disable-line max-len
            interlocutor.get('id'), {
              queryParams: {
                theme: context.themeCode
              }
            });
          } else {
            route.transitionTo('app.ticust-web-module-interactions.interlocutors.interlocutor.conversations.create',
            // eslint-disable-line max-len
            interlocutor.get('id'));
          }
        });
      } else if (context.cible === 'NEW_CLAIM') {
        this.getInterlocutor().then(function (interlocutor) {
          route.transitionTo('app.ticust-web-module-interactions.interlocutors.interlocutor.conversations.claim', interlocutor.get('id'));
        });
      } else if (context.cible === 'LOGOUT') {
        this.get('session').invalidate('authenticator:lbp-token-auth');
        route.transitionTo('ticust-web-module-authentication.logout');
      } else {
        this.get('tiLogger').error('unknow cible ' + context.cible + ' go to root');
        route.transitionTo('app');
      }
    },
    /* Interlocutor Promise */getInterlocutor: function getInterlocutor() {
      // TODO C6 the context data should directly tell
      // for which interlocutor we should dispatch

      // For now we use the default / first one / only one ?
      // NOTE: accessing identity.userPromise.interlocutor at once will return undefined;
      return this.get('identity.userPromise').then(function (user) {
        return user.get('interlocutor');
      });
    },
    keepAliveLoop: function keepAliveLoop() {
      var _this3 = this;
      if (this.get('keepAliveFlag') == FLAG_RUNNING) {
        try {
          return this.get('ajax').raw(this.get('keepAliveUrl'), {
            method: 'GET',
            error: function error() {/* Drop keepAliveUrl Error*/}
          }).catch(function () {/* Drop keepAliveUrl Error*/});
        } catch (error) {
          /* Drop keepAliveUrl Error*/
        } finally {
          Ember.run.later(function () {
            _this3.keepAliveLoop();
          }, this.get('_keepAliveTiming') * 1000);
        }
      }
    },
    // To keep the user session alive for another 10 minutes each time the function is called
    keepAlive: function keepAlive() {
      if (this.get('keepAliveFlag') == FLAG_RUNNING) {
        try {
          return this.get('ajax').raw(this.get('keepAliveUrl'), {
            method: 'GET',
            error: function error() {/* Drop keepAliveUrl Error*/}
          }).catch(function () {/* Drop keepAliveUrl Error*/});
        } catch (error) {
          /* Drop keepAliveUrl Error*/
        }
      }
    },
    startKeepalive: function startKeepalive() {
      var _this4 = this;
      this.set('keepAliveFlag', FLAG_RUNNING);
      if (this.get('_keepAliveTiming') > 0) {
        this.get('tiLogger').info('Start calling keepAlive every ' + this.get('_keepAliveTiming') + 's : ' + this.get('keepAliveUrl'));
        this.set('_keepaliveLater', Ember.run.later(function () {
          _this4.keepAliveLoop();
        }, this.get('_keepAliveTiming') * 1000));
      } else {
        this.get('tiLogger').debug('KeepAlive disabled. URL: {}', this.get('keepAliveUrl'));
      }
    },
    // TODO EWLTILBPEE-42 to be called when session ends cleanly (manual logout or inactivity logout)
    stopKeepalive: function stopKeepalive() {
      this.set('keepAliveFlag', FLAG_STOPPED);
      Ember.run.cancel(this.get('_keepaliveLater'));
    },
    clearContext: function clearContext() {
      this.set('session.data.context', undefined);
    }
  });
  _exports.default = _default;
});