define("lbp-cust-web-application/initializers/engines/auth-engine", ["exports", "ticust-web-module-authentication/engine"], function (_exports, _engine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  var LBP_SERVICE = 'lbp-service';
  function initialize() {}
  var _default = {
    name: 'ticust-web-module-authentication/engine',
    // eslint-disable-line
    initialize: initialize
  };
  /**
    Initializer to reopen the Interaction engined and inject the tag service.
  */
  _exports.default = _default;
  _engine.default.reopen({
    init: function init() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      this._super(args);

      // Init arrays if necessary
      if (!this.dependencies.services) {
        this.dependencies.services = [];
      }
      if (!this.dependencies.externalRoutes) {
        this.dependencies.externalRoutes = [];
      }

      // Inject if necessary LBP services and externalRoutes
      if (this.dependencies.services.indexOf(LBP_SERVICE) === -1) {
        this.dependencies.services.push(LBP_SERVICE);
      }
    }
  });
});