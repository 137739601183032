define("lbp-cust-web-application/mixins/project-indicator", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    // Services
    identityService: Ember.inject.service(),
    lbpService: Ember.inject.service('lbp-service'),
    accessibleDate: Ember.inject.service('accessible-date'),
    isVisible: Ember.computed.alias('showIndicators'),
    _showChangeModule: false,
    hasActionOpenProject: Ember.computed.notEmpty('openProject'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalChangeModuleId', 'popup-change-module-' + _emberUuid.default.v4());
      this.set('modalChangeModuleTitleId', 'popup-change-module-' + _emberUuid.default.v4());
    },
    // Manage warning indicator
    _lastNotifiedDate: Ember.computed('project.lastCustomerNotifiedDate', 'project.lastAdviserNotifiedDate', 'project.lastNotifiedDate', function () {
      if (this.get('identityService').isActor()) {
        return this.get('project.lastNotifiedDate') || this.get('project.lastCustomerNotifiedDate');
      }
      return this.get('project.lastNotifiedDate') || this.get('project.lastAdviserNotifiedDate');
    }),
    _isWarned: Ember.computed('_lastNotifiedDate', 'project.isReadOnly', function () {
      return this.get('_lastNotifiedDate') && !this.get('project.isReadOnly');
    }),
    // Manage related message indicator
    conversation: Ember.computed.alias('project.conversation'),
    _hasConversation: Ember.computed('conversation', '_nbMessageInConversation', function () {
      return this.get('conversation') && !this.get('conversation.isStatusDeleted') && !this.get('conversation.isStatusRemovedFromTrash') && this.get('_nbMessageInConversation') > 0;
    }),
    _hasRoleForWebmail: Ember.computed.or('identityService.hasRoleConversationModuleAccess', 'identityService.hasRoleAccessToInteractions'),
    _displayRelatedMessage: Ember.computed.and('_hasConversation', '_hasRoleForWebmail'),
    _hasRoleDisplayRelatedMessage: Ember.computed.and('_hasRoleForWebmail'),
    _nbMessageInConversation: Ember.computed('conversation.messageStatuses', function () {
      if (this.get('conversation.messageStatuses')) {
        return Object.values(this.get('conversation.messageStatuses')).reduce(function (accumulator, currentValue) {
          return accumulator + currentValue;
        });
      }
      return 0;
    }),
    isOpen: Ember.computed.equal('project.status', 'IN_PROGRESS'),
    showIndicators: Ember.computed.and('isOpen', 'hasIndicators'),
    hasIndicators: Ember.computed.or('_hasRoleDisplayRelatedMessage', '_hasUnreadDocuments', '_isWarned', 'project.isNewProject', 'isShared'),
    _isUnread: Ember.computed('project.clientReaders.[]', function () {
      return this.get('project.clientReaders.length') === 0;
    }),
    _isLinked: Ember.computed.alias('_displayRelatedMessage'),
    actions: {
      openProject: function openProject() {
        this.get('openProject')(this.get('currentInterlocutor'), this.get('project'));
      },
      openLinked: function openLinked() {
        this.set('lbpService.openConversationClick', true);
        this.get('openConversation')(this.get('currentInterlocutor'), this.get('conversation'));
      },
      showChangeModule: function showChangeModule() {
        this.set('_showChangeModule', true);
      }
    }
  });
  _exports.default = _default;
});