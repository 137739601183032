define("lbp-cust-web-application/templates/components/ticust-web-module-interactions/conversation/simple-attachment-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2mRQMky+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"message-attachment-detail__block\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"message-attachment__detail exact-attachment-list ellipsis\"],[9],[0,\"\\n    \"],[1,[27,\"a11y/a11y-semantic-icon\",null,[[\"iconClass\"],[\"icon-object-pdf\"]]],false],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"ellipsis text-left message-attachment__name exact-attachment-list\"],[12,\"title\",[28,[[23,[\"attachment\",\"filename\"]]]]],[9],[1,[23,[\"attachment\",\"filename\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"attachment\",\"isUploaded\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"attachment-cancel\"],[9],[0,\"\\n      \"],[1,[27,\"a11y/a11y-action-icon\",null,[[\"action\",\"label\",\"title\",\"iconClass\"],[[23,[\"deleteAttachment\"]],[27,\"t\",[\"interactions.conversation.composer.attachments.buttons.delete.aria-label\"],[[\"file\"],[[23,[\"attachment\",\"filename\"]]]]],[27,\"t\",[\"interactions.conversation.composer.attachments.buttons.delete.title\"],null],\"icon-lbp-conversation-attachment-trash\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"ti-web-shared/a11y/icons/decorative-icon\",null,[[\"iconClass\"],[\"icon-spin\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ticust-web-module-interactions/conversation/simple-attachment-display.hbs"
    }
  });
  _exports.default = _default;
});