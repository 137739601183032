define("lbp-cust-web-application/components/ticust-web-module-interactions/conversation-action-bar-component", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation-action-bar-component"], function (_exports, _conversationActionBarComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _conversationActionBarComponent.default;
    }
  });
});