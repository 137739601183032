define("lbp-cust-web-application/templates/components/ti-web-shared/content-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bv+RekVc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"section\"],[11,\"id\",\"main-section\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/content-wrapper.hbs"
    }
  });
  _exports.default = _default;
});