define("lbp-cust-web-application/helpers/integer-to-index-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.integerToIndexArray = integerToIndexArray;
  function integerToIndexArray(params) {
    var array = [];
    for (var i = 1; i <= params[0]; i++) {
      array.push(i);
    }
    return array;
  }
  var _default = Ember.Helper.helper(integerToIndexArray);
  _exports.default = _default;
});