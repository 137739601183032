define("lbp-cust-web-application/templates/components/help-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "a6/wDzAe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\"],[12,\"class\",[28,[\"page-content-main \",[27,\"concat\",[[23,[\"moduleName\"]],\"-help\"],null]]]],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"aria-level\",\"2\"],[11,\"tabindex\",\"0\"],[9],[1,[27,\"t\",[[27,\"concat\",[[23,[\"moduleName\"]],\".help.title\"],null]],null],false],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"help-page-body\"],[9],[0,\"\\n    \"],[1,[27,\"ti-web-shared/sanitize-html\",null,[[\"raw\",\"value\",\"strict\",\"defaultOptions\"],[true,[23,[\"text\"]],false,[23,[\"defaultOptions\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/help-page.hbs"
    }
  });
  _exports.default = _default;
});