define("lbp-cust-web-application/initializers/components/ti-web-shared/a11y/focusable", ["exports", "ti-web-shared/components/ti-web-shared/a11y/focusable"], function (_exports, _focusable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ti-web-shared/components/ti-web-shared/a11y/focusable',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _focusable.default.reopen({
    setFocus: function setFocus() {
      if (!this.get('isEnabled')) {
        return;
      }
      var focusComponentSelector = document.querySelector(this.get('focusComponentSelector'));
      var focusElement = focusComponentSelector ? focusComponentSelector : this.element.querySelector(':first-child');
      if (focusElement == null) {
        // focus first input
        var allFocusables = document.getElementsByClassName('page-content-main')[0].querySelectorAll('h2, input, select, a, button:enabled, textarea');
        if (allFocusables.length > 1) {
          focusElement = allFocusables[0];
        } else {
          focusElement = document.getElementsByClassName('page-content-main')[0].querySelector(':first-child').children[0];
        }
      }
      focusElement.focus();
    }
  });
});