define("lbp-cust-web-application/initializers/controllers/ticust-web-module-interactions/interlocutors/interlocutor/conversations/drafts/draft", ["exports", "ticust-web-module-interactions/controllers/interlocutors/interlocutor/conversations/drafts/draft", "ember-cp-validations"], function (_exports, _draft, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.conversation.theme.content': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'themes.error.theme'
      })]
    },
    'model.conversation.subject': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'themes.error.subject'
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^\s*\S.*$/,
        messageKey: 'themes.error.subject'
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    }
  });
  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/controllers/interlocutors/interlocutor/conversations/drafts/draft',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _draft.default.reopen(Validations, {
    queue: Ember.inject.service(),
    queryParams: ['pageSource'],
    isCategorizeActionEnabled: false,
    isDirty: Ember.computed('composerIsDirty', 'conversation.hasDirtyAttributes', 'conversation.isDeleted', function () {
      return !this.get('conversation.isDeleted') && (this.get('composerIsDirty') || this.get('conversation.hasDirtyAttributes'));
    }),
    fieldsForTheme: Ember.computed('conversation.theme', 'conversation.theme.fields', function () {
      // Get additional fields related to the selected theme
      var fields = this.get('conversation.theme.fields');
      // Set selected options of additional fields
      var caracteristics = this.get('conversation.data.caracteristics');
      if (fields && caracteristics) {
        // if theme selected is not the theme of the conversatizon
        // reset additional fields selected options
        if (this.get('conversation.caracteristics.theme-key') != this.get('themeSelected.key')) {
          fields.forEach(function (item) {
            // eslint-disable-next-line ember/new-module-imports,ember/no-side-effects
            Ember.set(item, 'selectedOption', '');
          });
        } else {
          // otherwhise the theme selected is the theme of the conversation
          // set additional fields selected options with existing values
          fields.forEach(function (item) {
            var value = caracteristics['theme-' + item.name];
            // eslint-disable-next-line ember/new-module-imports,ember/no-side-effects
            Ember.set(item, 'selectedOption', '');
            if (item.options) {
              item.options.forEach(function (option) {
                if (option.value === value) {
                  // eslint-disable-next-line ember/new-module-imports,ember/no-side-effects
                  Ember.set(item, 'selectedOption', option);
                }
              });
            } else {
              // eslint-disable-next-line ember/no-side-effects,ember/new-module-imports
              Ember.set(item, 'selectedOption', '');
            }
          });
        }
        fields = fields.sort(function (f1, f2) {
          return f1.get('name').localeCompare(f2.get('name'));
        });
      }
      // this.set('conversation.theme.id', this.get('themeSelected.id'));
      return fields;
    }),
    enableBack: Ember.computed('pageSource', function () {
      return !!this.get('pageSource');
    }),
    useSelfService: Ember.computed('iaIsEnabled', function () {
      return this.get('iaIsEnabled') === 'true';
    }),
    init: function init() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      this._super(args);

      // Validation - add new attribute to showErrors
      this.set('showErrors.theme', false);
    },
    selfServiceValidateMisc: function selfServiceValidateMisc() {
      // Draft : remove the draft and Redirect to my conversations
      this.send('deleteConversation');
    },
    closeModal: function closeModal() {
      throw new Error("Missing 'closeModal' handler");
    },
    actions: {
      onBlacklistedModalClose: function onBlacklistedModalClose() {
        this.transitionToRoute('interlocutors.interlocutor.conversations.sources.source', this.get('interlocutorController.model'), 'conversationDraft');
      },
      sendMessage: function sendMessage(message) {
        var _this = this;
        var focusOnFirstError = function focusOnFirstError() {
          var fieldsForThemeErrorIndex = _this.get('fieldsForThemeErrorIndex');
          if (_this.get('displayThemeRequiredError') === true) {
            document.querySelector('#conversation-composer-create-select-theme').focus();
          } else if (fieldsForThemeErrorIndex !== -1) {
            var errorNodes = document.querySelectorAll('select.conversation-composer-create-fields-for-theme');
            errorNodes[fieldsForThemeErrorIndex].focus();
          } else if (_this.get('displaySubjectError') === true) {
            document.querySelector('#inputConversationSender').focus();
          }
        };

        // Validate the form
        var hasFieldsError = this._validateFields();
        if (this.get('messageIsEmpty')) {
          this.set('showErrors.message', true);
          this.get('_focusTextEditor')();
          return;
        } else if (!this.get('validations.isValid') || this.get('isCharacterLimitExceeded') || this.get('displaySubjectError')) {
          this.set('showErrors.theme', true);
          this.set('showErrors.subject', true);
          this.set('showErrors.message', true);
          focusOnFirstError();
          return;
        } else if (hasFieldsError) {
          focusOnFirstError();
          return;
        }
        if (this.get('identity.legalGuardianIdPph')) {
          message = this.get('identity').checkAndSetLegalGuardianSentence(message);
        }

        // Save parent send message as callback.
        var sendMessageCallback = function sendMessageCallback() {
          _this.send('updateConversationInRoute', message, 'SENT');
        };
        if (this.get('useSelfService')) {
          // Call self-service interceptor
          this.selfServiceMessageInterception(message, sendMessageCallback);
        } else {
          // Do the normal sendMessageCall - default case
          sendMessageCallback(message);
        }
      },
      // Save Draft
      saveDraftMessage: function saveDraftMessage(draftMessage) {
        var _this2 = this;
        var autoSaving = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        if (autoSaving && !this.get('isDirty')) {
          return;
        }
        // Validate the form
        if (!this.get('validations.isValid') || this.get('displaySubjectError')) {
          if (!autoSaving) {
            this.set('showErrors.subject', true);
            this.set('showErrors.theme', true);
            this.set('showErrors.message', true);
            window.toastr.options.onHidden = function () {
              var fieldsForThemeErrorIndex = _this2.get('fieldsForThemeErrorIndex');
              if (_this2.get('displayThemeRequiredError') === true) {
                document.querySelector('#conversation-composer-create-select-theme').focus();
              } else if (fieldsForThemeErrorIndex !== -1) {
                var errorNodes = document.querySelectorAll('select.conversation-composer-create-fields-for-theme');
                errorNodes[fieldsForThemeErrorIndex].focus();
              } else if (_this2.get('displaySubjectError') === true) {
                document.querySelector('#inputConversationSender').focus();
              }
            };
            this.showError('interactions.conversation.create.message-info.error.invalid-form');
            return;
          }
        }
        this.send('updateConversationInRoute', draftMessage, 'DRAFT', this.get('_transition'), autoSaving, this.get('displaySubjectError'));
      },
      deleteConversation: function deleteConversation() {
        var callback = this._super.bind(this);
        this.get('queue').push(callback, 'stop', 'message');
      },
      cancelMessage: function cancelMessage() {
        this.get('conversation').rollbackAttributes();
        this.send('cancelDraftInRoute', this.get('_transition'));
      },
      back: function back() {
        var interlocutor = this.get('interlocutorController.model');
        var pageSource = this.get('pageSource');
        this.transitionToRoute('interlocutors.interlocutor.conversations.sources.source', interlocutor, pageSource);
      },
      closeModal: function closeModal() {
        this.set("isMessageSent", true);
      },
      delete: function _delete() {
        var _this3 = this;
        if (confirm(this.get('intl').t('interactions.conversation.message-info.error.pop-up.delete'))) {
          this.set('isDeleting', true);
          this.get('conversation').destroyRecord().then(function () {
            _this3.showSuccess('interactions.conversation.draft.message-info.info.delete');
            _this3.transitionToRoute('interlocutors.interlocutor.conversations.sources.source', _this3.get('interlocutorController.model'), 'conversationMine');
          }).catch(function (error) {
            _this3.get('conversation').rollbackAttributes();
            _this3.showErrorAndLog('interactions.conversation.draft.message-info.error.delete', 'An error occured while deleting the conversation.', error);
          });
        }
      }
    }
  });
});