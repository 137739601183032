define("lbp-cust-web-application/controllers/interlocutors/interlocutor/conversations/inbenta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    isCurrentlyLoading: undefined,
    intl: Ember.inject.service(),
    lbpService: Ember.inject.service('lbp-service'),
    pageTitle: Ember.on('init', Ember.observer('model', function () {
      if (this.get('model')) {
        document.getElementById("dynamicTitle").innerText = this.get('intl').t('page.name', {
          pageName: this.get('intl').t('interactions.conversations.inbenta.title')
        });
      }
    })),
    actions: {
      backToList: function backToList() {
        this.transitionToRoute('app.ticust-web-module-interactions.interlocutors' + '.interlocutor.conversations.sources.source', this.get('model.interlocutor'), 'conversationMine');
        this.set('lbpService.inbentaCancelButtonClicked', true);
      },
      newMessage: function newMessage() {
        this.transitionToRoute('app.ticust-web-module-interactions.interlocutors.' + 'interlocutor.conversations.create', this.get('model.interlocutor'));
      },
      claim: function claim() {
        this.transitionToRoute('app.ticust-web-module-interactions.interlocutors' + '.interlocutor.conversations.claim');
      }
    }
  });
  _exports.default = _default;
});