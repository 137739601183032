define("lbp-cust-web-application/mixins/side-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    // Services
    identity: Ember.inject.service('identity-service'),
    tiLogger: Ember.inject.service('logger-service'),
    topBar: Ember.inject.service('topbar-service'),
    callingCodeMobile: Ember.computed('identity.userCallingCode', function () {
      return this.get('identity.userCallingCode') === '0004';
    }),
    actions: {
      routeToReturnUrl: function routeToReturnUrl() {
        this.get('tiLogger').debug('Going to ' + this.get('identity.userReturnUrl'));
        var url = this.get('identity.userReturnUrl');
        if (!/^https?:\/\//i.test(url)) {
          url = 'http://' + url;
        }
        window.location.replace(url);
      }
    }
  });
  _exports.default = _default;
});