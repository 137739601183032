define("lbp-cust-web-application/initializers/components/ticust-web-module-interactions/conversation/composer/conversation-composer-abstract", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/composer/conversation-composer-abstract", "lbp-cust-web-application/config/environment", "ticust-web-common/utils/part-utils"], function (_exports, _conversationComposerAbstract, _environment, _partUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function initialize() {}
  var _default = {
    name: 'components/ticust-web-module-interactions/conversation/composer/conversation-composer-abstract',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _conversationComposerAbstract.default.reopen({
    // Services
    lbpMessage: Ember.inject.service('lbp-message'),
    tagService: Ember.inject.service('tags'),
    config: Ember.inject.service(),
    isReadOnlyComposer: Ember.computed.and('isReadOnly', '_actionNotAutoSaving'),
    _actionNotAutoSaving: Ember.computed.not('_actionAutoSaving'),
    // Override LBP to manage the TO header
    _toHeader: Ember.computed('config.toHeader', function () {
      return [this.get('config.toHeader')];
    }),
    _messageContent: Ember.computed('_textPart', '_attachmentParts', '_fromHeader', '_toHeader', '_ccHeader', '_subjectHeader', 'interlocutor', '_attachments.[]', function () {
      var attachmentParts = this.get('_attachmentParts').map(function (attachmentPart) {
        return attachmentPart.copy();
      }).sort(_partUtils.default.compareByType);
      var textPart = this.get('_textPart').copy();
      var mainPart;
      if (!Ember.get(attachmentParts, 'length')) {
        mainPart = textPart;
      } else {
        mainPart = this.get('store').createFragment('multi-part', {
          headers: {
            'Content-Type': ['multipart/mixed']
          },
          bodies: [textPart].concat(_toConsumableArray(attachmentParts))
        });
      }
      mainPart.set('headers.From', this.get('_fromHeader'));
      mainPart.set('headers.To', this.get('_toHeader'));
      mainPart.set('headers.Cc', this.get('_ccHeader'));
      mainPart.set('headers.Subject', [this.get('_subjectHeader')]);
      return this.get('lbpMessage').addMessageHeaders(mainPart, this.get('interlocutor'), this.get('_attachments.length'));
    }),
    // Draft auto saving - Specific LBP
    _autoSavingEnable: _environment.default.draftAutoSaving.enable || false,
    _autoSavingTimer: _environment.default.draftAutoSaving.timer || 60000,
    _autoSavingProcess: undefined,
    _actionAutoSaving: false,
    _isTextTooLarge: Ember.computed('_textPlain', function () {
      return this.get('_textPlain.length') > 20000;
    }),
    _isMessageAndSubjectEmpty: Ember.computed('_textHtml', 'subject', function () {
      if (this.get('subject') && this.get('_textHtml')) {
        return this.get('subject').split(' ').join('').length === 0 || this.get('_textHtml').split(' ').join('') === '<p></p>';
      } else {
        return true;
      }
    }),
    cancelButtonDisabled: Ember.computed.or('_isTextTooLarge', 'isReadOnly'),
    sendButtonDisabled: Ember.computed.or('_isTextTooLarge', 'isReadOnly', '_isMessageAndSubjectEmpty'),
    saveButtonDisabled: Ember.computed.or('_isTextTooLarge', 'isReadOnly'),
    attachmentLabel: Ember.computed('hasAttachment', function () {
      return this.get('hasAttachment') ? 'interactions.conversation.composer.buttons.add-attachment.title2' : 'interactions.conversation.composer.buttons.add-attachment.title1';
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    _autoSavingObserver: Ember.on('init', Ember.observer('_autoSavingEnable', '_autoSavingTimer', function () {
      // Cancel the previous timeout process
      this._destroyAutoSavingTimeout();
      // Start a new one if needed
      if (this.get('_autoSavingEnable')) {
        this._autoSavingTimeout(this.get('_autoSavingTimer'));
      }
    })),
    _autoSavingTimeout: function _autoSavingTimeout(timer) {
      var _this = this;
      var process = Ember.run.later(this, function () {
        // Start promise to manage the auto saving
        _this._createAutoSavingPromise(function () {
          // Send save clicked action without showing errors
          _this.set('_actionAutoSaving', true);
          _this.send('saveClickedAutoSaving', true);
        }).then(function () {
          // Restart the process
          _this._autoSavingTimeout(timer);
        });
      }, timer);
      this.set('_autoSavingProcess', process);
    },
    _createAutoSavingPromise: function _createAutoSavingPromise(callback) {
      return new Ember.RSVP.Promise(function (resolve) {
        resolve(callback());
      });
    },
    _destroyAutoSavingTimeout: function _destroyAutoSavingTimeout() {
      if (this.get('_autoSavingProcess')) {
        // Cancel the auto saving process if exists
        Ember.run.cancel(this.get('_autoSavingProcess'));
        this.set('_autoSavingProcess', undefined);
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this._destroyAutoSavingTimeout();
    },
    _autoSavingChain: function _autoSavingChain(action) {
      this._destroyAutoSavingTimeout();
      action();
    },
    // -- End auto saving --

    messageContentObserver: Ember.on('init', Ember.observer('_messageContentTextHtml', '_messageContentAttachments', function () {
      if (!this.get('_actionAutoSaving')) {
        this.set('_textHtml', typeof this.get('_messageContentTextHtml') != 'undefined' ? this.get('_messageContentTextHtml') : '');
        this.set('_attachments', this.get('_messageContentAttachments'));
      }
      this.onComposerReset();
    })),
    hasAttachment: Ember.computed('_attachments.[]', function () {
      return this.get('_attachments').length > 0;
    }),
    // This computed function don't observe a property, it's normal it's to handle a IE bug
    getTextOnLoad: Ember.computed(function () {
      return this.get('_textHtml');
    }),
    splitFileName: function splitFileName(fileName) {
      return fileName.split('.').slice(0, -1).join('.');
    },
    findAttachment: function findAttachment(file) {
      return this.get('_attachments').find(function (attachment) {
        return (attachment.get('name') === file.name || attachment.get('filename') === file.name) && attachment.isUploaded !== true;
      });
    },
    _setTabIndexAndFocusOnUploadLink: function _setTabIndexAndFocusOnUploadLink() {
      var isFocusable = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var focus = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var uploadLink = document.querySelector('label.conversation-composer__upload-label span');
      if (uploadLink) {
        if (isFocusable) {
          uploadLink.setAttribute('tabindex', '0');
        } else {
          uploadLink.setAttribute('tabindex', '-1');
        }
        if (focus) {
          uploadLink.focus();
        }
      }
    },
    actions: {
      // Override to manage the auto saving parameter
      saveClickedAutoSaving: function saveClickedAutoSaving() {
        if (!this.get('_attachmentsUploadInProgress')) {
          this.saveMessage(this.get('_messageContent'), true);
        }
      },
      sendClicked: function sendClicked() {
        // Add LBP headers
        this.get('tagService').sendClickTag({
          name: 'contacter::messagerie::reception_bouton_envoyer',
          type: 'navigation'
        });
        this._autoSavingChain(this._super.bind(this));
      },
      cancelClicked: function cancelClicked() {
        this._autoSavingChain(this._super.bind(this));
      },
      saveClicked: function saveClicked() {
        this._autoSavingChain(this._super.bind(this));
      },
      textHtmlChanged: function textHtmlChanged(newValue) {
        // On first change, we persist the original Text
        if (this.get('_originalTextHtml') === null) {
          this.set('_originalTextHtml', this.get('_textHtml'));
        }
        // If _textHtml changed
        if (this.get('_textHtml') !== newValue) {
          this.onComposerChanged();
        }
        this.set('_textHtml', newValue);
        if (this.get('textHtmlChanged')) {
          this.get('textHtmlChanged')(newValue);
        }

        // If the _textHtml come back to original, we reset the dirty status
        // replace() is to fix a dirty issue
        if (this.get('_originalTextHtml').replace('<p></p>', '') === this.get('_textHtml').replace('<p></p>', '')) {
          this.onComposerReset();
        }
      },
      uploadAttachmentOnStartUpload: function uploadAttachmentOnStartUpload(file) {
        var _this2 = this;
        var attachment = this.get('_attachments').find(function (att) {
          return _this2.splitFileName(att.get('filename')) === _this2.splitFileName(file.name);
        });
        if (attachment) {
          this.showErrorAndLog('common.file-upload.error.sameName', 'Two attachments have the same name.');
          throw new Error(); // Uncaught but prevent uploading attachments that are already presents
        } else {
          this.get('_attachments').addObject(Ember.Object.create({
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModified,
            name: file.name,
            filename: file.name,
            size: file.size,
            type: file.type,
            webkitRelativePath: file.webkitRelativePath
          }));
        }
      },
      uploadAttachmentOnDidUpload: function uploadAttachmentOnDidUpload(file, event) {
        var _this3 = this;
        var attachment = this.findAttachment(file);
        if (!attachment) {
          return;
        }
        attachment.set('isUploaded', true);
        attachment.set('s3Id', event.contentRef);
        attachment.set('contentType', event.contentType);
        if (event.contentType === 'application/pdf') {
          // Correct encoding
          attachment.set('filename', file.name.substring(0, file.name.lastIndexOf('.')) + '.pdf');
        }
        window.toastr.options.onHidden = function () {
          _this3.get('_setTabIndexAndFocusOnUploadLink')(true, true);
        };
        this.showSuccess('interactions.conversation.composer.notifications.attachment-uploaded');
        var element = document.querySelector('.toast.toast-success');
        if (element) {
          element.setAttribute('tabindex', '-1');
          element.focus();
        }
      },
      uploadAttachmentDidError: function uploadAttachmentDidError(file, attachment) {
        this._super(file, attachment);
        var attachmentOnError = this.findAttachment(file);
        if (attachmentOnError) {
          this.get('_attachments').removeObject(attachmentOnError);
        }
        this.showErrorAndLog('common.file-upload.error.addFile', 'Failed to upload the attachment for the conversation.');
      }
    }
  });
});