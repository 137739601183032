define("lbp-cust-web-application/templates/components/ti-web-shared/spinner/spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wYwjK5It",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"lbp-spinner\"],[9],[0,\"\\n  \"],[1,[27,\"ti-web-shared/a11y/icons/decorative-icon\",null,[[\"iconClass\"],[\"icon-spin-page\"]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/spinner/spinner.hbs"
    }
  });
  _exports.default = _default;
});