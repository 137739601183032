define("lbp-cust-web-application/initializers/components/ti-web-shared/file-component", ["exports", "ti-web-shared/components/ti-web-shared/file-component", "ember-uuid"], function (_exports, _fileComponent, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ti-web-shared/components/ti-web-shared/file-component',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _fileComponent.default.reopen({
    // Services
    config: Ember.inject.service(),
    // given as parameter. based on canAddFiles
    hasRightsToDelete: true,
    showModalDeletion: false,
    _displayPopupPreview: false,
    _displayPreviewModal: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalDeletionId', 'popup-project-delete-file-' + _emberUuid.default.v4());
      this.set('modalDeletionTitleId', 'popup-project-delete-file-' + _emberUuid.default.v4());
      this.set('modalPreviewId', 'popup-project-preview-file-' + _emberUuid.default.v4());
      this.set('modalPreviewTitleId', 'popup-project-preview-file-' + _emberUuid.default.v4());
    },
    filename: Ember.computed('file.filename', function () {
      var filename = this.get('file.filename');
      if (filename && filename.lastIndexOf('.') > 0) {
        return filename.substring(0, filename.lastIndexOf('.')) + '.pdf';
      }
      return filename;
    }),
    canDelete: Ember.computed('project.status', 'hasRightsToDelete', 'projectPart.isReadOnly', 'document.isInterlocutorProvider', 'document.isStatusOK', function () {
      return this.get('project.status') === 'IN_PROGRESS' && this.get('hasRightsToDelete') && !this.get('projectPart.isReadOnly') && this.get('document.isInterlocutorProvider') && !this.get('document.isStatusOK');
    }),
    canDownload: Ember.computed('project.status', function () {
      return this.get('project.status') === 'IN_PROGRESS' || this.get('project.status') === 'CLOSED';
    }),
    mobileOrTabletMode: Ember.computed('config.screenMd', function () {
      return window.innerWidth < this.get('config.screenMd');
    }),
    // if files are not shown at all
    showIfRejected: true,
    author: Ember.computed('file.ownerActor', 'file.ownerInterlocutor', 'intl.locale', function () {
      var promise = null;
      if (this.get('file.ownerActor.id')) {
        return this.get('intl').t('projects.project.documents.file.attachments.from.adviser');
      } else if (this.get('file.ownerInterlocutor.id')) {
        promise = this.get('file.ownerInterlocutor');
      }
      if (promise) {
        return promise.then(function (author) {
          return author.get('display');
        });
      }
    }),
    actions: {
      popupPreview: function popupPreview() {
        if (this.get('mobileOrTabletMode')) {
          this.send('preview');
        } else {
          this.set('_previousFocus', document.activeElement);
          this.set('_displayPopupPreview', true);
        }
      },
      preview: function preview() {
        this.set('_displayPreviewModal', true);
      },
      toggleModalDeletion: function toggleModalDeletion() {
        this.toggleProperty('showModalDeletion');
      }
    }
  });
});