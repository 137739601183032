define("lbp-cust-web-application/components/ti-web-shared/multi-selectors/cc-multi-selector-item", ["exports", "ti-web-shared/components/ti-web-shared/multi-selectors/cc-multi-selector-item"], function (_exports, _ccMultiSelectorItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ccMultiSelectorItem.default;
    }
  });
});