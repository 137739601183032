define("lbp-cust-web-application/translations/en-gb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "administration.customers.attachments.types.application-docx": "DOCX",
    "administration.customers.attachments.types.application-ms-excel": "XLS",
    "administration.customers.attachments.types.application-ms-word": "DOC & DOT",
    "administration.customers.attachments.types.application-ppt": "PPT",
    "administration.customers.attachments.types.application-rtf": "RTF",
    "auth.login.accounts": "Bank accounts type|nature|number splitted by comma",
    "auth.login.callingCode": "Code Appelant",
    "auth.login.creditAccounts": "Credit accounts type|nature|number splitted by comma",
    "auth.login.financialCenter": "Financial center",
    "auth.login.phones": "Phones",
    "auth.login.returnUrl": "Redirect Url",
    "auth.login.typo": "Customer typology code",
    "auth.logout.text": "You have been correctly disconnected.",
    "auth.logout.title": "Success",
    "auth.sso-error.text": "Your session has expired or an error occured, please try to login on your online banking space.",
    "auth.sso-error.title": "An error occured",
    "authentication": {
      "error": {
        "AUTHENTICATION_FAILED": "The authentication failed",
        "BAD_CREDENTIALS": "Error: username or password invalid",
        "UNKNOWN": "An unknown error occurred. Please try again later"
      },
      "login": {
        "company": "Company",
        "forgot": "Lost password",
        "message-info": {
          "error": {
            "invalid-form": "The form is invalid, at least one field is missing.",
            "invalid-input": {
              "company": "The company is required.",
              "password": "The password is required.",
              "username": "The username is required."
            }
          }
        },
        "password": "Password",
        "title": "Login",
        "username": "Login",
        "valid": "Sign in"
      },
      "prospect": {
        "create": {
          "birthDate": "Birth date",
          "company": "Company",
          "email": "E-mail address",
          "firstName": "First name",
          "lastName": "Last name",
          "link": "Join now",
          "user": {
            "password": "Password",
            "title": "Create Password"
          }
        },
        "message": {
          "save": {
            "error": "An error occurred while saving the prospect. Please try again later.",
            "has-no-company": "This organisation doesn't exist",
            "info": "The prospect has been saved.",
            "user": "The user has been created.",
            "wrong-email": "This email is already in use. Please use the another one."
          },
          "validation": {
            "invalid-form": "The form is invalid, correct it and try again."
          }
        },
        "new": {
          "buttons": {
            "cancel": "Cancel",
            "confirm": "Confirm",
            "create": "Submit"
          }
        },
        "title": "New user"
      },
      "pwdreinit": {
        "cancel": "Cancel",
        "email": "Email",
        "header": "Please fill your email to receive an activation url to renew your password",
        "message-info": {
          "error": {
            "invalid-form": "The form is incorrect.",
            "invalid-input": {
              "email": "The email is incorrect."
            }
          },
          "info": {
            "success": "If an account exists for {email}, you will receive an email with a renewal link inside"
          }
        },
        "title": "Reseting password",
        "validate": "Submit"
      },
      "pwdrenew": {
        "email": "Email",
        "firstnewpassword": "Please type here your new password",
        "message-info": {
          "error": {
            "echec": "Your password has not been changed.",
            "invalid-form": "The form is incorrect, please check the fields.",
            "invalid-input": {
              "firstPassword": "The first password is missing.",
              "secondPassword": "The second password is missing."
            }
          },
          "info": {
            "success": "Your password has been changed."
          }
        },
        "password": "Password",
        "secondnewpassword": "Please confirm your new password",
        "title": "Change password",
        "validate": "Submit"
      }
    },
    "authentication.login.das": "Your DAS",
    "authentication.login.legal-guardian": "Identifier of legal guardian",
    "authentication.login.legal-guardian-date-take-office": "A date of take office by legal guardian",
    "authentication.login.username": "The customer to use",
    "common": {
      "application": {
        "title": "Trusted Interactions"
      },
      "file-upload": {
        "error": {
          "addFile": "The file has not been uploaded. Please try again later.",
          "contentType": "The type of attachment is not allowed.",
          "length": "The size of the attachment is greater than the maximum size allowed."
        }
      },
      "filter": {
        "datePrefix": "Date",
        "dateTimeLocale": "en",
        "hide": "Hide",
        "lessCriteria": "Show less criteria",
        "message-info": {
          "error": {
            "invalid-form": "The form is invalid, correct it and try again."
          }
        },
        "moreCriteria": "Show more criteria",
        "preset": "Preset:",
        "reset": "Reset criteria",
        "search": "Search",
        "show": "Show"
      },
      "img-avatar": {
        "avatar-alt": "{type, select, ADVISER {Adviser }  CLIENT {Interlocutor } other {}}{gender, select, MR {Mr }  MME {Ms } other {}}{lastname} {firstname}",
        "unknown-person": "Unknown person"
      },
      "message-info": {
        "error": {
          "403": "You are not allowed to see this page.",
          "unknown": "An error occurred while loading the page. Please try again later."
        }
      },
      "notification": {
        "CUST_RESET_PASSWORD": {
          "label": "Reset password",
          "variables": {
            "URL_LINK": "URL_LINK"
          }
        },
        "LOWER_CONFIDENTIALITY_ACCEPTED": {
          "label": "Accept to lower confidentiality",
          "variables": {
            "INTERLOCUTOR_CIVILITY": "INTERLOCUTOR_CIVILITY",
            "INTERLOCUTOR_FIRSTNAME": "INTERLOCUTOR_FIRSTNAME",
            "INTERLOCUTOR_LASTNAME": "INTERLOCUTOR_LASTNAME",
            "INTERLOCUTOR_NATURE": "INTERLOCUTOR_NATURE"
          }
        },
        "LOWER_CONFIDENTIALITY_REFUSED": {
          "label": "Refuse to lower confidentiality",
          "variables": {
            "INTERLOCUTOR_CIVILITY": "INTERLOCUTOR_CIVILITY",
            "INTERLOCUTOR_FIRSTNAME": "INTERLOCUTOR_FIRSTNAME",
            "INTERLOCUTOR_LASTNAME": "INTERLOCUTOR_LASTNAME",
            "INTERLOCUTOR_NATURE": "INTERLOCUTOR_NATURE"
          }
        },
        "NEW_MESSAGE_INTERLOCUTOR_RECEIVED": {
          "label": "New interlocutor message received",
          "variables": {
            "CONVERSATION_SUBJECT": "CONVERSATION_SUBJECT",
            "MESSAGE_EXTRACT": "MESSAGE_EXTRACT",
            "URL_LINK": "URL_LINK"
          }
        }
      },
      "page-error-message": "An unknown error occured. Please try again later or contact your service support",
      "pagination-component": {
        "currentPage": "Current page",
        "firstPage": "First page",
        "lastPage": "Last page",
        "nextPage": "Next page",
        "page": "Page",
        "previousPage": "Previous page"
      },
      "panel-collapse": {
        "close": "Close",
        "open": "Open",
        "title-default": "Error, title of panel is missing"
      },
      "result-list": {
        "action": {
          "show-grid": "Show in grid",
          "show-list": "Show in lines"
        },
        "results": {
          "error": "An error occurred while loading, please try again later.",
          "none": "No result available"
        },
        "sort": {
          "by": "Sort by"
        }
      },
      "selectors": {
        "actor-selector": {
          "error": "An error occurred during the search.",
          "loading": "Loading result(s)...",
          "no-results": "No recipient is matching this term",
          "required": "A recipient must be selected"
        },
        "theme-selector": {
          "error": "An error occurred during the search.",
          "loading": "Loading result(s)...",
          "no-results": "No theme is matching this term",
          "required": "A theme must be selected"
        }
      },
      "sidemenu": {
        "label": "Menu",
        "minimize": "Pull menu up",
        "pulldown": "Pull menu down",
        "search": "Search"
      },
      "spellchecker": {
        "languages": {
          "en": "English",
          "fr": "French"
        },
        "message-info": {
          "error": {
            "unknown": "An error occurred."
          }
        }
      },
      "variables": {
        "notificationCommon": {
          "EVENT_DATE": "EVENT_DATE",
          "RECIPIENT_CIVILITY": "RECIPIENT_CIVILITY",
          "RECIPIENT_FIRSTNAME": "RECIPIENT_FIRSTNAME",
          "RECIPIENT_LASTNAME": "RECIPIENT_LASTNAME"
        }
      }
    },
    "common-projects": {
      "closed": "Closed Project",
      "components": {
        "annex-area-button": {
          "openConversation": {
            "buttonLabel": "Go to conversation",
            "title": "Conversation"
          }
        },
        "audit-component": {
          "results": {
            "error": "An error has occurred while loading audit lines",
            "loading": "Loading, please wait..."
          },
          "table": {
            "no-result": "No results to show"
          }
        },
        "buttons": {
          "close": "Close"
        },
        "file-uploader-label": "Upload",
        "file-uploading": "Upload in progress ...",
        "header": "History",
        "project-nature-selector": {
          "error": "An error occurred during the search",
          "loading": "Loading result(s)...",
          "no-results": "No entity is matching this term",
          "required": "An entity must be selected"
        },
        "table": {
          "action": "Action",
          "date": "Date",
          "newValue": "New value",
          "no-result": "No result",
          "oldValue": "Old value",
          "user": "User"
        }
      },
      "document": {
        "audit": {
          "action": {
            "fileInvalid": "Marked as invalid",
            "fileValid": "Marked as valid",
            "init": "Initialization",
            "status": "Status changed",
            "uploaded": "Deposit"
          },
          "button": {
            "close": "Close"
          },
          "column": {
            "action": "Action",
            "actor": "Actor",
            "date": "Date",
            "detail": "Detail",
            "type": "Type"
          },
          "userType": {
            "ADVISER": "Adviser",
            "CLIENT": "Client"
          }
        },
        "button": {
          "add": "Add a file",
          "audit": "See history about this document",
          "cancel": "Cancel",
          "copy": "Copy-paste a file from an existing project",
          "create": {
            "copy": "Copy a file",
            "new": "Blank document",
            "upload": "Upload a file"
          },
          "download": "Download all files of the document",
          "edit": {
            "show": "Edit"
          },
          "hideButtons": "Hide action buttons for the document and its files",
          "info": {
            "hide": "Hide complementary information about this document",
            "show": "See more information about this document"
          },
          "markAsNotNeeded": "Already present",
          "markAsNotValid": "Mark the document as Invalid",
          "mergeFiles": "Merge files",
          "save": "Validate",
          "showButtons": "Show action buttons for the document and its files",
          "undoAsNotNeeded": "Undo already present",
          "upload": "Upload a new file from device"
        },
        "create": {
          "new": {
            "title": "New Document"
          },
          "title": "Add a new document"
        },
        "fields": {
          "description": "Description",
          "nature": "Nature",
          "providerType": "Provider",
          "signatureRequired": "Signature required",
          "title": "Name"
        },
        "message-info": {
          "error": {
            "duplicate-title": "The document title already exists.",
            "invalid-form": "The form is invalid, correct it and try again.",
            "markAsNotNeeded": "An error occurred while updating the document as Already Present, please try again later.",
            "markAsNotValid": "An error occurred while updating the file as Invalid, please try again later.",
            "markAsValid": "An error occurred while updating the file as Valid, please try again later.",
            "save": "An error occurred while saving the document, please try again later.",
            "undoNotNeeded": "An error occurred while reverting Already Present status, please try again later."
          },
          "info": {
            "markAsNotNeeded": "The document has been marked as Already Present.",
            "markAsNotValid": "The document has been marked as Invalid.",
            "markAsValid": "The document has been marked as Valid.",
            "onlyAdviserAllowed": "* Only the document with Adviser provider can be set as signature required",
            "save": "The document has been saved successfully.",
            "undoNotNeeded": "The document Already Present status reverted."
          }
        },
        "nature": {
          "ADVERTISEMENT": "Advertisement",
          "BANK_STATEMENT": "Bank statement",
          "BIRTH_CERTIFICATE": "Birth certificate",
          "COMPROMISE_SALE": "Compromise sale",
          "IBAN": "IBAN",
          "IDENTIFICATION": "Proof of identity",
          "INSURANCE": "Insurance",
          "LOAN_OFFER": "Loan offer",
          "PAYSLIP": "Payslip",
          "POLICE_RECORD": "Police record",
          "TAX_NOTICE": "Tax notice",
          "UNKNOWN": "Unknown",
          "label": "Nature:"
        },
        "providerType": {
          "ACTOR": "Adviser",
          "INTERLOCUTOR": "Customer",
          "UNKNOWN": "Unknown",
          "label": "Provider:"
        },
        "refusedReason": {
          "BLURRY": "The document is blurry.",
          "ERROR": "The document is off topic.",
          "ILLEGIBLE": "The document is illegible.",
          "label": "Reason to reject:"
        },
        "status": {
          "KO": "Invalid",
          "NOT_NEEDED": "Already present",
          "OK": "Valid",
          "TO_ACKNOWLEDGE": "To be validated",
          "TO_UPLOAD": "New",
          "UPLOADED": "Uploaded"
        },
        "statusMessage": {
          "KO": "This document has been flagged as Invalid by advisor. Please upload a new file.",
          "NOT_NEEDED": "This document is already present.",
          "TO_ACKNOWLEDGE": "This document is awaiting validation by an advisor.",
          "TO_UPLOAD": "This document has been created. Please upload the corresponding file."
        },
        "type": {
          "FREE": "Free",
          "STRUCTURED": "Structured",
          "UNKNOWN": "Unknown",
          "label": "Type of document:"
        }
      },
      "file": {
        "button": {
          "delete": "Delete this file",
          "download": "Download this file",
          "markAsNotValid": "Mark the file as Invalid",
          "markAsValid": "Mark the file as Valid",
          "preview": "Preview this file",
          "sign": "Sign this file"
        },
        "help": {
          "signature-required": "The file needs to be signed",
          "signed": "The file is already signed"
        },
        "message-info": {
          "error": {
            "delete": "An error occurred while deleting the file, please try again later.",
            "get-e-signiture-url": "An error occurred while getting the URL to sign the file, please try again later.",
            "mark-as-not-valid": "An error occurred while updating the file as Invalid, please try again later.",
            "mark-as-valid": "An error occurred while updating the file as Valid, please try again later."
          },
          "info": {
            "delete": "The file has been deleted.",
            "mark-as-not-valid": "The file has been marked as Invalid.",
            "mark-as-valid": "The file has been marked as Valid.",
            "pop-up": {
              "delete": "Do you want to delete this file?"
            }
          }
        },
        "status": {
          "KO": "Invalid",
          "OK": "Valid",
          "TO_SIGN": "To sign",
          "UPLOADED": "Uploaded"
        }
      },
      "file-upload": {
        "error": {
          "contentType": "This file type is not allowed."
        },
        "info": {
          "uploadSucess": "Your file has been uploaded."
        }
      },
      "folder": "Folder",
      "list": {
        "tabs": {
          "personal": "Personal",
          "shared": "Shared"
        },
        "title": "{interlocutorName}'s projects"
      },
      "modal-copy": {
        "buttons": {
          "cancel": "Cancel",
          "copy": "Copy"
        },
        "document": "Choose the document to copy to",
        "file": "Choose the file to copy",
        "label": {
          "search": "Search"
        },
        "message-info": {
          "error": {
            "post-400": "An error occurred while copying the file, please check the document status or that you don't have file with such name.",
            "unknown": "An error occurred while copying the file, please try again later."
          },
          "info": {
            "save": "{nb, plural, =1 {Copied file has been saved.} other {Copied files have been saved.}}",
            "saveAttachment": "Copied attachment has been saved.",
            "uploading": "File is uploading..."
          }
        }
      },
      "modal-merge": {
        "buttons": {
          "cancel": "Cancel",
          "confirm": "Confirm",
          "move-down": "Move Down",
          "move-up": "Move Up",
          "preview": "Preview"
        },
        "filename": "Name of target file: ",
        "header": "Merge files",
        "list": "List of files to merge: ",
        "message-info": {
          "error": {
            "generic": "An error occurred while merging the file, please try again later."
          },
          "info": {
            "info": "Some files might not be merged.",
            "loading": "File is being merged, please wait...",
            "saved": "File has been merged and saved."
          },
          "invalid-input": {
            "filename-duplicate": "Filename already exists in the document.",
            "filename-empty": "Filename can't be empty.",
            "filename-long": "Filename is too long.",
            "list-empty": "At least two files must be selected.",
            "list-too-large": "The weight of all the files is too large."
          }
        }
      },
      "modal-share": {
        "buttons": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "message-info": {
          "error": {
            "loading": "An error occurred while loading the trusted interlocutors, please try again later.",
            "save": "An error occurred while sharing the project, please try again later."
          },
          "info": {
            "empty-result": "You don't have any trusted interlocutors.",
            "save": "Sharing information has been saved."
          }
        },
        "placeholder": "Rights",
        "title": "Project rights"
      },
      "modal-upload": {
        "message-info": {
          "error": {
            "failed-due-bad-request": "An error occurred while uploading the file. please check the size or type of the file.",
            "failed-to-upload": "An error occurred while uploading the file. please try again later.",
            "post-400": "An error occurred while uploading the file, please check the document status or that you don't have file with such name.",
            "unknown": "An error occurred while uploading the file, please try again later.",
            "wrong-format": "The format of a file for which a signature is required can only be a PDF"
          },
          "info": {
            "file-uploading": "Upload in progress ...",
            "save": "Your file has been uploaded."
          }
        }
      },
      "page-error-message": "An unknown error occurred. Please try again later or contact your service support",
      "preview-component": {
        "buttons": {
          "close": "Close"
        },
        "error": "An error occurred while downloading the picture, please try again later.",
        "loading": "Loading; please wait...",
        "title": {
          "filedBy": "Filed by:",
          "filedOn": "Filed on:"
        }
      },
      "project": {
        "assignation": "This project is handled by: ",
        "audit": {
          "action": {
            "CREATE": "Project creation",
            "SHARED_INTERLOCUTOR_ADD": "Add interlocutor {interlocutorName} as {newProfileName}",
            "SHARED_INTERLOCUTOR_REMOVE": "Remove interlocutor {interlocutorName}",
            "SHARED_INTERLOCUTOR_UPDATE": "Update interlocutor {interlocutorName} from {previousProfileName} to {newProfileName}",
            "STATUS_CLOSED": "Project closed",
            "STATUS_REOPENED": "Project reopened",
            "UPDATE": "Project updated",
            "auditToAdviser": "Notification send to adviser {receiverName}",
            "auditToCustomer": "Notification send to customer {receiverName}"
          },
          "button": {
            "close": "Close"
          },
          "column": {
            "action": "Action",
            "date": "Date",
            "detail": "Detail",
            "sender": "Author"
          }
        },
        "button": {
          "audit": "See history about this project",
          "back": "Return to the list of projects",
          "close": "Close the project",
          "download": "Download all files of the project",
          "edit": "Edit the project",
          "hideInformations": "Hide informations",
          "notify-to-adviser": "Notify my Adviser that my project has been updated",
          "notify-to-customer": "Notify my Customer that his project has been updated",
          "open": "Open the project",
          "openConversation": "Open the linked conversation",
          "reopen": "Reopen the project",
          "share": "Manage project publication",
          "showInformations": "Show more informations"
        },
        "closedDate": "Closed on",
        "createdBy": "By",
        "creationDate": "Created on",
        "finishDate": "Expected completion on",
        "lastUpdateDate": "Modified on {date} by {name}",
        "message-error": {
          "download": "An error occurred while downloading the project, please try again later."
        },
        "message-info": {
          "error": {
            "LIMITED_CLICK_FOR_ADVISER": "You have already notified your customer less than {limit, plural, =1 {# hour} other {# hours}} ago.",
            "LIMITED_CLICK_FOR_CUSTOMER": "You have already notified your adviser less than {limit, plural, =1 {# hour} other {# hours}} ago.",
            "close": "An error occurred while updating the project as closed, please try again later.",
            "reopen": "An error occurred while updating the project as in-progress, please try again later.",
            "unknown": "An error occurred while sending notification, please try again later."
          },
          "info": {
            "close": "The project has been closed.",
            "notify-update": "The notification has been sent.",
            "pop-up": {
              "close": "Do you want to close this project?",
              "reopen": "Do you want to reopen this project?"
            },
            "reopen": "The project has been reopened."
          }
        },
        "new": "New",
        "reopenDate": "Reopened on",
        "shared": {
          "byMe": "This project is currently published to {to} with the profile: ",
          "not": "This project is currently not published to other clients.",
          "right": {
            "FULL_ACCESS": "Full access",
            "NO_ACCESS": "No access",
            "READ_ONLY": "Read only"
          },
          "toMe": "This project is currently published by {from} with the profile: "
        },
        "statistics": {
          "messages": {
            "KO": "{nb, plural, =1 {# document} other {# documents}} rejected",
            "OK": "{nb, plural, =1 {# document} other {# documents}} approved",
            "TO_ACKNOWLEDGE": "{nb, plural, =1 {# document} other {# documents}} to acknowledge",
            "UPLOADED": "{nb, plural, =1 {# document} other {# documents}} uploaded"
          }
        },
        "type": {
          "FREE": "Free",
          "STRUCTURED": "Structured"
        }
      },
      "project-card": {
        "invalid-documents": "{numDocs, plural, =1 {# document was} other {# documents were}} flagged as invalid.",
        "project-closed-alt": "Project closed",
        "project-shared-alt": "Project shared"
      },
      "project-element-list": {
        "sort": {
          "creationDate": {
            "asc": "The oldest",
            "desc": "The most recent"
          },
          "lastUpdateDate": {
            "asc": "The oldest update",
            "desc": "The most recent update"
          },
          "sortBy": "Sort by:"
        },
        "status-filters": {
          "all": "All",
          "all-help": "All projects",
          "closed": "Closed",
          "closed-help": "Closed projects",
          "opened": "Open",
          "opened-help": "Open projects"
        }
      },
      "project-group-title": {
        "button": {
          "close": "Close",
          "open": "Open"
        }
      },
      "project-part": {
        "close": "Close this part",
        "open": "Open this part"
      },
      "project-part-validator": {
        "error": {
          "duplicate-title": "The template folder name already exists for this project."
        }
      },
      "project-template-duplicate-validator": {
        "error": {
          "duplicate-title": "The project template label already exists."
        }
      },
      "project-template-part-document-validator": {
        "error": {
          "duplicate-title": "The document template name already exists for this folder."
        }
      },
      "selectors": {
        "document-nature-selector": {
          "error": "An error occurred during the search",
          "loading": "Now loading...",
          "no-results": "No results to show"
        },
        "project-nature-selector": {
          "error": "An error occurred during the search",
          "loading": "Now loading...",
          "no-results": "No results to show"
        },
        "project-part-document-file-selector": {
          "error": "An error occurred during the search",
          "loading": "Now loading...",
          "no-results": "No results to show"
        },
        "project-part-document-selector": {
          "error": "An error occurred during the search",
          "loading": "Now loading...",
          "no-results": "No results to show"
        }
      },
      "side-menu": {
        "new-project": "New Project",
        "projects": "Projects"
      },
      "title": "Project"
    },
    "common-projects.file.message-info.info.mark-as-not-valid": "The file has been deleted",
    "common-projects.modal-copy.message-info.info.save.plural.false": "Copied file has been saved.",
    "common-projects.modal-copy.message-info.info.save.plural.true": "Copied files have been saved.",
    "common-projects.preview-component.loading": "Loading",
    "common-projects.preview-component.previewTitle": "PDF file preview",
    "common-projects.project.message-info.info.notify-update-close": "Close",
    "common-projects.project.message-info.info.notify-update-text": "Documents have been received. <br> They will be process by your LBP advisor soon.",
    "common-projects.project.message-info.info.notify-update-title": "Acknowledgment that your documents were received",
    "common-projects.side-menu.closed-projects": "Closed projects",
    "common-projects.side-menu.current-projects": "In progress projects",
    "common-projects.side-menu.help.get.label": "Get help",
    "common-projects.side-menu.help.label": "Help",
    "common-projects.side-menu.projects.section.label": "Projects",
    "common.blacklisted-modal.buttons.close": "Close",
    "common.blacklisted-modal.footer": "*Service 0,15€/minute + price of a regular call",
    "common.blacklisted-modal.main-text": "To be able to use La Banque Postale's messaging service, please call the customer service at the following number: 36 39*.",
    "common.blacklisted-modal.title": "Service temporarily unavailable",
    "common.file-upload.error.sameName": "Impossible two attachments with same name",
    "common.form-fields-required-label": "All fields marked with an * are required.",
    "common.notification.HELP_INTERACTIONS.label": "Help page for interactions",
    "common.pagination.button": "See more",
    "common.pagination.button.label": "See more messages",
    "common.pagination.button.previous": "See previous",
    "common.pagination.button.previous.label": "See previous messages",
    "common.pagination.label": "pagination",
    "common.pagination.limit": "Please refine the search to have more results",
    "common.panel.panel-component.buttons.burger.label": "Open the menu",
    "common.simple-modal.buttons.back.label": "Back",
    "common.simple-modal.buttons.back.text": "Back",
    "common.simple-modal.buttons.back.title": "Back",
    "common.simple-modal.buttons.close.label": "Close",
    "common.simple-modal.buttons.close.text": "Close",
    "common.simple-modal.buttons.close.title": "Close",
    "common.simple-modal.buttons.delete.label": "Delete",
    "common.simple-modal.buttons.delete.text": "Delete",
    "common.simple-modal.buttons.delete.title": "Delete",
    "common.simple-modal.buttons.validate.label": "Validate",
    "common.simple-modal.buttons.validate.text": "Validate",
    "common.simple-modal.buttons.validate.title": "Validate",
    "errors": {
      "accepted": "{description} must be accepted",
      "after": "{description} must be after {after}",
      "before": "{description} must be before {before}",
      "blank": "{description} can't be blank",
      "collection": "{description} must be a collection",
      "confirmation": "{description} doesn't match {on}",
      "date": "{description} must be a valid date",
      "description": "This field",
      "email": "{description} must be a valid email address",
      "empty": "{description} can't be empty",
      "equalTo": "{description} must be equal to {is}",
      "even": "{description} must be even",
      "exclusion": "{description} is reserved",
      "file-empty": "File is not selected",
      "greaterThan": "{description} must be greater than {gt}",
      "greaterThanOrEqualTo": "{description} must be greater than or equal to {gte}",
      "inclusion": "{description} is not included in the list",
      "invalid": "{description} is invalid",
      "lessThan": "{description} must be less than {lt}",
      "lessThanOrEqualTo": "{description} must be less than or equal to {lte}",
      "notANumber": "{description} must be a number",
      "notAnInteger": "{description} must be an integer",
      "odd": "{description} must be odd",
      "otherThan": "{description} must be other than {value}",
      "phone": "{description} must be a valid phone number",
      "positive": "{description} must be positive",
      "present": "{description} must be blank",
      "singular": "{description} can't be a collection",
      "tooLong": "{description} is too long (maximum is {max} characters)",
      "tooShort": "{description} is too short (minimum is {min} characters)",
      "unsupported-file": "File type is not supported for signature required document",
      "url": "{description} must be a valid url",
      "wrongDateFormat": "{description} must be in the format of {format}",
      "wrongLength": "{description} is the wrong length (should be {is} characters)"
    },
    "errors.tooLong": "{description} is too long (maximum is {max} characters)",
    "errors.tooShort": "{description} is too short (minimum is {min} characters)",
    "icon.disabled": "Disabled",
    "interactions": {
      "conversation": {
        "action-bar": {
          "archive": "Archive the conversation",
          "categorize": "Classify the conversation",
          "delete": "Delete the conversation",
          "download": "Export the conversation",
          "expiring-date": "This conversation expires on:",
          "mark-as-read": "Mark the conversation as read",
          "mark-as-unread": "Mark the conversation as unread",
          "open-project": "Open project",
          "print": "Print the conversation",
          "removeFromTrash": "Permanently delete the conversation",
          "restore": "Restore the conversation",
          "sort-asc": "Sort the messages by date from oldest to newest",
          "sort-desc": "Sort the messages by date from newest to oldest",
          "unarchive": "Restore the conversation"
        },
        "cc": {
          "current": "This conversations is related to customer: {name}"
        },
        "composer": {
          "attachments": {
            "buttons": {
              "delete": {
                "title": "Delete the attachment"
              },
              "download": {
                "title": "Download the attachment"
              },
              "preview": {
                "title": "Preview the attachment"
              }
            },
            "upload-in-progress": "(in progress)"
          },
          "buttons": {
            "add-attachment": {
              "title": "Add a new attachment"
            },
            "cancel": {
              "title": "Cancel this edition",
              "value": "Cancel"
            },
            "save": {
              "title": "Save the message as draft",
              "value": "Save the draft"
            },
            "send": {
              "title": "Send the message",
              "value": "Send"
            }
          },
          "notifications": {
            "attachment-uploaded": "The attachment has been uploaded",
            "empty-file": "You can't upload empty files. Please try again with a valid file.",
            "file-name-already-used": "An attachment with the same name already exists.",
            "message-size-limit": "You have exceeded the max size of message attachments.",
            "upload-in-progress": "An attachment is being uploaded. Can not send the message"
          },
          "placeholder": "Write a message"
        },
        "create": {
          "cc": "Cc",
          "confidentiality": {
            "HIGH": "High",
            "LOW": "Regular",
            "label": "Confidentiality:"
          },
          "flash-answer": {
            "answer": {
              "no": "No",
              "yes": "Yes"
            },
            "indication": "If you answer \"yes\", your message will be \"archived\".",
            "question": "Does this information answer your question?"
          },
          "message-info": {
            "error": {
              "invalid-form": "The form is invalid, please check your input.",
              "pop-up": {
                "leaving-page": "Your changes may not be saved, do you want to leave this page?"
              },
              "recipient": "The recipient is required.",
              "save": {
                "conversation": "An error occurred while creating conversation. Please try again later.",
                "conversation-message": "An error occurred while creating message. Please try again later."
              },
              "subject": "The subject is required."
            },
            "info": {
              "save": "The conversation has been created."
            }
          },
          "subject": "Subject:",
          "theme": "Theme:",
          "title": "New conversation",
          "to": "To:"
        },
        "draft": {
          "button": {
            "delete": {
              "label": "Delete",
              "title": "Delete the draft conversation."
            }
          },
          "message-info": {
            "error": {
              "delete": "An error occurred while deleting the draft conversation. Please try again later.",
              "invalid-form": "The form is invalid, please check your input.",
              "save": {
                "conversation": "An error occurred while updating draft conversation. Please try again later.",
                "conversation-message": "An error occurred while updating draft message. Please try again later."
              }
            },
            "info": {
              "delete": "The conversation has been deleted.",
              "pop-up": {
                "delete": "Do you want to delete this conversation ?"
              },
              "save": "The draft has been updated."
            }
          },
          "title": "Edit a draft"
        },
        "errors": {
          "delete": "Failed to delete the draft. Please try again later",
          "save": "Failed to save the message. Please try again later",
          "send": "Failed to send the message. Please try again later"
        },
        "export": {
          "attachments": "With attachments",
          "button": {
            "cancel": "Cancel",
            "download": "Download"
          },
          "options": "Export options"
        },
        "header": {
          "has-attachments": "This conversation has attachments",
          "is-confidential": "This conversation is confidential",
          "message-number": "{nb, plural, =0 {no message} =1 {1 message} other {# messages}} ",
          "open-project": "This conversation is related to a personal project ",
          "status": {
            "CLOSED": "Archived",
            "DELETED": "Deleted",
            "INIT": "Draft",
            "IN_PROGRESS": "In progress",
            "TO_ARCHIVE": "Deleted definitively",
            "title": "Status"
          },
          "unread": "Unread"
        },
        "message-info": {
          "error": {
            "accept": "An error occurred while accepting the confidentiality downgrade. Please try again later.",
            "archive": "An error occurred while archiving the conversation. Please try again later.",
            "categorize": "An error occurred while categorizing the conversation. Please try again later.",
            "confidentiality-response": "An error occurred while answering the confidentiality asking. Please try again later.",
            "delete": "An error occurred while deleting the conversation. Please try again later.",
            "download": "An error occurred while downloading the conversation. Please try again later.",
            "markAsRead": "An error occurred while marking the conversation as read. Please try again later.",
            "markAsUnread": "An error occurred while marking the conversation as unread. Please try again later.",
            "pop-up": {
              "archive": "Do you want to archive this conversation ?",
              "delete": "Are you sure you want to delete this conversation ?",
              "leaving-page": "Your changes may not be saved, do you want to leave this page?"
            },
            "print": "An error occurred while printing the conversation. Please try again later.",
            "refuse": "An error occurred while refusing the confidentiality downgrade. Please try again later.",
            "removeFromTrash": "An error occurred while deleting definitively the conversation. Please try again later.",
            "restore": "An error occurred while restoring the conversation. Please try again later.",
            "unarchive": "An error occurred while restoring the conversation. Please try again later."
          },
          "info": {
            "accept": "The confidentiality downgrade has been accepted.",
            "archive": "The conversation has been archived.",
            "categorize": "The conversation has been categorized.",
            "delete": "The conversation has been deleted.",
            "markAsRead": "The conversation has been marked as read.",
            "markAsUnread": "The conversation has been marked as unread.",
            "pop-up-removeFromTrash": "Do you want to remove definitively this conversation?",
            "refuse": "The confidentiality downgrade has been rejected.",
            "removeFromTrash": "The conversation has been deleted definitively.",
            "restore": "The conversation has been restored.",
            "unarchive": "The conversation has been restored."
          }
        },
        "showMore": {
          "button": "Show more messages",
          "message-info": {
            "error": {
              "showMoreMessages": "An error occurred while loading the messages. Please try again later."
            },
            "info": {
              "showMoreMessages": "Older messages have been downloaded."
            }
          }
        },
        "success": {
          "delete": "The draft has been deleted",
          "save": "The draft has been saved",
          "send": "The message has been sent"
        },
        "templates": {
          "confidentiality": {
            "accept": "<p>Hello,</p><p>The customer has accepted to downgrade the confidentiality level of the conversation.</p><p>Best regards,</p>",
            "refuse": "<p>Hello,</p><p>The customer has refused to downgrade the confidentiality level of the conversation.</p><p>Best regards,</p>"
          }
        }
      },
      "conversation-action-bar-component": {
        "actions": {
          "archive": {
            "display": "Archive",
            "title": "Archive all checked conversations"
          },
          "categorize": {
            "display": "Classify in ...",
            "labelSelected": "Classify all checked conversations in {label}",
            "title": "Classify all checked conversations in ...",
            "toggle": "Open the menu 'Classify in ...'"
          },
          "checkAll": "Select all checked conversations",
          "delete": {
            "display": "Delete",
            "title": "Delete all checked conversations"
          },
          "markAs": {
            "display": "Mark as ...",
            "markAsRead": {
              "display": "Mark as read",
              "title": "Mark all checked conversations as read"
            },
            "markAsUnread": {
              "display": "Mark as unread",
              "title": "Mark all checked conversations as unread"
            },
            "toggle": "Open the menu 'Mark as ...'"
          },
          "removeFromTrash": {
            "display": "Remove from trash",
            "title": "Remove all checked conversations from trash"
          },
          "restore": {
            "display": "Restore",
            "title": "Restore all checked conversations"
          },
          "unarchive": {
            "display": "Restore",
            "title": "Restore all checked conversations"
          },
          "uncheckAll": "Unselect all checked conversations"
        },
        "search": {
          "button": "Filter conversation with the text",
          "input": "Text to search"
        },
        "sort": {
          "sortBy": "Sort by:"
        }
      },
      "conversation-line-component": {
        "buttons": {
          "check": "Check the conversation.",
          "uncheck": "Uncheck the conversation."
        },
        "confidential": "The conversation is confidential.",
        "conversationSubject": "Conversation subject.",
        "hasAttachments": "The conversation has at least one attachment",
        "isRead": {
          "read": "The last conversation message is read.",
          "unread": "The last conversation message is not read."
        },
        "lastMessageDisplayName": "Adviser name attached to the last message.",
        "lastMessageReceivedDate": "Last message date.",
        "lastMessageReceivedcontent": "Last message content.",
        "lastMessageStatus": {
          "DRAFT": "The last conversation message is a draft.",
          "RECEIVED": "The last conversation message comes from your adviser.",
          "SENT": "The last conversation message comes from you."
        }
      },
      "conversation-message": {
        "body": {
          "buttons": {
            "show-less": {
              "text": "--- display the partial message ---",
              "title": "Display the partial message"
            },
            "show-more": {
              "text": "--- display the full message ---",
              "title": "Display the full message"
            }
          }
        },
        "header": {
          "expand-button": {
            "label-expand": "Expand header",
            "label-shrink": "Shrink header"
          },
          "expanded": {
            "cc": "Cc:",
            "cc-type": {
              "actors": "(Adviser)",
              "externals": "(External)",
              "interlocutors": "(Customer)"
            },
            "channel": "Channel:",
            "channels": {
              "MAIL": "Mail"
            },
            "from": "From:",
            "receiveDate": "Received on:",
            "sendDate": "Sent at:",
            "subject": "Subject:",
            "tags": "Tags:",
            "to": "To:",
            "virus-scan": "Virus scan:"
          },
          "shrunken": {
            "cc": "Cc:",
            "from": "From:",
            "to": "To:"
          },
          "title": "Message Information"
        }
      },
      "conversation-message-attachment": {
        "attachment": "Attachments",
        "download": "Download the file",
        "dropdown-button-title": "Actions",
        "preview": "Preview the file"
      },
      "conversation-message-confidentiality": {
        "accept": "You accepted to downgrade the confidentiality level at {date}",
        "asking": "Downgrade the confidentiality level of this conversation:",
        "buttons": {
          "accept": "Accept",
          "refuse": "Refuse"
        },
        "refuse": "You refused to downgrade the confidentiality level at {date}"
      },
      "conversations": {
        "search": {
          "list": {
            "title": "Conversations"
          },
          "message-info": {
            "error": {
              "archive": "An error occurred while archiving. Please try again later.",
              "categorize": "An error occurred while categorizing. Please try again later.",
              "delete": "An error occurred while deleting. Please try again later.",
              "markAsRead": "An error occurred while marking as read. Please try again later.",
              "markAsUnread": "An error occurred while marking as unread. Please try again later.",
              "removeFromTrash": "An error occurred while deleting definitively. Please try again later.",
              "restore": "An error occurred while restoring. Please try again later.",
              "unarchive": "An error occurred while restoring. Please try again later."
            },
            "info": {
              "archive": "Archiving has been done.",
              "categorize": "Categorizing has been done.",
              "delete": "Removal has been done.",
              "markAsRead": "Marking as read has been done.",
              "markAsUnread": "Marking as unread has been done.",
              "pop-up-removeFromTrash": "Do you want to remove definitively?",
              "removeFromTrash": "Removal definitively has been done.",
              "restore": "Restoration has been done.",
              "unarchive": "Restoration has been done."
            }
          }
        },
        "sort": {
          "lastMessageReceivedDate": {
            "asc": "The oldest",
            "desc": "The most recent"
          }
        }
      },
      "forbidden-interlocutor": "The access to this user is not allowed",
      "label": {
        "categorize-component": {
          "buttons": {
            "close": "Close",
            "manage": "Manage my personal folders",
            "save": "Save"
          },
          "header": {
            "text": "My personal folders",
            "title": "My personal folders"
          },
          "loading": "Loading, please wait...",
          "no-data": "No personal folder to display"
        },
        "popup-edit-component": {
          "buttons": {
            "close": "Cancel",
            "save": "Save"
          },
          "fields": {
            "color": {
              "label-color-1": "Color 1",
              "label-color-2": "Color 2",
              "label-color-3": "Color 3",
              "label-color-4": "Color 4",
              "label-color-5": "Color 5",
              "text": "Color:",
              "title": "Color"
            },
            "description": {
              "text": "Description:",
              "title": "Description"
            },
            "name": {
              "text": "Name:",
              "title": "Name"
            }
          },
          "header": {
            "edit": {
              "text": "Personal folder: [{name}]",
              "title": "Personal folder"
            },
            "new": {
              "text": "New personal folder",
              "title": "New personal folder"
            }
          },
          "message-info": {
            "error": {
              "duplication": "The name is already used for another personal folder.",
              "invalid-form": "The form is invalid, please check your input."
            }
          }
        },
        "settings": {
          "buttons": {
            "add": {
              "label": "Add",
              "title": "Add"
            },
            "delete": {
              "label": "Delete",
              "title": "Delete"
            },
            "edit": {
              "label": "Edit",
              "title": "Edit"
            }
          },
          "fields": {
            "defaultText": {
              "text": "Aa",
              "title": "Label example"
            },
            "description": {
              "title": "Description"
            },
            "name": {
              "title": "Name"
            }
          },
          "message-info": {
            "error": {
              "create": "An error occurred, the personal folder hasn't been created. Please retry later.",
              "delete": "An error occurred, the personal folder hasn't been removed. Please retry later.",
              "edit": "An error occurred, the personal folder hasn't been updated. Please retry later.",
              "get-404": "An error occurred, the label was not found. Please retry later.",
              "unknow": "An error occurred on the page. Please retry later."
            },
            "info": {
              "create": "The personal folder has been created.",
              "delete": "The personal folder has been deleted.",
              "deleteConfirm": "Do you want to delete the personal folder [{name}]?",
              "edit": "The personal folder has been updated."
            }
          },
          "search": {
            "results": {
              "title": "Personal folders"
            }
          }
        }
      },
      "message-attachment": {
        "message-info": {
          "error": {
            "download": "An error occurred while downloading the attachment, please try again later."
          }
        }
      },
      "message-composer-attachments": {
        "multiple-upload": {
          "error": "{nbUploadsFailed, plural, one {# attachment} other {# attachments}} could not be uploaded.",
          "success": "{nbUploadsPassed, plural, one {The attachment has} other {# attachments have}} been uploaded."
        }
      },
      "preview-component": {
        "buttons": {
          "close": "Back"
        },
        "error": "An error occurred while downloading the picture, please try again later.",
        "loading": "Loading, please wait...",
        "title": {
          "uploadDate": "Uploaded on:",
          "uploader": "Uploaded by:"
        }
      },
      "side-menu": {
        "conversations": {
          "label": "Conversations",
          "lines": {
            "archived": "Archived",
            "draft": "Drafts",
            "mine": "My Conversations",
            "sent": "Sent",
            "trashed": "Trash"
          },
          "sr-only": "conversations"
        },
        "labels": {
          "configurationButton": "Configuration",
          "label": "Personal folders"
        },
        "new-conversation": "New Conversation"
      }
    },
    "interactions.conversation-action-bar-component.actions.cancel": "Cancel",
    "interactions.conversation-action-bar-component.actions.selectAll": "Select all conversations",
    "interactions.conversation-action-bar-component.actions.unselectAll": "Unselect all conversations",
    "interactions.conversation-header.buttons.actions.label": "Menu of actions",
    "interactions.conversation-header.buttons.answer.label": "Answer",
    "interactions.conversation-header.buttons.back.aria-label": "{pageSource, select, conversationMine {Back to messages received} conversationSent {Back to messages sent} conversationDraft {Back to drafts} conversationTrashed {Back to messages trashed} search {Back to search} other {Back to messages received} }",
    "interactions.conversation-header.buttons.back.label": "Back",
    "interactions.conversation-header.buttons.history.label": "Display history",
    "interactions.conversation-header.title": "{pageSource, select, conversationMine {Messages received} conversationSent {Messages sent} conversationDraft {Drafts} conversationTrashed {Messages trashed} search {Search} other {Messages received} }",
    "interactions.conversation-line-component.buttons.label.campaign.isNotRead": "{attachment, select, true {Check the not read campaign message dating from {day} of {label} with at least one attachment of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} false {Check the not read campaign message dating from {day} of {label} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} other {Check the not read campaign message dating from {day} of {label} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.label.campaign.isRead": "{attachment, select, true {Check the read campaign message dating from {day} of {label} with at least one attachment of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} false {Check the read campaign message dating from {day} of {label} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} other {Check the read campaign message dating from {day} of {label} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.label.draft.isNotRead": "{attachment, select, true {Check the not read conversation dating from {day} of {label} with at least one attachment of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Check the not read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Check the not read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.label.draft.isRead": "{attachment, select, true {Check the read conversation dating from {day} of {label} with at least one attachment of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Check the read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Check the read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.label.no-draft.isNotRead": "{attachment, select, true {Check the not read conversation dating from {day} of {label} with at least one attachment of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Check the not read conversation dating from {day} of {label} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Check the not read conversation dating from {day} of {label} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.label.no-draft.isRead": "{attachment, select, true {Check the read conversation dating from {day} of {label} with at least one attachment of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Check the read conversation dating from {day} of {label} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Check the read conversation dating from {day} of {label} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.label.only-draft.isNotRead": "{attachment, select, true {Check the not read conversation dating from {day} of {label} with at least one attachment of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} false {Check the not read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} other {Check the not read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.label.only-draft.isRead": "{attachment, select, true {Check the read conversation dating from {day} of {label} with at least one attachment of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} false {Check the read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} other {Check the read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.no-label.campaign.isNotRead": "{attachment, select, true {Check the not read campaign message dating from {day} with at least one attachment of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} false {Check the not read campaign message dating from {day} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} other {Check the not read campaign message dating from {day} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.no-label.campaign.isRead": "{attachment, select, true {Check the read campaign message dating from {day} with at least one attachment of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} false {Check the read campaign message dating from {day} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} other {Check the read campaign message dating from {day} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.no-label.draft.isNotRead": "{attachment, select, true {Check the not read conversation dating from {day} with at least one attachment of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Check the not read conversation dating from {day} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Check the not read conversation dating from {day} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.no-label.draft.isRead": "{attachment, select, true {Check the read conversation dating from {day} with at least one attachment of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Check the read conversation dating from {day} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Check the read conversation dating from {day} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.no-label.no-draft.isNotRead": "{attachment, select, true {Check the not read conversation dating from {day} with at least one attachment of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Check the not read conversation dating from {day} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Check the not read conversation dating from {day} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.no-label.no-draft.isRead": "{attachment, select, true {Check the read conversation dating from {day} with at least one attachment of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Check the read conversation dating from {day} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Check the read conversation dating from {day} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.no-label.only-draft.isNotRead": "{attachment, select, true {Check the not read conversation dating from {day} with at least one attachment of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} false {Check the not read conversation dating from {day} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} other {Check the not read conversation dating from {day} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.no-label.only-draft.isRead": "{attachment, select, true {Check the read conversation dating from {day} with at least one attachment of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} false {Check the read conversation dating from {day} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} other {Check the read conversation dating from {day} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.draft.label": "Draft",
    "interactions.conversation-line-component.expiring-date": "Conversation expiration date.",
    "interactions.conversation-line-component.header.actions.cancel.label": "Cancel",
    "interactions.conversation-line-component.header.actions.cancel.title": "Cancel all actions effectuated",
    "interactions.conversation-line-component.header.actions.delete-draft.label": "Delete",
    "interactions.conversation-line-component.header.actions.delete-draft.title": "Delete the draft",
    "interactions.conversation-line-component.header.actions.delete-draft.titleSelect": "Delete {nb, plural, =1 {the draft} other {drafts}}",
    "interactions.conversation-line-component.header.actions.delete-full.label": "Delete permanently",
    "interactions.conversation-line-component.header.actions.delete-full.title": "Delete permanently the conversation",
    "interactions.conversation-line-component.header.actions.delete-full.titleSelect": "Delete permanently {nb, plural, =1 {the conversation} other {conversations}}",
    "interactions.conversation-line-component.header.actions.delete.label": "Delete",
    "interactions.conversation-line-component.header.actions.delete.title": "Delete, move the conversation to the trash",
    "interactions.conversation-line-component.header.actions.delete.titleSelect": "Delete (Move {nb, plural, =1 {the conversation} other {conversations}} to the trash)",
    "interactions.conversation-line-component.header.actions.link-to.label": "Link to",
    "interactions.conversation-line-component.header.actions.link-to.title": "Link to the conversation",
    "interactions.conversation-line-component.header.actions.restore.label": "Restore",
    "interactions.conversation-line-component.header.actions.restore.title": "Restore the conversation",
    "interactions.conversation-line-component.header.actions.restore.titleSelect": "Restore {nb, plural, =1 {the conversation} other {conversations}}",
    "interactions.conversation-line-component.header.number-checked": "{nb, plural, =0 {Select all} =1 {# seleted conversation} other {# selected conversations}}",
    "interactions.conversation-line-component.isDraft.label": "The conversation has a draft",
    "interactions.conversation-line-component.isRead.unread": "One conversation message is not read.",
    "interactions.conversation-line-component.messages.label": "messages",
    "interactions.conversation-line-component.title.label.campaign.isNotRead": "{attachment, select, true {Open the not read campaign message dating from {day} of {label} with at least one attachment of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} false {Open the not read campaign message dating from {day} of {label} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} other {Open the not read campaign message dating from {day} of {label} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.label.campaign.isRead": "{attachment, select, true {Open the read campaign message dating from {day} of {label} with at least one attachment of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} false {Open the read campaign message dating from {day} of {label} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} other {Open the read campaign message dating from {day} of {label} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.label.draft.isNotRead": "{attachment, select, true {Open the not read conversation dating from {day} of {label} with at least one attachment of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Open the not read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Open the not read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.label.draft.isRead": "{attachment, select, true {Open the read conversation dating from {day} of {label} with at least one attachment of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Open the read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Open the read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.label.no-draft.isNotRead": "{attachment, select, true {Open the not read conversation dating from {day} of {label} with at least one attachment of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Open the not read conversation dating from {day} of {label} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Open the not read conversation dating from {day} of {label} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.label.no-draft.isRead": "{attachment, select, true {Open the read conversation dating from {day} of {label} with at least one attachment of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Open the read conversation dating from {day} of {label} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Open the read conversation dating from {day} of {label} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.label.only-draft.isNotRead": "{attachment, select, true {Open the not read conversation dating from {day} of {label} with at least one attachment of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} false {Open the not read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} other {Open the not read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.label.only-draft.isRead": "{attachment, select, true {Open the read conversation dating from {day} of {label} with at least one attachment of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} false {Open the read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} other {Open the read conversation dating from {day} of {label} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.no-label.campaign.isNotRead": "{attachment, select, true {Open the not read campaign message dating from {day} with at least one attachment of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} false {Open the not read campaign message dating from {day} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} other {Open the not read campaign message dating from {day} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.no-label.campaign.isRead": "{attachment, select, true {Open the read campaign message dating from {day} with at least one attachment of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} false {Open the read campaign message dating from {day} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} other {Open the read campaign message dating from {day} of which the subject is {subject} which expires on {expiringDate}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.no-label.draft.isNotRead": "{attachment, select, true {Open the not read conversation dating from {day} with at least one attachment of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Open the not read conversation dating from {day} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Open the not read conversation dating from {day} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.no-label.draft.isRead": "{attachment, select, true {Open the read conversation dating from {day} with at least one attachment of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Open the read conversation dating from {day} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Open the read conversation dating from {day} of which the subject is {subject}, with 1 draft and {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.no-label.no-draft.isNotRead": "{attachment, select, true {Open the not read conversation dating from {day} with at least one attachment of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Open the not read conversation dating from {day} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Open the not read conversation dating from {day} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.no-label.no-draft.isRead": "{attachment, select, true {Open the read conversation dating from {day} with at least one attachment of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} false {Open the read conversation dating from {day} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} other {Open the read conversation dating from {day} of which the subject is {subject}, with {nbrMessages,  plural, =1 {# message} other {# messages}}, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.no-label.only-draft.isNotRead": "{attachment, select, true {Open the not read conversation dating from {day} with at least one attachment of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} false {Open the not read conversation dating from {day} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} other {Open the not read conversation dating from {day} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} }",
    "interactions.conversation-line-component.title.no-label.only-draft.isRead": "{attachment, select, true {Open the read conversation dating from {day} with at least one attachment of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} false {Open the read conversation dating from {day} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} other {Open the read conversation dating from {day} of which the subject is {subject}, with 1 draft, starting with {truncatedMessage}} }",
    "interactions.conversation-message-attachment.label.download": "download attachment",
    "interactions.conversation-message-attachment.label.preview": "preview attachment",
    "interactions.conversation-message-attachment.text.download": "download",
    "interactions.conversation-message-attachment.text.preview": "preview",
    "interactions.conversation-message-header.attachments.format": " : PDF, JPG, DOC(X), XLS(X), PPT(X), TXT, PNG, GIF, RTF, ODS, ODT",
    "interactions.conversation-message-header.attachments.format-title": "Accepted formats ",
    "interactions.conversation-message-header.attachments.label": "{nb, plural, =1 {# attachment} other {# attachments}}",
    "interactions.conversation-message-header.attachments.max-size": " : 10MB per document and 30MB for all documents",
    "interactions.conversation-message-header.attachments.max-size-title": "Maximum size ",
    "interactions.conversation-message-header.from.adviser": "La Banque Postale",
    "interactions.conversation-message-header.from.label": "Sender",
    "interactions.conversation-message-header.from.me": "Me",
    "interactions.conversation-message-header.isUnread": "Unread",
    "interactions.conversation-message-header.label": "Answer",
    "interactions.conversation-message.header.expand-button.expand.aria-label.campaign.isNotRead": "{empty, select, false {Show and mark as read message sent by {sender} on {sendDate} which expires on {expirationDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which begins with {truncatedContent}} true {Show and mark as read message sent by {sender} on {sendDate} which expires on {expirationDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which has an empty body} other {Show and mark as read message sent by {sender} on {sendDate} which expires on {expirationDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which begins with {truncatedContent}} }",
    "interactions.conversation-message.header.expand-button.expand.aria-label.campaign.isRead": "{empty, select, false {Show message sent by {sender} on {sendDate} which expires on {expirationDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which begins with {truncatedContent}} true {Show message sent by {sender} on {sendDate} which expires on {expirationDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which has an empty body} other {Show message sent by {sender} on {sendDate} which expires on {expirationDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which begins with {truncatedContent}} }",
    "interactions.conversation-message.header.expand-button.expand.aria-label.regular.isNotRead": "{empty, select, false {Show and mark as read message sent by {sender} on {sendDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which begins with {truncatedContent}} true {Show and mark as read message sent by {sender} on {sendDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which has an empty body} other {Show and mark as read message sent by {sender} on {sendDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which begins with {truncatedContent}} }",
    "interactions.conversation-message.header.expand-button.expand.aria-label.regular.isRead": "{empty, select, false {Show message sent by {sender} on {sendDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which begins with {truncatedContent}} true {Show message sent by {sender} on {sendDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which has an empty body} other {Show message sent by {sender} on {sendDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which begins with {truncatedContent}} }",
    "interactions.conversation-message.header.expand-button.expand.title": "Show message",
    "interactions.conversation-message.header.expand-button.shrink.aria-label.campaign": "{empty, select, false {Hide message sent by {sender} on {sendDate} which expires on {expirationDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which begins with {truncatedContent}} true {Hide message sent by {sender} on {sendDate} which expires on {expirationDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which has an empty body} other {Hide message sent by {sender} on {sendDate} which expires on {expirationDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which begins with {truncatedContent}} }",
    "interactions.conversation-message.header.expand-button.shrink.aria-label.regular": "{empty, select, false {Hide message sent by {sender} on {sendDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which begins with {truncatedContent}} true {Hide message sent by {sender} on {sendDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which has an empty body} other {Hide message sent by {sender} on {sendDate}, {attachmentCount, plural, =0 {} =1 {which has one attachment} other {which has # attachments}} and which begins with {truncatedContent}} }",
    "interactions.conversation-message.header.expand-button.shrink.title": "Hide message",
    "interactions.conversation-search-form.buttons.validate.label": "Search",
    "interactions.conversation-search-form.buttons.validate.title": "Search",
    "interactions.conversation-search-form.clear.title": "Clear Search",
    "interactions.conversation-search-form.fields.perimeter.body": "Search in :",
    "interactions.conversation-search-form.fields.perimeter.body-title": "Your search :",
    "interactions.conversation-search-form.fields.perimeter.dropdown-label": "Select search criteria",
    "interactions.conversation-search-form.fields.perimeter.text": "In:",
    "interactions.conversation-search-form.fields.perimeter.title": "Perimeter",
    "interactions.conversation-search-form.fields.text.title": "Search text",
    "interactions.conversation-search-form.no-result": "No result available for your search request. You can retry by updating the filters.",
    "interactions.conversation-search-form.perimeters.all": "All messages",
    "interactions.conversation-search-form.perimeters.conversationDraft": "Drafts",
    "interactions.conversation-search-form.perimeters.conversationMine": "Inbox",
    "interactions.conversation-search-form.perimeters.conversationSent": "Outbot",
    "interactions.conversation-search-form.perimeters.conversationTrashed": "Trash",
    "interactions.conversation-search-form.result": "{nb, plural, =1 {# result} other {# results}}",
    "interactions.conversation-search-form.title": "Search",
    "interactions.conversation.action-bar.expiring-date-label": "Expired on",
    "interactions.conversation.composer.attachments.buttons.delete.aria-label": "Delete the {file} attachment",
    "interactions.conversation.composer.attachments.buttons.delete.title": "Delete the attachment",
    "interactions.conversation.composer.attachments.preview-in-progress": "Preview loading",
    "interactions.conversation.composer.buttons.add-attachment.title1.aria-label": "Add an attachment",
    "interactions.conversation.composer.buttons.add-attachment.title1.label": "Add an attachment",
    "interactions.conversation.composer.buttons.add-attachment.title2.aria-label": "Add an attachment",
    "interactions.conversation.composer.buttons.add-attachment.title2.label": "Add",
    "interactions.conversation.composer.editor.aria-label.message": "Write your message",
    "interactions.conversation.composer.editor.aria-label.response": "Write your response",
    "interactions.conversation.composer.editor.label": "Message",
    "interactions.conversation.composer.editor.placeholder.message": "Write your message",
    "interactions.conversation.composer.editor.placeholder.response": "Write your response",
    "interactions.conversation.composer.modal.body": "Do you want to save your modifications?",
    "interactions.conversation.composer.modal.buttons.back.title": "Back to message",
    "interactions.conversation.composer.modal.buttons.back.value": "Back to message",
    "interactions.conversation.composer.modal.buttons.cancel.title": "Cancel the message creation",
    "interactions.conversation.composer.modal.buttons.save.title": "Save the message as draft",
    "interactions.conversation.composer.modal.buttons.save.value": "Save",
    "interactions.conversation.composer.modal.title": "Save a draft",
    "interactions.conversation.create.ai.close.label": "No, this response is not good enough, send my email",
    "interactions.conversation.create.ai.close.text": "No, send my email",
    "interactions.conversation.create.ai.close.title": "No, send my email",
    "interactions.conversation.create.ai.found.prompt": "Is this response good enough?",
    "interactions.conversation.create.ai.title": "This response might help you",
    "interactions.conversation.create.ai.validate.label": "Yes, this response is good enough",
    "interactions.conversation.create.ai.validate.text": "Yes",
    "interactions.conversation.create.ai.validate.title": "Yes",
    "interactions.conversation.create.input.subject": "Enter an object",
    "interactions.conversation.create.new.message.leave-modal.leave-button.title": "{returnButtonName} and return to inbox",
    "interactions.conversation.create.new.message.optional.hint": "All fields are mandatory except those indicated as optional.",
    "interactions.conversation.create.new.message.theme.input": "Select the reason",
    "interactions.conversation.create.new.message.theme.title": "Please specify the reason for your request",
    "interactions.conversation.create.new.message.title": "Write a message",
    "interactions.conversation.create.subject": "Write a subject",
    "interactions.conversation.create.subject-label": "Email subject",
    "interactions.conversation.create.subject-label.info": "Email ",
    "interactions.conversation.create.theme": "Select theme",
    "interactions.conversation.create.theme-field": "Select theme",
    "interactions.conversation.create.theme-label": "Please specify the reason of your request",
    "interactions.conversation.create.title": "Write a message",
    "interactions.conversation.create.title.panel": "Write a message",
    "interactions.conversation.create.to": "To",
    "interactions.conversation.draft-info.info.delete": "The draft message has been deleted.",
    "interactions.conversation.draft.message-info.info.pop-up.deleteTitle": "Delete draft",
    "interactions.conversation.message-info.info.categorize": "The change has been saved",
    "interactions.conversation.message-info.info.delete": "Suppression has been done.",
    "interactions.conversation.message-info.legal-guardian": "Message sent by {userInfo}, legal representative of {interlocutorInfo}",
    "interactions.conversation.scroll.button.text": "Top",
    "interactions.conversation.success.send": "Your request has been registered. We will answer you as soon as possible",
    "interactions.conversations.claim.iframe-title": "Request form",
    "interactions.conversations.claim.title": "Write a message",
    "interactions.conversations.expired.bold-label": "Expired conversation",
    "interactions.conversations.expired.label": ": We invite you to create a new message.",
    "interactions.conversations.inbenta.iframe-title": "Claim form",
    "interactions.conversations.inbenta.return-to-list": "Abort writing the new message and return to the inbox",
    "interactions.conversations.inbenta.title": "Do you have a question ?",
    "interactions.conversations.panel-result.title.actions.new-conversation": "New message",
    "interactions.conversations.panel-result.title.actions.new-conversation-label": "Write a new message",
    "interactions.conversations.panel-result.title.actions.new-conversation-mobile": "New",
    "interactions.conversations.panel-result.title.actions.new-conversation-sr": "Write a",
    "interactions.conversations.panel-result.title.label.conversationDraft": "Drafts",
    "interactions.conversations.panel-result.title.label.conversationMine": "Inbox",
    "interactions.conversations.panel-result.title.label.conversationSent": "Sent",
    "interactions.conversations.panel-result.title.label.conversationTrashed": "Trash",
    "interactions.conversations.panel-result.title.label.jump-menu": "Return to action menu",
    "interactions.conversations.panel-result.title.label.jump-message": "Browse messages",
    "interactions.conversations.panel-result.title.label.search": "Search",
    "interactions.conversations.search.message-info.error.deleteDraft": "An error occurred while deleting. Please try again later.",
    "interactions.conversations.search.message-info.info.categorize": "The change has been saved",
    "interactions.conversations.search.message-info.info.deleteDraft": "The draft has been deleted.",
    "interactions.conversations.search.message-info.info.pop-up-removeFromTrash": "Are you sure to delete the conversation ?",
    "interactions.conversations.search.message-info.info.pop-up-removeFromTrash-selected": "Are you sure to delete {nb, plural, =1 {the selected conversation} other {the # selected conversations}} ?",
    "interactions.conversations.search.message-info.info.pop-up-removeFromTrashtitle": "Delete",
    "interactions.help.title": "Help",
    "interactions.label.color-name.label-color-1": "Red",
    "interactions.label.color-name.label-color-2": "Orangered",
    "interactions.label.color-name.label-color-3": "Chartreuse",
    "interactions.label.color-name.label-color-4": "Teal",
    "interactions.label.color-name.label-color-5": "Lightskyblue",
    "interactions.label.color-name.label-color-6": "Dodgerblue",
    "interactions.label.color-name.label-color-7": "Royalblue",
    "interactions.label.color-name.label-color-8": "blueviolet",
    "interactions.label.color-name.selected": "Selected",
    "interactions.label.popup-component.buttons.close": "Cancel",
    "interactions.label.popup-component.buttons.save": "Validate",
    "interactions.label.popup-component.folder": "Folder {folder}",
    "interactions.label.popup-component.header.text": "Link to a personal folder",
    "interactions.label.popup-component.header.title": "Link to a personal folder",
    "interactions.label.popup-component.header.title-description": "Select the folder to associate with your message",
    "interactions.label.popup-component.no-data": "No personal files to be displayed",
    "interactions.label.popup-edit-component.buttons.create.close": "Close creation label popup",
    "interactions.label.popup-edit-component.buttons.create.save": "Create label",
    "interactions.label.popup-edit-component.buttons.edit.close": "Close edit label popup",
    "interactions.label.popup-edit-component.buttons.edit.save": "Save label modifications",
    "interactions.label.popup-edit-component.buttons.save": "Validate",
    "interactions.label.popup-edit-component.fields.color.label": "Select color of the new folder",
    "interactions.label.popup-edit-component.fields.color.text-title": "Color",
    "interactions.label.popup-edit-component.fields.name.edit.text": "Name of the folder",
    "interactions.label.popup-edit-component.fields.name.new.text": "Name of the new folder",
    "interactions.label.popup-edit-component.header.edit.text": "Modify my folder",
    "interactions.label.popup-edit-component.header.edit.title": "Modify my folder",
    "interactions.label.popup-edit-component.header.new.text": "New folder",
    "interactions.label.popup-edit-component.header.new.title": "New folder",
    "interactions.label.settings.buttons.add.label": "Add a folder",
    "interactions.label.settings.buttons.add.title": "Add a folder",
    "interactions.label.settings.buttons.delete.title": "Delete the folder {folder}",
    "interactions.label.settings.buttons.edit.title": "Edit the folder {folder}",
    "interactions.label.settings.cannot-create-popup.text": "Your discussion space does not allow creating more than {nb} folders.",
    "interactions.label.settings.cannot-create-popup.title": "Create folder",
    "interactions.label.settings.delete-popup.buttons.cancel": "Cancel",
    "interactions.label.settings.delete-popup.title": "Delete the label",
    "interactions.label.settings.message-info.info.deleteConfirm": "Would you like to delete this folder ?",
    "interactions.label.settings.name.label": "Folder",
    "interactions.label.settings.search.results.subtitle": "Manage my folders",
    "interactions.label.settings.search.results.title": "Manage my folders ({nb} / {total})",
    "interactions.label.settings.search.results.title-empty": "Manage my folders",
    "interactions.label.title": "Folder {folder} of color {color}",
    "interactions.parameters.message-info.error.save": "An error occured while updating parameters.",
    "interactions.parameters.message-info.info.save": "Parameters have been updated.",
    "interactions.parameters.modal.footer.buttons.close": "Cancel",
    "interactions.parameters.modal.footer.buttons.save": "Confirm",
    "interactions.parameters.modal.footer.buttons.send": "Send",
    "interactions.parameters.modal.label.active.part1": "I want to be notified on my email adress",
    "interactions.parameters.modal.label.active.part2": "when I receive a new message.",
    "interactions.parameters.modal.label.inactive.part1": "I don't want to be notified by mail when I receive a new message in my Customer Area of La Banque Postale.",
    "interactions.parameters.modal.label.inactive.part2": "However, for any regulatory information, a notification will be sent on my email address.",
    "interactions.parameters.modal.label.nota-bene": "To indicate or modify this email address, go to your personal online banking Area",
    "interactions.parameters.modal.title": "Notifications management",
    "interactions.parameters.title": "Parameters",
    "interactions.preview-component.previewTitle": "PDF attachment preview",
    "interactions.side-menu.close.aria-label": "Close messaging menu",
    "interactions.side-menu.close.description": "Close the messaging and return to the Contacts space",
    "interactions.side-menu.close.label": "Messaging menu",
    "interactions.side-menu.conversations.lines.mine": "Inbox",
    "interactions.side-menu.conversations.lines.search": "Search",
    "interactions.side-menu.conversations.lines.sent": "Sent message",
    "interactions.side-menu.conversations.section.label": "Main boxes",
    "interactions.side-menu.help.get.label": "Get help",
    "interactions.side-menu.help.label": "Help",
    "interactions.side-menu.labels.add.label": "Create a folder",
    "interactions.side-menu.labels.label": "My folders",
    "interactions.side-menu.labels.manage.label": "Manage my folders",
    "interactions.side-menu.leave.aria-label": "Exit mail and go back to contact modes",
    "interactions.side-menu.main-folder": "My folders",
    "interactions.side-menu.module.label": "Messaging",
    "interactions.side-menu.parameters.label": "Parameters",
    "interactions.side-menu.switch-module.label": "Access to document exchange",
    "interactions.side-menu.switch-module.popup.buttons.cancel.label": "Cancel",
    "interactions.side-menu.switch-module.popup.buttons.cancel.title": "Cancel",
    "interactions.side-menu.switch-module.popup.buttons.confirm.label": "Confirm",
    "interactions.side-menu.switch-module.popup.buttons.confirm.title": "Confirm",
    "interactions.side-menu.switch-module.popup.label": "Access to document exchange",
    "interactions.side-menu.switch-module.popup.message-info.info.confirm-route": "Are you sure to leave the messaging?",
    "interactions.side-menu.switch-module.title": "More",
    "interlocutors": {
      "breadcrumb": {
        "title": "Interlocutors"
      },
      "interlocutor": {
        "projects": {
          "breadcrumb": {
            "title": "Projects"
          },
          "search": {
            "closed": "Closed Projects",
            "open": "Current Projects"
          }
        }
      }
    },
    "multimenu.buttons.back.label": "Back to project page",
    "multimenu.buttons.back.submenu": "Back to file origin choice",
    "page.name": "LBP - Messagerie Sécurisée - {pageName}",
    "page.name.project": "LBP - Espace de partage - {pageName}",
    "page.name.project-info": "Project ",
    "pageContentLoader.label": "Request pending, please wait",
    "powerSelect.selected": "{title} selected",
    "profile": {
      "coordinates": {
        "edit": {
          "cancel": "Cancel",
          "email": {
            "clear": "Clear the external email field.",
            "label": "External email:",
            "title": "External email"
          },
          "mobile-phone": {
            "clear": "Clear the mobile phone field.",
            "invalid-message": "The provided phone number is invalid.",
            "label": "Mobile phone :",
            "title": "Mobile phone"
          },
          "save": "Save"
        },
        "header": "Contact details",
        "message-info": {
          "error": {
            "invalid-form": "The form is invalid, please corect it and try again.",
            "save": "An error occurred, your contact details have not been updated. Please try again later."
          },
          "info": {
            "save": "Your contact details have been updated."
          }
        },
        "view": {
          "edit": "Modify",
          "email": {
            "label": "External email:"
          },
          "mobile-phone": {
            "label": "Mobile phone :"
          }
        }
      },
      "infos": {
        "field": {
          "firstname": {
            "label": "First name:",
            "title": "First name"
          },
          "name": {
            "label": "Last name:",
            "title": "Last name"
          }
        },
        "header": "Personal information"
      },
      "notifications": {
        "NEW_CAMPAIGN_MESSAGE_RECEIVED": "New campaign message received",
        "NEW_DOCUMENT_UPLOADED_BY_ADVISER": "New document uploaded by adviser",
        "NEW_FILE_AVAILABLE_TO_SIGN": "New file to sign",
        "NEW_MESSAGE_INTERLOCUTOR_RECEIVED": "New message received",
        "PROJECT_PUBLICATION_STOPPED": "Project sharing stopped",
        "PROJECT_PUBLISHED_TO_INTERLOCUTOR": "New project shared with me",
        "SEND_DOCUMENT_REJECT_TO_CUSTOMER": "Document rejected by adviser",
        "SEND_UPDATE_TO_CUSTOMER": "Project updated"
      },
      "preferences": {
        "authorizeAudioMessage": {
          "label": "Authorized audio messages:",
          "title": "Authorized audio messages:"
        },
        "authorizeAudioMessages": {
          "false": "No",
          "true": "Yes"
        },
        "availableLangages": {
          "en-gb": "English",
          "fr-fr": "Français"
        },
        "closeErrorMessage": {
          "label": "Closing the error messages:",
          "title": "Closing the error messages:"
        },
        "closeErrorMessages": {
          "false": "Automatic",
          "true": "Manual"
        },
        "edit": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "header": "Preferences",
        "language": {
          "label": "Interface language:",
          "title": "Language"
        },
        "message-info": {
          "error": {
            "save": "An error occurred whilst updating your preferences. Please try again later."
          },
          "info": {
            "save": "The preferences have been updated."
          }
        },
        "spellchecker-language": {
          "label": "Spellchecker language by default:",
          "title": "Spellchecker language by default"
        },
        "view": {
          "edit": "Modify"
        }
      },
      "side-bar": {
        "sign-out": "Sign out",
        "title": "Account settings"
      },
      "side-menu": {
        "personal-informations": "Personal information",
        "preferences": "Preferences",
        "subscriptions": "Notifications"
      },
      "subscriptions": {
        "edit": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "header": "My subscriptions",
        "message-info": {
          "error": {
            "loading": "An error occurred while loading subscriptions. Please try again later.",
            "save": "An error occurred while updating subscriptions. Please try again later."
          },
          "info": {
            "save": "Your subscriptions have been updated."
          }
        },
        "view": {
          "edit": "Edit",
          "state": {
            "disabled": "Disabled",
            "enabled": "Enabled"
          }
        }
      }
    },
    "projects": {
      "breadcrumb": {
        "choose-project": "Project Choice",
        "create": {
          "free": {
            "title": "Free Project"
          },
          "title": "New Project"
        },
        "edit": {
          "free": {
            "title": "Edit Project"
          }
        },
        "project-details": "Details",
        "projects-status": {
          "CLOSED": "Closed Projects",
          "IN_PROGRESS": "Current Projects"
        }
      },
      "create": {
        "project": {
          "buttons": {
            "cancel": "Cancel",
            "preview": "Preview",
            "save": "Save"
          },
          "fields": {
            "assigned-to": "Assigned to",
            "date-format": "MM/DD/YYYY",
            "description": "Description",
            "enddate": "End date",
            "nature": "Nature",
            "title": "Name"
          },
          "folder": {
            "no-data": "Project does not have any folders.",
            "status": {
              "CLOSED": "Closed",
              "INIT": "New",
              "OPENED": "Opened"
            },
            "title": "Folders"
          },
          "message-info": {
            "error": {
              "create": "An error occurred while creating the project, please try again later.",
              "duplicate-title": "The name already exists for this interlocutor.",
              "invalid-form": "The form is invalid, correct it and try again."
            },
            "info": {
              "create": "The project has been created successfully."
            }
          },
          "pop-up": {
            "not-saved-transition": "Your changes were not saved. Are you sure you want to leave this page ?"
          }
        }
      },
      "document": {
        "no-data": "This part has no document."
      },
      "edit": {
        "project": {
          "buttons": {
            "cancel": "Cancel",
            "preview": "Preview",
            "save": "Save"
          },
          "fields": {
            "assigned-to": "Assigned to",
            "date-format": "MM/DD/YYYY",
            "description": "Description",
            "enddate": "End date",
            "nature": "Nature",
            "title": "Name"
          },
          "folder": {
            "no-data": "Project does not have any folders.",
            "status": {
              "CLOSED": "Closed",
              "INIT": "New",
              "OPENED": "Opened"
            },
            "title": "Folders"
          },
          "message-info": {
            "error": {
              "duplicate-title": "The name already exists for this interlocutor.",
              "edit": "An error occurred while editing the project, please try again later.",
              "invalid-form": "The form is invalid, correct it and try again."
            },
            "info": {
              "edit": "The project has been updated successfully."
            }
          },
          "pop-up": {
            "not-saved-transition": "Your changes were not saved. Are you sure you want to leave this page ?"
          }
        }
      },
      "list": {
        "message-info": {
          "error": {
            "get-404": "An error occurred, the project was not found. Please retry later.",
            "unknown": "An error occurred on the page. Please retry later."
          }
        },
        "title": "Projects"
      },
      "new-project": {
        "buttons": {
          "choose-btn": "Choose"
        },
        "free": {
          "description": "In a free project, you can freely add documents.",
          "title": "New Free Project"
        }
      },
      "project": {
        "interlocutor-new": {
          "help": "Create a new project for {interlocutor}",
          "title": "New project for {interlocutor}"
        },
        "new": {
          "help": "Create a new project",
          "title": "New project"
        }
      },
      "project-group": {
        "no-data": "This group of project is empty."
      },
      "project-group-title": {
        "projects": "Projects owned by {interlocutorFullName}",
        "projects-shared": "Projects shared with {interlocutorFullName}"
      },
      "project-part": {
        "no-data": "This project has no part."
      },
      "search": {
        "filter": {
          "label": {
            "assignedTo": "Assigned to:",
            "interlocutor": "Interlocutor:"
          }
        },
        "legend": "Search",
        "message-info": {
          "error": {
            "project-load": "Failed to load the projects list"
          }
        }
      },
      "side-menu": {
        "closed-projects": "Closed",
        "in-progress-projects": "In progress",
        "interlocutor-menu-label": "Projects of {name}",
        "my-projects": "Mine",
        "projects": "Projects"
      },
      "signature": {
        "spinner": {
          "primary-error": "There was an error",
          "primary-success": "Document signed",
          "primary-wait": "Checking document signature...",
          "secondary-error": "The document was not signed",
          "secondary-success": "You may close this tab",
          "secondary-wait": "Please wait..."
        }
      },
      "title": {
        "empty": "Projects",
        "mine": "Projects"
      }
    },
    "projects.help.title": "Help",
    "projects.project-group-title-heading.project.text": "Project {project}",
    "projects.project-group-title-heading.status.closed": "Closed",
    "projects.project-group-title-heading.status.in-progress": "In progress",
    "projects.project-group-title-heading.text": "My {status} projects",
    "projects.project-group-title.projects": "My projects",
    "projects.project-group.no-data.closed": "No closed project",
    "projects.project-group.no-data.current": "No current project",
    "projects.project.actions.action-download.aria-label": "Download all documents as zip",
    "projects.project.actions.action-download.title": "Download documents",
    "projects.project.actions.action-notif.aria-label": "Notify my advisor that all documents are sent",
    "projects.project.actions.action-notif.title": "Notify my advisor that all documents are sent",
    "projects.project.actions.title": "Actions",
    "projects.project.description.closedOn": "Closed on",
    "projects.project.description.createdOn": "Created on",
    "projects.project.description.createdOnList": "Created on {date}",
    "projects.project.description.desc": "Project description",
    "projects.project.description.expiredOn": "Expired on",
    "projects.project.description.finishedOn": "Ending on",
    "projects.project.description.indicators.new-project.text": "This Project space has been created by my adviser.",
    "projects.project.description.indicators.new-project.title": "New",
    "projects.project.description.indicators.related-messages.text": "Consult in the messaging    >",
    "projects.project.description.indicators.related-messages.title": "{nb, plural, =1 {# Related message} other {# Related messages}}",
    "projects.project.description.indicators.unread-new-documents.title": "{nb, plural, =1 {# new document} other {# new documents}}",
    "projects.project.description.indicators.unread-rejected-documents.title": "{nb, plural, =1 {# rejected document} other {# rejected documents}}",
    "projects.project.description.indicators.unread-validated-documents.title": "{nb, plural, =1 {# validated document} other {# validated documents}}",
    "projects.project.description.indicators.warning.text": "At {date}, I warned my advisor that all my files were provided.",
    "projects.project.description.indicators.warning.title": "I warned my advisor",
    "projects.project.description.link": "Go to the project",
    "projects.project.description.new": "New",
    "projects.project.description.shared": "Shared by me",
    "projects.project.description.title": "About this project",
    "projects.project.documents.action.delete.aria-label": "Delete the document {document}",
    "projects.project.documents.action.delete.title": "Delete the document",
    "projects.project.documents.delete.group-title.name": "Do you want to delete this document:",
    "projects.project.documents.delete.title": "Delete the document",
    "projects.project.documents.deletion.buttons.cancel.label": "Cancel",
    "projects.project.documents.deletion.buttons.cancel.text": "Cancel",
    "projects.project.documents.deletion.buttons.cancel.title": "Cancel",
    "projects.project.documents.deletion.buttons.ok.label": "Ok",
    "projects.project.documents.deletion.buttons.ok.text": "Ok",
    "projects.project.documents.deletion.buttons.ok.title": "Ok",
    "projects.project.documents.deletion.group-title.body": "Deletion",
    "projects.project.documents.deletion.group-title.text": "Do you want to delete this file?",
    "projects.project.documents.deletion.group-title.title": "Delete the file",
    "projects.project.documents.file.action.delete.aria-label": "Delete the file {file}",
    "projects.project.documents.file.action.delete.title": "Delete the file",
    "projects.project.documents.file.action.download.aria-label": "Download or preview the file {file}",
    "projects.project.documents.file.action.download.title": "Download the file",
    "projects.project.documents.file.action.preview.aria-label": "Preview the file {file}",
    "projects.project.documents.file.action.upload.text": "File upload in progress ...",
    "projects.project.documents.file.action.upload.title": "File upload",
    "projects.project.documents.file.add.aria-label": "Add a file to the document {document}, status {status}",
    "projects.project.documents.file.add.from.addInProgress": "Upload in progress",
    "projects.project.documents.file.add.from.duplicate": "A file with the same name is already present into the document",
    "projects.project.documents.file.add.from.file.aria-label": "File {file}",
    "projects.project.documents.file.add.from.fileSearchInProgress": "File search in progress",
    "projects.project.documents.file.add.from.message-info.errors.duplicate": "You cannot import multiple files with the same name.",
    "projects.project.documents.file.add.from.no-files.aria-label": "No file in the project {project}",
    "projects.project.documents.file.add.from.no-files.label": "No file in this project",
    "projects.project.documents.file.add.from.no-project": "You do not have a project in this category.",
    "projects.project.documents.file.add.from.project.aria-label": "Files list int the project {project}",
    "projects.project.documents.file.add.from.projectSearchInProgress": "Project search in progress",
    "projects.project.documents.file.add.from.selectFiles": "Add selected files",
    "projects.project.documents.file.add.fromClosed.aria-label": "File from a closed project, this choice change the list of available files",
    "projects.project.documents.file.add.fromClosed.label": "File from a closed project",
    "projects.project.documents.file.add.fromCurrent.aria-label": "File from current project, this choice change the list of available files",
    "projects.project.documents.file.add.fromCurrent.label": "File from current project",
    "projects.project.documents.file.add.fromDevice.aria-label": "File from this device, this choice change the list of available files",
    "projects.project.documents.file.add.fromDevice.label": "File from this device",
    "projects.project.documents.file.add.inProgress": "Upload progress",
    "projects.project.documents.file.add.selectFile": "Select a file to upload",
    "projects.project.documents.file.add.title": "Add a file",
    "projects.project.documents.file.attachments.from.adviser": "La Banque Postale",
    "projects.project.documents.file.attachments.popup.label.download": "download file",
    "projects.project.documents.file.attachments.popup.label.preview": "preview file",
    "projects.project.documents.file.attachments.popup.text.download": "download",
    "projects.project.documents.file.attachments.popup.text.preview": "preview",
    "projects.project.documents.file.deleteAction": "Delete the file",
    "projects.project.documents.file.downloadAction": "Download the file",
    "projects.project.documents.message-info.error.delete": "An error occurred while deleting the document, please try again later.",
    "projects.project.documents.message-info.info.delete": "This document has been removed",
    "projects.project.documents.message-info.info.pop-up.delete": "Do you want to delete this document?",
    "projects.project.documents.reject.aria-label": "Reason for document rejection {document}",
    "projects.project.documents.reject.modal.title": "Reason for document rejection",
    "projects.project.documents.reject.no-data": "Unknown reject reason",
    "projects.project.documents.status.KO": "Invalid",
    "projects.project.documents.status.OK": "Valid",
    "projects.project.documents.status.TO_ACKNOWLEDGE": "Uploaded",
    "projects.project.documents.status.TO_UPLOAD": "To upload",
    "projects.project.documents.status.TO_UPLOAD_LBP": "To upload by La Banque Postale",
    "projects.project.documents.status.UPLOADED": "Uploaded by La Banque Postale",
    "projects.project.documents.title": "Project documents",
    "projects.project.documents.unread.text": "Unread",
    "projects.project.documents.unread.title": "Document unread",
    "projects.project.documents.uploadOther.baseModal.buttonCancel": "Cancel",
    "projects.project.documents.uploadOther.button.OK": "OK",
    "projects.project.documents.uploadOther.button.aria-label": "Validate the creation of the new document type",
    "projects.project.documents.uploadOther.confirmModal.text": "Document of type \"{type}\" has been created",
    "projects.project.documents.uploadOther.errorModal.text": "Error adding the document",
    "projects.project.documents.uploadOther.errorModal.textDuplicate": "Cannot add a document with the same name as an existing one",
    "projects.project.documents.uploadOther.errorModal.title": "Add a new document",
    "projects.project.documents.uploadOther.fileModal.title": "Add a new file to the document",
    "projects.project.documents.uploadOther.powerSelect.aria-label": "List of possible document types to add",
    "projects.project.documents.uploadOther.powerSelect.model.aria-label": "Model {model} of nature {nature}",
    "projects.project.documents.uploadOther.powerSelect.model.title": "- {model}",
    "projects.project.documents.uploadOther.powerSelect.nature.aria-label": "Nature {nature}",
    "projects.project.documents.uploadOther.title": "Upload file for another type of document",
    "projects.project.labels.advisorWarned": "I have warned my advisor on the {date} that I have validated all my documents",
    "projects.project.labels.clos": "See project {name} closed on the {closed}, expire on the {expire}",
    "projects.project.labels.newDocuments": "{nb, plural, =1 {1 new document is available} other {# new documents are available}}, see the project",
    "projects.project.labels.open": "See project {name} opened on the {created} {nbMessages, plural, =0 { } =1 {, # message} other {, # messages}} {isWarned, plural, =1 {, advisor warned} other {}}",
    "projects.project.labels.readMessages": "{nbMessages, plural, =0 {} =1 {Read message associated} other {Read # messages associated}} with project {project}",
    "projects.project.labels.rejectedDocuments": "There {nb, plural, =1 {is 1 new document} other {are # new documents}} rejected, see the project",
    "projects.project.labels.unvalidatedDocuments": "There {nb, plural, =1 {is 1 new document} other {are # new documents}} not validated, see the project",
    "projects.quit.text": "Access to the conversation will make you change module. Would you like to continue ?",
    "projects.side-menu.close.aria-label": "Close document exchange menu",
    "projects.side-menu.close.label": "Document exchange menu",
    "projects.side-menu.description.closed": "Closed projects",
    "projects.side-menu.description.inprogress": "Current projects",
    "projects.side-menu.leave.aria-label": "Exit document exchange and go back to contact modes",
    "projects.side-menu.module.label": "Document exchange",
    "projects.side-menu.switch-module.label": "Access to messaging",
    "projects.side-menu.switch-module.popup.buttons.cancel.label": "Cancel",
    "projects.side-menu.switch-module.popup.buttons.cancel.title": "Cancel",
    "projects.side-menu.switch-module.popup.buttons.confirm.label": "Confirm",
    "projects.side-menu.switch-module.popup.buttons.confirm.title": "Confirm",
    "projects.side-menu.switch-module.popup.label": "Access to messaging",
    "projects.side-menu.switch-module.popup.message-info.info.confirm-route": "Are you sure to leave the document exchange?",
    "projects.side-menu.switch-module.title": "More",
    "select.none": "Choose the type of documents",
    "select.none-label": "Choose document type :",
    "shared": {
      "components": {
        "a11y": {
          "modals": {
            "alert-modal": {
              "closeButtonText": "Close",
              "closeButtonTitle": "Close the window"
            }
          }
        },
        "absence-table": {
          "absence-backend-error": "Error when retreiving absence date for an adviser",
          "granularity-dateformat-day": "DD/MM/YYYY",
          "granularity-dateformat-month": "MMMM YYYY",
          "granularity-dateformat-week": "Week {week} - {year}",
          "granularity-day": "day",
          "granularity-legend": "Display by",
          "granularity-month": "month",
          "granularity-week": "week",
          "grid-caption": "List of absences",
          "next-day-button-label": "Next day",
          "next-month-button-label": "Next month",
          "next-week-button-label": "Next week",
          "prev-day-button-label": "Previous day",
          "prev-month-button-label": "Previous month",
          "prev-week-button-label": "Previous week",
          "range-label": "From {start} to {end}",
          "range-label-backup": "Backup is {backup}",
          "today-button-label": "Today"
        },
        "charts": {
          "adviser-dashboard-chart": {
            "ACKNOWLEDGE": "Acknowledged conversations",
            "CLOSED": "Closed conversations",
            "CLOSED-AFTER-CRITICAL-DUE-DATE": "Closed conversations after critical due date",
            "CLOSED-BEFORE-CRITICAL-DUE-DATE": "Closed conversations before critical due date",
            "CLOSED-BEFORE-DUE-DATE": "Closed conversations before due date",
            "OPEN": "Opened conversations",
            "REOPEN": "Reopened conversations",
            "nb-conversations": "Number of conversations"
          }
        },
        "date": {
          "cancel": "Cancel",
          "dow": {
            "abbr": {
              "Fr": "Friday",
              "Mo": "Monday",
              "Sa": "Saturday",
              "Su": "Sunday",
              "Th": "Thursday",
              "Tu": "Tuedsay",
              "We": "Wednesday"
            },
            "text": {
              "Fr": "Fr",
              "Mo": "Mo",
              "Sa": "Sa",
              "Su": "Su",
              "Th": "Th",
              "Tu": "Tu",
              "We": "We"
            }
          },
          "format": "DD/MM/YYYY",
          "formatWithTime": "DD/MM/YYYY HH:mm",
          "month": {
            "april": "April",
            "august": "August",
            "december": "December",
            "february": "February",
            "january": "January",
            "july": "July",
            "june": "June",
            "march": "March",
            "may": "May",
            "november": "November",
            "october": "October",
            "september": "September"
          },
          "month-year": "Month Year",
          "next-month": "Next month",
          "next-year": "Next year",
          "ok": "OK",
          "picker-button-alt": "Choose a date",
          "placeholderFormat": "DD/MM/YYYY",
          "placeholderFormatWithTime": "DD/MM/YYYY hh:mm",
          "previous-month": "Previous month",
          "previous-year": "Previous year"
        },
        "dropdown": {
          "default-button-title": "Actions"
        },
        "form-component": {
          "form-fields-required-label": "All fields marked with an * are required."
        },
        "form-group-component": {
          "missing-text": "This field is required."
        },
        "modals": {
          "audio-preview": {
            "loading": "Loading...",
            "modal-title": "Audio play"
          },
          "document-preview": {
            "error-message": "An error occurred while downloading the document, please try again later.",
            "modal-title": "Document preview",
            "select-page": "Select page",
            "upload-by": "Uploaded by {actor} on {date}",
            "zoom-in": "Zoom in",
            "zoom-out": "Zoom out"
          },
          "image-preview": {
            "error-message": "An error occurred while downloading the picture, please try again later.",
            "loading-message": "Loading, please wait...",
            "modal-title": "File preview",
            "upload-by": "Uploaded by",
            "upload-date": "Uploaded on",
            "zoom-in": "Zoom in",
            "zoom-out": "Zoom out"
          },
          "modal-component": {
            "modal-cancel": "Cancel",
            "modal-confirm": "Confirm"
          },
          "title": {
            "closeButton": "Close this message"
          }
        },
        "multi-selectors": {
          "cc-multi-selector": {
            "error": "An error occurred during the search.",
            "error-external-on-secured": "Cannot add external email on secured email channel",
            "error-secured-on-external": "Cannot add secured email on secured email channel",
            "errors": {
              "no-account-found-external": "No account with external mail '{email}' has been found",
              "no-account-found-secured": "No account with secured mail '{email}' has been found"
            },
            "loading": "Loading result(s)...",
            "no-results": "No match",
            "type": {
              "actor": "Advisor: ",
              "entity": "Entity: ",
              "external": "Ext.",
              "interlocutor": "",
              "trustedInterlocutor": "Trusted interlocutor"
            }
          },
          "tags-multi-selector": {
            "error": "An error occurred during the search.",
            "loading": "Loading result(s)...",
            "no-results": "No match"
          }
        },
        "panels": {
          "showLess": "Show less",
          "showMore": "Show more"
        },
        "presets-selector": {
          "title": "Preset"
        },
        "rating": {
          "message-info": {
            "error": {
              "rate": "An error occurred while saving rate, please try again later."
            },
            "info": {
              "rate": "The rate has been successfully saved."
            }
          },
          "title": "Do you want to change your grade?",
          "title-new": "Are you satisfied by this answer?"
        },
        "result-list": {
          "results": {
            "error": "An error occurred while loading, please try again later.",
            "loading": "Loading...",
            "none": "No result available",
            "wait": "Please wait"
          }
        },
        "text-editor": {
          "actions-list": {
            "f-ai": {
              "choose-template": "Choose a reply template:",
              "ko-declaration": "Declare the templates as not satisfying:",
              "show-templates": "Show semantic analysis reply templates"
            },
            "f-aligncenter": "Align center",
            "f-alignleft": "Align left",
            "f-alignright": "Align right",
            "f-attachments": {
              "help": "Copy attachments"
            },
            "f-bold": "Bold",
            "f-color": {
              "help": "Color",
              "label": "Change text color"
            },
            "f-font": {
              "help": "Font",
              "label": "Choose font family"
            },
            "f-gallery": "Add image",
            "f-italic": "Italic",
            "f-link": {
              "help": "Add link",
              "ok": "OK",
              "url": "URL"
            },
            "f-list": "Switch from/to list",
            "f-signature": {
              "apply": "Apply signature",
              "choose": "Choose signature",
              "label": "Signature..."
            },
            "f-size": {
              "help": "Font size",
              "label": "Choose font size"
            },
            "f-spellCheck": {
              "check-spelling": "Check spelling",
              "choose-language": "Choose language",
              "ignore": "Ignore",
              "ignore-all": "Ignore all"
            },
            "f-underline": "Underline",
            "f-upload": {
              "help": "Add an attachment"
            }
          },
          "initial-attachments": {
            "copy-attachment": "Copy an attachment",
            "message-info": {
              "error": {
                "copy-attachment": "An error occurred while copying the attachment, please try again later."
              },
              "info": {
                "file-removed": "The attachment has been removed.",
                "file-uploaded": "The attachment has been copied."
              }
            }
          },
          "inline-images": {
            "message-info": {
              "error": {
                "empty-file": "You can't upload empty files. Please try again with a valid file.",
                "preview": "An error occurred while downloading the picture, please try again later."
              }
            }
          },
          "message-composer-attachments": {
            "delete": "Delete this attachment",
            "download": "Download this attachment",
            "dropdown-button-title": "Actions",
            "message-info": {
              "error": {
                "bad-content-type": "The type of attachment is not allowed.",
                "download": "An error occurred while downloading the attachment, please try again later.",
                "empty-file": "You can't upload empty files. Please try again with a valid file.",
                "failed-to-upload": "Failed to upload the attachment. Please try again later",
                "file-name-already-used": "An attachment with the same name already exists.",
                "maxAttachmentReached": "You have reached the maximum number of attachments allowed.",
                "message-size-limit": "You have exceeded the max size of message attachments.",
                "preview": "An error occurred while downloading the picture, please try again later."
              },
              "info": {
                "file-uploaded": "The attachment has been uploaded",
                "file-uploading": "Upload in progress ..."
              }
            },
            "preview": "Preview this attachment"
          }
        }
      },
      "file-uploader": {
        "error": {
          "EMPTY_ATTACHMENT": "You can't upload empty files. Please try again with a valid file.",
          "NOT_ALLOWED_CONTENT_TYPE": "The type of attachment is not allowed.",
          "addFile": "The file has not been uploaded. Please try again later.",
          "contentType": "The type of attachment is not allowed.",
          "length": "The size of the attachment is greater than the maximum size allowed."
        }
      },
      "helpers": {
        "date-short-view": {
          "today": "Today, {hour}"
        }
      },
      "img-avatar": {
        "avatar-alt": "{type, select, ADVISER {Adviser }  CLIENT {Interlocutor } other {}}{gender, select, MR {Mr }  MME {Ms } other {}}{lastname} {firstname}",
        "unknown-person": "Unknown person"
      },
      "inline-uploader": {
        "error": {
          "EMPTY_ATTACHMENT": "You can't upload empty files. Please try again with a valid file.",
          "length": "The size of the inline image is greater than the maximum size allowed."
        }
      },
      "numeric-unit": {
        "billion": "G",
        "million": "M",
        "thousand": "K"
      },
      "pagination": "pagination",
      "skip-link": "Navigation (Skip menu)",
      "spellchecker": {
        "languages": {
          "de": "German",
          "en": "English",
          "fr": "French",
          "nl": "Dutch",
          "ru": "Russian"
        },
        "message-info": {
          "error": {
            "unknown": "An error occurred."
          }
        }
      },
      "theme": {
        "criticalDueDateDelay": {
          "error": {
            "message": "This field must be greater than Late in"
          }
        }
      },
      "treeview": {
        "button": {
          "list.label": "Show the result list only",
          "search.label": "Search",
          "select.default.label": "Choisir",
          "tree.label": "Show the complete tree"
        },
        "message": {
          "noresult": "Search returned no result"
        }
      }
    },
    "shared.skip-link": "Go to Main content (Exit the menu)",
    "side-panel": {
      "accessibility": {
        "buttons": {
          "close": {
            "title": "Close"
          }
        },
        "contrast": {
          "enhanced": "Enhanced",
          "standard": "Standard"
        },
        "disparities": "Disparities",
        "message-info": {
          "error": {
            "unknown": "An error occurred while changing the accessibility contrast, please try again later."
          }
        },
        "title": "Accessibility"
      },
      "burger": {
        "label": "Show / Hide the Menu"
      },
      "hide-menu": "Hide side menu",
      "logo": {
        "by": "by",
        "ti": "Trusted Interactions",
        "worldline": "Worldline"
      },
      "logo-alt": "WL Trusted Interactions",
      "module": {
        "title": "Module"
      },
      "settings": {
        "myprofile": "Settings",
        "name": "Settings",
        "preferences": "Preferences"
      },
      "show-menu": "Show side menu",
      "toggle-title": "Opens/Closes the menu"
    },
    "themes.ACCOUNT_NUMBER": "Account number",
    "themes.CREDIT_NUMBER": "Credit number",
    "themes.PHONE_NUMBER": "Phone number",
    "themes.error.ACCOUNT_NUMBER": "Select an account number",
    "themes.error.CREDIT_NUMBER": "Select a credit number",
    "themes.error.message": "Please provide details regarding your message",
    "themes.error.message.replay": "Enter your message to send your response",
    "themes.error.subject": "Please enter the subject of the message",
    "themes.error.theme": "Please specify the reason for your request",
    "themes.input.ACCOUNT_NUMBER": "Select the account",
    "themes.input.CREDIT_NUMBER": "Select the real estate credit number",
    "themes.optional.field": "(optional)",
    "topbar": {
      "burger": {
        "label": "Show / Hide the Menu"
      },
      "interlocutors": {
        "myself": "My account"
      },
      "message-info": {
        "log-out-confirmation": "Do you want to sign out? All the unsaved data will be lost."
      },
      "module": {
        "administration": "Administration",
        "interactions": "Messages",
        "profile": "Profile",
        "projects": "Projects"
      },
      "nav": {
        "label": "Multi module topbar"
      },
      "organisation": {
        "name": "equensWorldline"
      },
      "settings": {
        "profile": "Parameters",
        "sign-out": "Sign Out"
      }
    },
    "viewer.error.format": "Only PDF files are viewable",
    "viewer.img.label": "Loaded : {name} - Page {nbPage}",
    "viewer.page.first": "First page",
    "viewer.page.label": "There are {nbPages} inside the document, you are page {current}",
    "viewer.page.last": "Last page",
    "viewer.page.next": "Next page",
    "viewer.page.previous": "Previous page",
    "viewer.select.label": "Page number",
    "viewer.zoom.minus": "Dezoomer",
    "viewer.zoom.plus": "Zoomer",
    "viewer.zoom.title": "Zoom:",
    "viewer.zoom.unzoomed": "Unzoomed",
    "viewer.zoom.zoomed": "Zoomed"
  };
  _exports.default = _default;
});