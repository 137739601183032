define("lbp-cust-web-application/components/a11y/a11y-action-div", ["exports", "lbp-cust-web-application/mixins/keydown"], function (_exports, _keydown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_keydown.default, {
    // Create div tag
    tagName: 'div',
    tabIndex: undefined,
    logger: Ember.inject.service('logger-service'),
    // Attribute bindings
    attributeBindings: ['label:aria-label', 'tabindex', 'title', 'aria-expanded', 'aria-controls', 'ariaChecked:aria-checked', 'ariaDescribedby:aria-describedby', 'id'],
    // Name of the div css class
    class: undefined,
    // Class bindings
    classNameBindings: ['disableBorder:lbp-div-action'],
    // Tab index is displayed only if the action is enable
    tabindex: Ember.computed('enableAction', function () {
      if (this.get('enableAction') && this.get("tabIndex") === undefined) {
        return 0;
      } else {
        return undefined;
      }
    }),
    // Aria role for the div
    ariaRole: Ember.computed('enableAction', 'role', function () {
      return this.get('role') ? this.get('role') : this.get('enableAction') ? 'button' : undefined;
    }),
    // Disable the border on click
    disableBorder: false,
    // Enable the action
    enableAction: true,
    isChecked: undefined,
    ariaChecked: Ember.computed('isChecked', function () {
      if (this.get('isChecked') != undefined) {
        return this.get('isChecked') ? 'true' : 'false';
      } else {
        return undefined;
      }
    }),
    action: function action() {
      this.get('logger').debug('No Custom action defined');
      // Remove focus
      document.activeElement.blur();
    },
    click: function click() {
      if (this.get('enableAction')) {
        this.get('action')();
      }
    }
  });
  _exports.default = _default;
});