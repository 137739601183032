define("lbp-cust-web-application/models/actor", ["exports", "ticust-web-common/models/actor"], function (_exports, _actor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _actor.default;
    }
  });
});