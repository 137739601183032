define("lbp-cust-web-application/controllers/interlocutors/interlocutor/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    text: Ember.computed.alias('model.templateContent')
  });
  _exports.default = _default;
});