define("lbp-cust-web-application/templates/components/ti-web-shared/projects/lbp-action-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "U8uU60sY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"project-action \",[27,\"concat\",[[23,[\"icon\"]],\"-item\"],null]]]],[9],[0,\"\\n\"],[4,\"a11y/a11y-action-div\",null,[[\"class\",\"label\",\"action\"],[\"project-action-trigger\",[23,[\"ariaLabel\"]],[27,\"action\",[[22,0,[]],\"triggerAction\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"project-action-trigger-body\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"project-action-left\"],[9],[0,\"\\n        \"],[1,[27,\"a11y/a11y-semantic-icon\",null,[[\"textClass\",\"fixClass\",\"iconClass\",\"srHidden\"],[\"\",\"fixSafari\",[27,\"concat\",[\"go-right project-icon \",[23,[\"icon\"]]],null],true]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"project-action-right\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"project-action-trigger-icon\"],[9],[0,\"\\n      \"],[1,[27,\"a11y/a11y-semantic-icon\",null,[[\"textClass\",\"fixClass\",\"iconClass\",\"srHidden\"],[\"\",\"fixSafari\",\"go-right project-icon project-action-action noMargin\",true]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/projects/lbp-action-element.hbs"
    }
  });
  _exports.default = _default;
});