define("lbp-cust-web-application/router", ["exports", "lbp-cust-web-application/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('app', function () {
      this.mount('ticust-web-module-interactions', {
        as: 'ticust-web-module-interactions',
        path: '/interactions'
      });
      this.mount('ticust-web-module-profile', {
        as: 'ticust-web-module-profile',
        path: '/profile'
      });
      this.mount('ticust-web-module-projects', {
        as: 'ticust-web-module-projects',
        path: '/sharing'
      });
    });
    this.mount('ticust-web-module-authentication', {
      as: 'ticust-web-module-authentication',
      path: '/auth'
    });
  });
  var _default = Router;
  _exports.default = _default;
});