define("lbp-cust-web-application/components/ti-web-shared/treeview/treeview-node-line-component", ["exports", "ti-web-shared/components/ti-web-shared/treeview/treeview-node-line-component"], function (_exports, _treeviewNodeLineComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _treeviewNodeLineComponent.default;
    }
  });
});