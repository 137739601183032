define("lbp-cust-web-application/initializers/components/ticust-web-module-interactions/conversation/conversation-message-body", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message-body"], function (_exports, _conversationMessageBody) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message-body',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _conversationMessageBody.default.reopen({
    ajax: Ember.inject.service(),
    identity: Ember.inject.service('identity-service'),
    safeHtmlContent: Ember.computed('htmlContent', function () {
      return Ember.String.htmlSafe(this.manageLinkInhtmlContent(this.get('htmlContent')));
    }),
    manageLinkInhtmlContent: function manageLinkInhtmlContent(htmlString) {
      var wrapper = document.createElement('div');
      wrapper.innerHTML = htmlString;
      var links = wrapper.querySelectorAll('a');
      links.forEach(function (link) {
        link.setAttribute('target', '_blank');
        link.setAttribute('contenteditable', 'false');
      });
      return wrapper.innerHTML;
    },
    isCampaign: Ember.computed('model.type', function () {
      return this.get('model.type') === 'CAMPAIGN';
    }),
    // LBP: add listeners to links with an exportLBP attr to register click events
    didInsertElement: function didInsertElement() {
      var _this = this;
      if (!this.get('isCampaign')) {
        return;
      }
      this.$('.conversation-campaign a').click(function (event) {
        var linkId = event.target.getAttribute('exportLBP');
        if (!linkId) {
          return;
        }
        var conversationId = _this.get('model.conversation.id');
        var url = '/api/customer/conversations/' + conversationId + '/register-link-access/' + linkId;
        return _this.get('ajax').raw(url, {
          method: 'POST',
          headers: _this.get('identity.ajaxHeaders')
        });
      });
    }
  });
});