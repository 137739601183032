define("lbp-cust-web-application/initializers/models/conversation-message", ["exports", "ember-data", "ticust-web-common/models/conversation-message"], function (_exports, _emberData, _conversationMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ticust-web-common/models/conversation-message',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _conversationMessage.default.reopen({
    interlocutor: _emberData.default.attr()
  });
});