define("lbp-cust-web-application/helpers/sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sum = sum;
  function sum(params) {
    return params.reduce(function (a, b) {
      return a + b;
    });
  }
  var _default = Ember.Helper.helper(sum);
  _exports.default = _default;
});