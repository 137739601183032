define("lbp-cust-web-application/initializers/routes/ticust-web-module-interactions/interlocutors/interlocutor/conversations/labels/label", ["exports", "ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/labels/label"], function (_exports, _label) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/labels/label',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _label.default.reopen({
    model: function model(params) {
      var _this = this;
      return this._super(params).then(function (model) {
        model.labelSource = _this.get('store').find('label', params.label);
        return Ember.RSVP.hash(model);
      });
    }
  });
});