define("lbp-cust-web-application/routes/app/ticust-web-module-interactions/interlocutors/interlocutor/parameters/notifications", ["exports", "ti-web-shared/mixins/toastMixin", "ti-web-shared/mixins/router-mixin"], function (_exports, _toastMixin, _routerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var defaultChannel = 'EXTERNAL_MAIL';
  var allNotificationId = 'ALL';
  var _default = Ember.Route.extend(_toastMixin.default, _routerMixin.default, {
    templateName: 'app/ticust-web-module-interactions/interlocutors/interlocutor/parameters/notifications',
    store: Ember.inject.service(),
    identityService: Ember.inject.service(),
    tagService: Ember.inject.service('tags'),
    lbpService: Ember.inject.service('lbp-service'),
    model: function model() {
      var interlocutor = this.modelFor('app.ticust-web-module-interactions.interlocutors.interlocutor');
      return Ember.RSVP.hash({
        owner: interlocutor,
        notification: this.store.find('notification', allNotificationId),
        subscriptions: this.store.query('subscription', {
          owner: interlocutor.get('id')
        }),
        choice: true
      }).then(function (hash) {
        // Email
        hash.externalEmailAddress = hash.owner.get('externalEmailAddress');
        // If user has subscriptions
        if (hash.subscriptions.get('content.length') > 0) {
          // Get email notification subscription
          var subscription = hash.subscriptions.filterBy('notification.id', allNotificationId).get('firstObject');
          hash.choice = subscription ? subscription.get('enabled') : true;
        }
        return hash;
      });
    },
    afterModel: function afterModel() {
      this.addAttributeForToRadioButtons();
      this.get('tagService').sendPageTag({
        name: 'contacter::messagerie::parametres'
      });
    },
    addAttributeForToRadioButtons: function addAttributeForToRadioButtons() {
      Ember.run.later(function () {
        var radiosLabels = document.querySelectorAll("label.notifications-body__label-radio");
        if (radiosLabels && radiosLabels.length > 0) {
          radiosLabels.forEach(function (label) {
            label.setAttribute('for', label.getElementsByTagName("input")[0].id);
          });
        }
      }, 30);
    },
    _focusValidateButton: function _focusValidateButton() {
      var validateButton = document.querySelector("button.btn-base-param.notification-btn.btn-primary");
      if (validateButton) {
        validateButton.focus();
      }
    },
    _focusTostCloseButton: function _focusTostCloseButton() {
      Ember.run.later(function () {
        var toast = document.getElementById("toast-container");
        if (toast) {
          toast.setAttribute('tabindex', '-1');
          toast.focus();
        }
      }, 30);
    },
    actions: {
      cancel: function cancel() {
        // Get subscriptions
        var subscriptions = this.controller.get('model.subscriptions');
        var subscription = false;

        // If user has subscriptions
        if (subscriptions.get('content.length') > 0) {
          // Get email notification subscription
          subscription = subscriptions.filterBy('notification.id', allNotificationId).get('firstObject');
        }
        if (subscription) {
          this.controller.set('choices', subscription.get('enabled'));
        }
        this.set('lbpService.settingCancelButtonClicked', true);
        // Redirect to webmail
        this.transitionTo('app.ticust-web-module-interactions');
      },
      save: function save() {
        var _this = this;
        // Get subscriptions
        var subscriptions = this.controller.get('model.subscriptions');
        var subscription = false;
        var subscriptionEnabled = this.controller.get('choices');

        // If user has subscriptions
        if (subscriptions.get('content.length') > 0) {
          // Get email notification subscription
          subscription = subscriptions.filterBy('notification.id', allNotificationId).get('firstObject');
        }
        if (subscription) {
          subscription.set('enabled', subscriptionEnabled);
        } else if (!subscriptionEnabled) {
          // If subscription does not exist
          // and the interlocutor does not want receive notifications
          // We need to store a subscription with enable = false
          subscription = this.get('store').createRecord('subscription', {
            owner: this.controller.get('model.owner'),
            notification: this.controller.get('model.notification'),
            channel: defaultChannel,
            enabled: false
          });
        }
        window.toastr.options.onHidden = function () {
          _this.get('_focusValidateButton')();
        };
        if (subscription) {
          subscription.save().then(function () {
            _this.showSuccess('interactions.parameters.message-info.info.save');
            _this.get('_focusTostCloseButton')();
            _this.refresh();
          }).catch(function (error) {
            _this.showError('interactions.parameters.message-info.error.save', 'An error occured while saving subscriptions.', error);
          });
        } else {
          this.showSuccess('interactions.parameters.message-info.info.save');
          this.get('_focusTostCloseButton')();
        }
      }
    }
  });
  _exports.default = _default;
});