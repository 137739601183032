define("lbp-cust-web-application/initializers/routes/ticust-web-module-interactions/interlocutors/interlocutor/conversations/create", ["exports", "ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/create"], function (_exports, _create) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/create',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _create.default.reopen({
    queue: Ember.inject.service(),
    identity: Ember.inject.service('identity-service'),
    apiSettings: Ember.inject.service(),
    lbpService: Ember.inject.service('lbp-service'),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('settingMap', JSON.parse(model.settingMap.get('settingValue')));
    },
    model: function model(params) {
      var _this = this;
      var settingMap = this.get('apiSettings').getSettingByKey('organization', null, 'adv.module.interactions.claim.url');
      return this._super(params).then(function (param) {
        // Manage settings themeCode/urlDesktop/urlMobile
        param.settingMap = settingMap;
        // Manage themes
        param.themes = _this._getThemesPromise();
        return Ember.RSVP.hash(param);
      });
    },
    validateTheme: function validateTheme(themeId) {
      if (!Ember.isEmpty(themeId)) {
        var payload = {
          type: 'client',
          code: this.get('identity.userTypo'),
          device: this.get('identity.userDeviceTheme'),
          key: themeId,
          enabled: true,
          valid: true,
          size: 1
        };
        return this.get('store').query('theme', payload).then(function (response) {
          if (response.get('length') < 1) {
            throw new Error('Cannot find theme ' + themeId);
          }
          return response.get('firstObject');
        }).catch(function () {
          return Ember.RSVP.reject('Theme \'' + themeId + '\' not found.');
        });
      }
      return Ember.RSVP.Promise.resolve();
    },
    actions: {
      didTransition: function didTransition() {
        this.set('controller.displayClaimForm', false);
      },
      willTransition: function willTransition(transition) {
        if (this.get('controller.isDirty') && !this.get('controller.showExitConfirmationModal')) {
          if (!this.get('controller.isMessageSent')) {
            this.set('controller.showExitConfirmationModal', true);
            this.set('controller._transition', transition);
            transition.abort();
            return;
          }
          this.set('controller.isDirty', false);
        }
        this.set('controller.isMessageSent', false);
        this.set('controller._transition', undefined);
        this._super(transition);
      },
      cancelCreateInRoute: function cancelCreateInRoute(transition) {
        if (transition) {
          this.set('lbpService.returnToSourcePageClick', true);
          transition.retry();
        } else {
          this.set('lbpService.returnToSourcePageClick', true);
          this._super();
        }
      },
      //modifhere
      createConversationInRoute: function createConversationInRoute(messageContent, status) {
        var _this2 = this;
        var transition = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var autoSaving = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
        var displaySubjectError = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
        this.set('controller.isSaving', true);
        this.get('queue').push(function () {
          var conversation = _this2.get('controller.model.conversation');
          // Draft management
          if (status === 'DRAFT') {
            conversation.set('status', 'INIT');
          } else {
            conversation.set('status', 'IN_PROGRESS');
          }

          // Update conversation to add theme information
          var caracteristics = _this2._addThemeInputInCaracteristics(conversation.get('caracteristics'));
          conversation.set('caracteristics', caracteristics);

          // Update conversation information
          conversation.set('creationDate', new Date());
          conversation.set('lastUpdateDate', new Date());
          // No secondary participants when creating conversation
          var participants = _this2.get('controller.participants').filter(function (p) {
            return p.email;
          });
          conversation.set('participants', {
            'primary': participants
          });
          var promise = undefined;
          if (conversation.id === null || conversation.id === undefined) {
            //When conversation is not created we should create it
            promise = conversation.save();
          } else if (displaySubjectError) {
            promise = new Ember.RSVP.Promise(function (resolve) {
              resolve(conversation);
            });
          } else {
            promise = conversation.save();
          }
          return promise.then(function (conversation) {
            var message = _this2.get('controller.model.message');
            messageContent = _this2._addLbpHeaders(messageContent);
            var participants = _this2.get('controller.participants').filter(function (p) {
              return p.email;
            });
            var interlocutor = _this2.modelFor('interlocutors.interlocutor').get('id');
            // Update message information
            message.set('messageContent', messageContent);
            message.set('participants', participants);
            message.set('receiveDate', new Date());
            message.set('status', status);
            message.set('interlocutor', interlocutor);
            return message.save().then(function () {
              if (!autoSaving) {
                if (message.get('isStatusSent')) {
                  _this2.showSuccess('interactions.conversation.success.send');
                  _this2.transitionTo('interlocutors.interlocutor.conversations.conversation', _this2.modelFor('interlocutors.interlocutor'), conversation.id, {
                    queryParams: {
                      pageSource: 'conversationSent'
                    }
                  });
                } else if (transition) {
                  transition.retry();
                } else {
                  _this2.showSuccess('interactions.conversation.create.message-info.info.save');
                  _this2.transitionTo('interlocutors.interlocutor.conversations.sources.source', _this2.modelFor('interlocutors.interlocutor'), 'conversationMine');
                }
              }
            }).catch(function (error) {
              _this2.showErrorAndLog('interactions.conversation.create.message-info.error.save.conversation-message', 'An error appear during saving conversation-message in conversation / create.', error);
              if (!autoSaving) {
                message.deleteRecord();
              }
            });
          }).catch(function (error) {
            _this2.showErrorAndLog('interactions.conversation.create.message-info.error.save.conversation', 'An error appear during saving conversation in conversation / create.', error);
          }).finally(function () {
            _this2.set('controller.isSaving', false);
          });
        }, autoSaving ? 'continue' : 'stop', 'message');
      }
    }
  });
});