define("lbp-cust-web-application/components/a11y/a11y-spinner", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    lines: 20,
    length: 6,
    width: 20,
    radius: 30,
    icon: false,
    labelKey: undefined,
    left: undefined,
    top: undefined,
    alertId: undefined,
    intl: Ember.inject.service(),
    // eslint-disable-next-line ember/no-on-calls-in-components
    startSpinner: Ember.on('didInsertElement', function () {
      if (this.get('labelKey') === undefined) {
        return;
      }
      var spinnerAlert = document.createElement('p');
      this.set('alertId', 'spinner-a11y-alert-' + _emberUuid.default.v4());
      spinnerAlert.setAttribute('id', this.get('alertId'));
      spinnerAlert.appendChild(document.createTextNode(this.get('intl').t(this.get('labelKey'))));
      if (document.getElementById('spinner-a11y-container')) {
        document.getElementById('spinner-a11y-container').appendChild(spinnerAlert);
      }
    }),
    // eslint-disable-next-line ember/no-on-calls-in-components
    stopSpinner: Ember.on('willDestroyElement', function () {
      if (this.get('alertId') !== undefined) {
        if (document.getElementById('spinner-a11y-container')) {
          document.getElementById('spinner-a11y-container').removeChild(document.getElementById(this.get('alertId')));
        }
      }
    })
  });
  _exports.default = _default;
});