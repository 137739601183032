define("lbp-cust-web-application/services/host-application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This service references the host app
   * so the engine overrides can be found
   */
  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('owner', Ember.getOwner(this));
    }
  });
  _exports.default = _default;
});