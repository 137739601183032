define("lbp-cust-web-application/authenticators/lbp-token-auth", ["exports", "ember-simple-auth-token/authenticators/jwt", "lodash"], function (_exports, _jwt, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _jwt.default.extend({
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    identity: Ember.inject.service('identity-service'),
    config: Ember.inject.service(),
    tiLogger: Ember.inject.service('logger-service'),
    router: Ember.inject.service(),
    getAuthenticateData: function getAuthenticateData(credentials) {
      var authentication = this._super(credentials);
      authentication.lbpToken = credentials;
      return authentication;
    },
    makeRequest: function makeRequest(url, data, headers) {
      var _this = this;
      var lbpToken = data;
      if (lbpToken && !(data instanceof Object)) {
        this.get('tiLogger').debug('Call LBP Token Authenticator with lbpToken = ' + lbpToken);
        return this.get('ajax').raw('/api/customer/authentication/token-login?lbpToken=' + lbpToken, {
          method: 'GET',
          headers: this.get('identity.ajaxHeaders')
        }).then(function (response) {
          _this.get('identity').set('authSuccess', true);
          return {
            json: response.response
          };
        });
      }
      // Refresh token
      this.get('tiLogger').debug('Call refresh token');
      return this._super(url, data, headers).then(function () {
        var data = _lodash.default.clone(_this.get('session.data.authenticated'));
        return {
          json: data
        };
      });
    },
    handleAuthResponse: function handleAuthResponse(response) {
      if (Ember.isEmpty(response.userId)) {
        throw new Error('Token is empty. Please check your backend response.');
      }

      /* LBP: fix expired token issue when device clock is out of sync with timezone
         by overriding the token's exp */
      var expiresAt = this.getCurrentTime() + response.timeToLive;
      response.expiresAt = expiresAt;
      this.scheduleAccessTokenRefresh(expiresAt, {});
      return response;
    },
    restore: function restore(data) {
      var expiresAt = data.expiresAt;
      var now = this.getCurrentTime();
      var router = Ember.getOwner(this).lookup('router:main');
      if (expiresAt > now && !(this.get('identity.authSuccess') === false && router.get('url') === '/auth/otu')) {
        this.get('identity').set('authSuccess', true);
        var wait = (expiresAt - now - this.refreshLeeway) * 1000;
        if (wait > 0) {
          this.scheduleAccessTokenRefresh(expiresAt, {});
          return Promise.resolve(data);
        }
      } else {
        return Promise.reject(new Error('token is expired'));
      }
    },
    invalidate: function invalidate() {
      var _this2 = this;
      // Get LBP Token
      var token = this.get('session.data.context.jetonLbp');

      // Delete context stored into local storage
      this.get('session').set('data.context', undefined);

      // Delete session ID stored into local storage
      this.get('session').set('data.sessionId', undefined);

      // Call login again to blacklist previous token
      // Because there is no logout endpoint
      return this.get('ajax').raw('/api/customer/authentication/token-login?lbpToken=' + token, {
        method: 'GET'
      }).then(function () {
        return _this2._super();
      });
    }
  });
  _exports.default = _default;
});